import { Col, Form, Modal, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { Button } from "../../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle } from "@mdi/js";
import ValidaComplexidadeSenha from "../../../../../../components/Formularios/ValidaComplexidadeSenha";
import ConsultaComplexidade from "../../../../../../components/ComplexidadeSenha";

type Props = {
  children?: React.ReactNode;
  titulo: string;
  onsubmit: (formData: any) => void;
};

export const ModalTrocarSenha: React.FC<Props> = ({
  children,
  titulo,
  onsubmit,
}) => {
  const usuario = sessionStorage.getItem("NomeUsuario") || "";
  const id_usuario = sessionStorage.getItem("DadosUsuarioID") || "";

  const [senhaAtual, setSenhaAtual] = useState<string>("");
  const [novaSenha, setNovaSenha] = useState<string>("");
  const [confirmNovaSenha, setConfirmNovaSenha] = useState<string>("");

  const novasenhaRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validarSenha = ConsultaComplexidade(novaSenha);
    if (novaSenha !== confirmNovaSenha) {
      toast.error(`A nova senha e a confirmação não conferem.`);
      setNovaSenha("");
      setConfirmNovaSenha("");
      novasenhaRef.current?.focus();
    } else {
      if (validarSenha < 60) {
        toast.error(
          `A senha não atende aos requisitos de segurança.`
        );
        setNovaSenha("");
        setConfirmNovaSenha("");
        novasenhaRef.current?.focus();
      } else {
        const dados = {
          id_usuario,
          senhaAtual,
          novaSenha,
        };
        onsubmit(dados);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white">
        <Row>
          <Col sm={6}>
            <InputSemBorda
              label="Usuário"
              name="usuario"
              type="text"
              placeholder="Usuário"
              required
              readonly
              value={usuario}
              onChange={() => {}}
              classNameInputsSemBorda="form-control bordasInferiorInput text-black"
            />
          </Col>
          <Col sm={6}>
            <InputSemBorda
              label="Senha Atual"
              name="senha"
              type="password"
              placeholder="Senha Atual"
              required
              value={senhaAtual}
              onChange={setSenhaAtual}
              classNameInputsSemBorda="form-control bordasInferiorInput text-black password"
              data-component="password-strength"
              data-monitor-id="password-strength-monitor"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <InputSemBorda
              label="Nova Senha"
              name="novasenha"
              type="password"
              placeholder="Nova Senha"
              required
              value={novaSenha}
              useRef={novasenhaRef}
              onChange={setNovaSenha}
              classNameInputsSemBorda="form-control bordasInferiorInput text-black"
            />
            <ValidaComplexidadeSenha senha={novaSenha} />
          </Col>
          <Col sm={6}>
            <InputSemBorda
              label="Confirmar Nova Senha"
              name="confirmnovasenha"
              type="password"
              placeholder="Confirmar Nova Senha"
              required
              value={confirmNovaSenha}
              onChange={setConfirmNovaSenha}
              classNameInputsSemBorda="form-control bordasInferiorInput text-black"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-white">
        <div className="d-flex my-2 gap-1 justify-content-between">
          <Button
          invisivel={false}
            type="submit"
            className="btn btn-light text-info-emphasis bg-white border-0"
            descricaoBotao="Salvar"
            icone={mdiCheckCircle}
          />
        </div>
      </Modal.Footer>
    </Form>
  );
};
