import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import FormUsuario from "./components/formulario";

export function UsuarioEditar() {
  const idusuario = useParams();

  return (
    <>
      <div className="col">
        <ContainerTitulo titulo="Editar Usuário" />
        <FormUsuario idusuario={idusuario.id} acao="Editar" />
      </div>
    </>
  );
}
