import React, { ChangeEvent,useRef } from "react";
interface InputProps {
  label: string;
  value: string;
  type: string;
  name: string;
  required?: boolean;
  classNameLabel?: string;
  classNameInput?: string;
  onChange: (value: string) => void;
  readonly?: boolean;
  useRef?: any;
}
const Input: React.FC<InputProps> = ({
  label,
  type,
  value,
  name,
  required,
  classNameLabel,
  classNameInput,
  onChange,
  readonly,
  useRef
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <label className={classNameLabel} htmlFor={name}>
        {label}
      </label>
      <input
        className={classNameInput}
        type={type}
        value={value}
        name={name}
        required={required}
        onChange={handleChange}
        readOnly={readonly}
        ref={useRef}
      />
    </div>
  );
};
export default Input;
