import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/img/principal.png";
import { getSessionData } from "../utils/storageUtils";
import ApiGet from "../api/endPoints/useGet";
import { useEffect, useState } from "react";
import { ModalTrocarSenhaLink } from "./administrativo/usuario/components/ModalTrocarSenhaLink";
import { setSessionData } from "../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import ModalSelecionarFilialLink from "./cadastro/filiais/filiais/components/ModalSelecionaClinicaLink/ModalSelecionaClinicaLink";
import ModalLoading from "../components/Formularios/Modal/ModalLoading";
import armazem from "../assets/img/armazem.png";
import avatar from "../assets/img/avatar.png";

const LeftBar = (): JSX.Element => {
  const isSuper = getSessionData("isPerfilSuper") || "";
  const id_perfil = getSessionData("id_perfil")
  const token = getSessionData("MultfilialWebToken") || "";
  const navigate = useNavigate()


  const [loading, setLoading] = useState<boolean>(false);

  //controle de permissões --- inicio
  const [acessoADM, setacessoADM] = useState(true)
  const [empresaHOLDING, setempresaHOLDING] = useState(true)
  const [empresaPERFILPERMISSAO, setempresaPERFILPERMISSAO] = useState(true)
  const [empresaUSUARIO, setempresaUSUARIO] = useState(true)
  const [empresaCODSISTEMA, setempresaCODSISTEMA] = useState(true)


  const [acessoCADASTRO, setacessoCADASTRO] = useState(true)
  const [acessoFILIAL, setacessoFILIAL] = useState(true)
  const [acessoCLIENTES, setacessoCLIENTES] = useState(true)
  const [acessoPRECADASTRO, setacessoPRECADASTRO] = useState(true)
  const [acessoCADASTROCLIENTE, setacessoCADASTROCLIENTE] = useState(true)
  const [acessoORGFINANCEIRA, setacessoORGFINANCEIRA] = useState(true)
  const [acessoPARCEIROS, setacessoPARCEIROS] = useState(true)


  async function CarregarPermissões(id_perfil: any) {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:idperfil",
      parametros: {
        idperfil: id_perfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then(function (retorno) {

        //##ACESSO ADM - módulo 
        if (retorno[0].visualizar_empresa_holding === 1 ||
          retorno[0].incluir_empresa_holding === 1 ||
          retorno[0].editar_empresa_holding === 1 ||
          retorno[0].excluir_empresa_holding === 1 ||
          retorno[0].visualizar_perfil_permissao === 1 ||
          retorno[0].incluir_perfil_permissao === 1 ||
          retorno[0].editar_perfil_permissao === 1 ||
          retorno[0].excluir_perfil_permissao === 1 ||
          retorno[0].visualizar_usuario === 1 ||
          retorno[0].incluir_usuario === 1 ||
          retorno[0].editar_usuario === 1 ||
          retorno[0].excluir_usuario === 1 ||
          retorno[0].visualizar_codsistema === 1 ||
          retorno[0].incluir_codsistema === 1 ||
          retorno[0].editar_codsistema === 1 ||
          retorno[0].excluir_codsistema === 1) {

          setacessoADM(false) //tem acesso à adm - mostrar adm

          //##ACESSO empresa(holding) - submódulo 
          if (retorno[0].visualizar_empresa_holding === 1 ||
            retorno[0].incluir_empresa_holding === 1 ||
            retorno[0].editar_empresa_holding === 1 ||
            retorno[0].excluir_empresa_holding === 1) {
            setempresaHOLDING(false) //tem acesso empresa(holding) - submódulo 
          }
          //##ACESSO Perfil / Permissão - submódulo 
          if (retorno[0].visualizar_perfil_permissao === 1 ||
            retorno[0].incluir_perfil_permissao === 1 ||
            retorno[0].editar_perfil_permissao === 1 ||
            retorno[0].excluir_perfil_permissao === 1) {
            setempresaPERFILPERMISSAO(false) //tem acesso empresa(holding) - submódulo 
          }
          //##ACESSO usuario - submódulo 
          if (retorno[0].visualizar_usuario === 1 ||
            retorno[0].incluir_usuario === 1 ||
            retorno[0].editar_usuario === 1 ||
            retorno[0].excluir_usuario === 1) {
            setempresaUSUARIO(false) //tem acesso empresa(holding) - submódulo 
          }
          //##ACESSO usuario - submódulo 
          if (retorno[0].visualizar_codsistema === 1 ||
            retorno[0].incluir_codsistema === 1 ||
            retorno[0].editar_codsistema === 1 ||
            retorno[0].excluir_codsistema === 1) {
            setempresaCODSISTEMA(false) //tem acesso empresa(holding) - submódulo 
          }


        }
        else {
          setacessoADM(true) //não tem acesso - não mostrar
        }

        //##ACESSO CADASTRO - módulo 
        if (retorno[0].visualizarfilial === 1 ||
          retorno[0].incluirfilial === 1 ||
          retorno[0].editarfilial === 1 ||
          retorno[0].excluirfilial === 1 ||
          retorno[0].visualizarDepositante === 1 ||
          retorno[0].incluirDepositante === 1 ||
          retorno[0].editarDepositante === 1 ||
          retorno[0].excluirDepositante === 1 ||
          retorno[0].visualizarDepositante === 1 ||
          retorno[0].incluirDepositante === 1 ||
          retorno[0].editarDepositante === 1 ||
          retorno[0].excluirDepositante === 1 ||
          retorno[0].visualizarEquipeOperacional === 1 ||
          retorno[0].incluirEquipeOperacional === 1 ||
          retorno[0].editarEquipeOperacional === 1 ||
          retorno[0].excluirEquipeOperacional === 1 ||
          retorno[0].visualizarAgendaEquipe === 1 ||
          retorno[0].incluirAgendaEquipe === 1 ||
          retorno[0].editarAgendaEquipe === 1 ||
          retorno[0].excluirAgendaEquipe === 1 ||
          retorno[0].EnviarPreCad === 1 ||
          retorno[0].incluirPreCad === 1 ||
          retorno[0].visualizarCadastroCliente === 1 ||
          retorno[0].incluirCadastroCliente === 1 ||
          retorno[0].EditarCadastroCliente === 1 ||
          retorno[0].ExcluirCadastroCliente === 1 ||
          retorno[0].EnviarCadastroCliente === 1 ||
          retorno[0].visualizarOrgFinanceira === 1 ||
          retorno[0].EditarOrgFinanceira === 1) {

          setacessoCADASTRO(false) //tem acesso à cadastro - mostrar cadastro

          if (retorno[0].visualizarfilial === 1 ||
            retorno[0].incluirfilial === 1 ||
            retorno[0].editarfilial === 1 ||
            retorno[0].excluirfilial === 1) {
            setacessoFILIAL(false)//tem acesso à filial - mostrar filial

          }
          if (retorno[0].incluirPreCad === 1 || retorno[0].visualizarCadastroCliente === 1 ||
            retorno[0].visualizarCadastroCliente === 1 ||
            retorno[0].incluirCadastroCliente === 1 ||
            retorno[0].EditarCadastroCliente === 1 ||
            retorno[0].ExcluirCadastroCliente === 1 || retorno[0].ExcluirPreCad === 1) {

            setacessoCLIENTES(false)
          }
          if (retorno[0].visualizarCadParceiro === 1) {

            setacessoPARCEIROS(false)
          }

          if (retorno[0].incluirPreCad === 1 || retorno[0].visualizarCadastroCliente === 1 || retorno[0].ExcluirPreCad === 1) {

            setacessoPRECADASTRO(false)
          }
          if (retorno[0].visualizarCadastroCliente === 1 ||
            retorno[0].incluirCadastroCliente === 1 ||
            retorno[0].EditarCadastroCliente === 1 ||
            retorno[0].ExcluirCadastroCliente === 1) {

            setacessoCADASTROCLIENTE(false)
          }
          if (retorno[0].visualizarOrgFinanceira === 1 ||
            retorno[0].EditarOrgFinanceira === 1) {

            setacessoORGFINANCEIRA(false)
          }

        }
        else {
          setacessoCADASTRO(true) //não tem acesso - não mostrar
        }

        setLoading(false)

      }).catch(function (erro) {
        sessionStorage.clear();
        navigate("/login");
      })
  }


  //controle de permissões --- FIM

  useEffect(function () {
    CarregarPermissões(id_perfil)
  }, [])


  return (
    <>

      <div className="leftside-menu">
        <a href="/" className="logo text-center ">
          <span className="logo-lg">
            <img src={Logo} alt="" style={{ height: "48px" }} />
          </span>
          <span className="logo-sm">
            <img src={Logo} alt="" style={{ height: "24px" }} />
          </span>
        </a>


        <div className="h-100" id="leftside-menu-container" data-simplebar>
          <ul className="side-nav">
            <li className="side-nav-item" hidden={acessoADM}>
              <a
                data-bs-toggle="collapse"
                href="#Administrativo"
                aria-expanded="false"
                aria-controls="Administrativo"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="clarity:administrator-solid"
                  data-width="22"
                ></span>
                <span style={{ margin: "15px" }}> Administrativo </span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="Administrativo">
                <ul className="side-nav-second-level">
                  {isSuper === "1" && (
                    <li hidden={empresaHOLDING}>
                      <Link to={"/administrativo/empresa"}>
                        Empresa (Holding)
                      </Link>
                    </li>
                  )}
                  {isSuper === "1" && (
                    <li hidden={empresaPERFILPERMISSAO}>
                      <Link to={"/administrativo/permisao"}>
                        Perfil / Permissão
                      </Link>
                    </li>
                  )}
                  <li hidden={empresaUSUARIO}>
                    <Link to={"/administrativo/usuario"}>Usuário</Link>
                  </li>

                  {isSuper === "1" && (
                    <li hidden={empresaCODSISTEMA}>
                      <Link to={"/administrativo/codistema"}>
                        Código do Sistema
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
            <li className="side-nav-item" hidden={acessoCADASTRO}>
              <a
                data-bs-toggle="collapse"
                href="#Cadastro"
                aria-expanded="false"
                aria-controls="Cadastro"
                className="side-nav-link"
              >
                <span
                  className="iconify"
                  data-icon="fluent:people-list-24-filled"
                  data-width="22"
                ></span>
                <span style={{ margin: "15px" }}>Cadastro</span>
                <span className="menu-arrow"></span>
              </a>
              <div className="collapse" id="Cadastro">
                <ul className="side-nav-second-level">
                  <li className="side-nav-item">
                    <Link hidden={acessoFILIAL} to={"cadastro/filiais/filial"}>Filial</Link>
                  </li>
                  <li className="side-nav-item" hidden={acessoPARCEIROS}>
                    <Link to={"cadastro/parceiros"}>Parceiros</Link>
                  </li>
                  <li className="side-nav-item" hidden={acessoCLIENTES}>
                    <a
                      data-bs-toggle="collapse"
                      href="#Clientes"
                      aria-expanded="false"
                      aria-controls="Clientes"

                    >
                      <span>Clientes</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <div className="collapse" id="Clientes">
                      <ul className="side-nav-third-level">
                        <li hidden={acessoPRECADASTRO}>
                          <Link to={"precadastro/clientes"}>Pré Cadastro</Link>
                        </li>
                        <li hidden={acessoCADASTROCLIENTE}>
                          <Link to={"cadastro/clientes/listagem"}>Clientes</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="side-nav-item" hidden={acessoORGFINANCEIRA}>
                    <a
                      data-bs-toggle="collapse"
                      href="#Org"
                      aria-expanded="false"
                      aria-controls="Org"

                    >
                      <span>Organização Financeira</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <div className="collapse" id="Org">
                      <ul className="side-nav-third-level">
                        <li>
                          <Link to={"org/financeira/list"}>Coleta Qualitativa</Link>
                        </li>
                        <li>
                          <Link to={"org/financeira/balancopatrionial/list"}>Balanço Patrimonial</Link>
                        </li>
                        <li>
                          <Link to={"org/financeira/fluxocaixa/list"}>Fluxo de Caixa</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <ModalLoading show={loading} />
    </>
  );
};

export { LeftBar };
