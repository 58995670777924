function MascaraValor(custo: any) {

    // Remove pontos dos milhares e substitui vírgula decimal por ponto
    if (typeof custo === 'string') {
        custo = custo.replace(/\./g, '').replace(',', '.');
    }

    // Arredonda o número para o número especificado de casas decimais
    if (custo <= 0) {
        return "";
    } else {
        const roundedNumber = Number(custo).toFixed(2);
        const formattedNumber = Number(roundedNumber).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        return formattedNumber;
    }
}

export default MascaraValor