import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { Row, Col, Card } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import { getSessionData } from "../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import Tabela from "../../../components/Formularios/Table";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { mdiEmailFastOutline } from '@mdi/js';
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../api/endPoints/useGet";

function PreCadastro() {

    const navigate = useNavigate()

    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/precadastro/clientes/formulario/novo/criar")}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    // Função para realizar a busca
    const [busca, set_busca] = useState("")
    function Search(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {
            carregarClientes(getSessionData("DadosIdfilial"))
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = clientes.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.nome.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)

            })


            set_clientes(resultados)
        }
    }

    useEffect(function () {
        Search(busca)
    }, [busca])


    const [clientes, set_clientes] = useState([])
    function carregarClientes(id_filial: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/clientes/${id_filial}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {

                set_carregando(false)
                if (resposta.data.codigo != 200) {

                    toast.error(resposta.data.message)
                }
                else {

                    set_clientes(resposta.data.clientes)
                }
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }


    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_cliente" },
        { titulo: "CPF", acesso: "cpf" },
        { titulo: "Nome", acesso: "nome" },
        { titulo: "Email", acesso: "email" },
        { titulo: "Celular", acesso: "telcelular" },
        { titulo: "Telefone", acesso: "telfixo" },
        { titulo: "Status", acesso: "status" },
        { titulo: "Situação envio de E-mail", acesso: "status_email" },
        { titulo: "Status Pré Cadastro", acesso: "status_pre_cad" }
    ]

    //modal loading
    const [carregando, set_carregando] = useState(false)



    async function enviarEmail(dado: any) {

        await showConfirmationDialog("Enviar e-mail ?",
            "Continuar envio de e-mail ?", "question", 1).then(function (resposta: any) {

                if (resposta.confirmed) {
                    set_carregando(true)

                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/enviar/email/pre/cad/${getSessionData("DadosIdfilial")}/${dado.email}/${dado.id_cliente}`, {
                        headers: {
                            Authorization: getSessionData("MultfilialWebToken")
                        }
                    })
                        .then(function (resposta) {

                            set_carregando(false)
                            if (resposta.data.codigo != 200) {

                                toast.error(resposta.data.message)
                            }
                            else {

                                toast.success(resposta.data.message)
                                carregarClientes(getSessionData("DadosIdfilial"))
                            }
                        }).catch(function (erro) {

                            set_carregando(false)
                            toast.error(erro)
                        })
                }
            })
    }


    //verifica permissão --- inicio
    const [permissao_incluir, set_permissao_incluir] = useState(true)
    const [permissao_enviar, set_permissao_enviar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)


    async function CarregarPermissões() {
        set_carregando(true);
        const headers = {
            Authorization: getSessionData("MultfilialWebToken") || "",
        };
        const dados = {
            url: "/perfil/carregar/:idperfil",
            parametros: {
                idperfil: getSessionData("id_perfil") || "",
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].EnviarPreCad === 1) {
                set_permissao_enviar(false)
            }
            if (response[0].incluirPreCad === 1) {
                set_permissao_incluir(false)
            }
            if(response[0].ExcluirPreCad === 1){
                set_permissao_excluir(false)
            }
        })
    }


    async function excluir(dado: any) {

        await showConfirmationDialog("Excluir Cadastro ?",
            "Deseja continuar ?", "question").then(function (resposta) {

                if (resposta.confirmed) {

                    set_carregando(true)

                    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/cliente/${getSessionData("DadosIdfilial")}/${dado.id_cliente}`, {
                        headers: {
                            Authorization: getSessionData("MultfilialWebToken")
                        }
                    })
                        .then(function (resposta) {

                            set_carregando(false)
                            if (resposta.data.codigo == 200) {

                                toast.success(resposta.data.message)
                                carregarClientes(getSessionData("DadosIdfilial"))
                            }
                            else {

                                toast.error(resposta.data.message)
                            }
                        }).catch(function (erro) {

                            set_carregando(false)
                            toast.error(erro)
                        })
                }
            })
    }


    useEffect(function () {

        carregarClientes(getSessionData("DadosIdfilial"))
        CarregarPermissões()
    }, [])


    return (

        <>
            <div className="col">
                <ContainerTitulo titulo="Listagem de Pré cadastro - Clientes" />

                <Row>
                    <Col sm={12}>

                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >  {actions.map((action) => (




                                        <SpeedDialAction
                                            hidden={action.name == "Incluir" ? permissao_incluir : false}
                                            key={action.name}
                                            icon={action.icon}
                                            tooltipTitle={action.name}
                                        />
                                    ))}
                                    </SpeedDial>
                                </Row>
                                <Row>
                                    <div className="d-flex my-2 gap-1 ">
                                        <Col sm={11}>
                                            <InputSemBorda
                                                name="fantasia"
                                                type="text"
                                                value={busca}
                                                onChange={set_busca}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Procurar por cliente"
                                            />
                                        </Col>
                                    </div>
                                </Row>

                                <Row>
                                    <Tabela
                                        coluna={colunas}
                                        dados={clientes}
                                        itemsPerPage={10}
                                        onVisuClick={enviarEmail}
                                        onEditClick={function () { }}
                                        onDeleteClick={excluir}
                                        iconeVisualizar={mdiEmailFastOutline}
                                        id="codigo"
                                        permissao_editar={true}
                                        permissao_visualizar={permissao_enviar}
                                        permissao_excluir={permissao_excluir}
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            <ModalLoading show={carregando} />
        </>
    )
}


export default PreCadastro