import { useFetcher, useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP, mascaraCNPJ } from "../../../hooks/mascaras";
import { getSessionData } from "../../../utils/storageUtils";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../../components/dadosFixos/UF";

function ParceirosForm() {


    const params = useParams()
    const navigate = useNavigate()

    const [cpf_cnpj, set_cpf_cnpj] = useState("")
    const [nome, set_nome] = useState("")
    const [email, set_email] = useState("")
    const [celular, set_celular] = useState("")
    const [telefone, set_telefone] = useState("")
    const [data_adesao, set_data_adesao] = useState("19-02-2004")
    const [cep, set_cep] = useState("")
    const [logradouro, set_logradouro] = useState("")
    const [complemento, set_complemento] = useState("")
    const [numero, set_numero] = useState("")
    const [bairro, set_bairro] = useState("")
    const [cidade, set_cidade] = useState("")
    const [codmunicipio, set_codmunicipio] = useState("")
    const [uf, set_uf] = useState("")
    const [obs, set_obs] = useState("")
    const [status, set_status] = useState(true)
    const [repasse, set_repasse] = useState<any>("")

    //modal loading
    const [carregando, set_carregando] = useState(false)

    const buscarCep = async (cep: string) => {
        if (cep.length < 8) {
            toast.error("CEP inválido!");
        } else if (cep.length == 8) {
            await axios
                .get(`https://viacep.com.br/ws/${cep}/json/`)
                .then((retorno: any) => {
                    if (retorno.data?.erro) {
                        toast.error("CEP inválido ou Não Localizado!");
                    } else {
                        const dadosCep = retorno.data;
                        set_logradouro(dadosCep.logradouro);
                        set_complemento(dadosCep.complemento);
                        set_bairro(dadosCep.bairro);
                        set_cidade(dadosCep.localidade);
                        set_uf(dadosCep.uf);
                        set_codmunicipio(dadosCep.ibge);
                    }
                })
                .catch((erro) => {
                    console.log("erro: ", erro);
                    const msg =
                        erro.response?.data.message === undefined
                            ? erro.message
                            : erro.response.data.message;
                    toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
                });
        }
    }

    async function carregarDadosCNPJ(event: any) {

        set_cpf_cnpj(event)
        if (event.length == 14) {
            set_carregando(true)
            const response = await axios.get(`https://publica.cnpj.ws/cnpj/${event}`)

            set_cpf_cnpj(response.data.estabelecimento.cnpj)
            set_nome(response.data.estabelecimento.nome_fantasia)
            set_logradouro(response.data.estabelecimento.logradouro)
            set_numero(response.data.estabelecimento.numero)
            set_complemento(response.data.estabelecimento.complemento)
            set_bairro(response.data.estabelecimento.bairro)
            set_cidade(response.data.estabelecimento.cidade.nome)
            set_codmunicipio(response.data.estabelecimento.cidade.ibge_id)
            set_cep(response.data.estabelecimento.cep)
            set_uf(response.data.estabelecimento.estado.sigla)
            //setPais(response.data.estabelecimento.pais.id)
            set_nome(response.data.razao_social)

            set_carregando(false)
            set_cpf_cnpj(mascaraCNPJ(event))
        }
        else if(event.length == 11){

            set_cpf_cnpj(mascaraCPF(event))
        }
    }

    async function criarNovo(e: any) {

        try {

            e.preventDefault()
            set_carregando(true)
            const dados = {

                cpf_cnpj,
                nome,
                email,
                celular,
                telefone,
                data_adesao,
                cep,
                logradouro,
                complemento,
                numero,
                bairro,
                cidade,
                codmunicipio,
                uf,
                obs,
                status,
                repasse,
                usuario_cadastro: getSessionData("NomeUsuario")
            }
            const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/novo/parceiro/${getSessionData("DadosIdfilial")}`, dados, {
                headers: {
                    Authorization: getSessionData("MultfilialWebToken")
                }
            })

            toast.success(resposta.data)
            setTimeout(() => {

                navigate(-1)
            }, 2000)

            set_carregando(false)
        } catch (error: any) {
            console.log(error)
            toast.error(error.response.data || error.response.statusText)
            set_carregando(false)
        }
    }

    async function CarregarParceiro(id_parceiro: any) {

        try {

            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/um/parceiro/${getSessionData("DadosIdfilial")}/${id_parceiro}`, {
                headers: {
                    Authorization: getSessionData("MultfilialWebToken")
                }
            })

            set_cpf_cnpj(resposta.data[0].cnpjcpf)
            set_data_adesao(resposta.data[0].dtadesao.split("T")[0])
            set_nome(resposta.data[0].nome)
            set_telefone(resposta.data[0].telfixo)
            set_celular(resposta.data[0].telcelular)
            set_email(resposta.data[0].email)
            set_logradouro(resposta.data[0].logradouro)
            set_numero(resposta.data[0].numero)
            set_bairro(resposta.data[0].bairro)
            set_codmunicipio(resposta.data[0].cnum)
            set_cidade(resposta.data[0].cidade)
            set_uf(resposta.data[0].cuf)
            set_cep(resposta.data[0].cep)
            set_obs(resposta.data[0].observacao)
            set_repasse(resposta.data[0].percrepasse)
            set_status(resposta.data[0].status)
            set_carregando(false)
        } catch (error: any) {

            set_carregando(false)
            toast.error(error.response.data || error.statusText)
        }
    }

    async function AtualizarParceiro(e: any) {

        try {

            e.preventDefault()
            set_carregando(true)
            const dados = {

                cpf_cnpj,
                nome,
                email,
                celular,
                telefone,
                data_adesao,
                cep,
                logradouro,
                complemento,
                numero,
                bairro,
                cidade,
                codmunicipio,
                uf,
                obs,
                status,
                repasse
            }
            const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/parceiro/${getSessionData("DadosIdfilial")}/${params.id_parceiro}`, dados, {
                headers: {
                    Authorization: getSessionData("MultfilialWebToken")
                }
            })

            toast.success(resposta.data)
            setTimeout(() => {

                navigate(-1)
            }, 2000)

            set_carregando(false)
        } catch (error: any) {
            console.log(error)
            toast.error(error.response.data || error.response.statusText)
            set_carregando(false)
        }
    }

    useEffect(function () {

        if (params.id_parceiro != "novo") {

            CarregarParceiro(params.id_parceiro)
        }
    }, [])

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo={params.acao == 'criar' ? "Novo Cadastro de Parceiro" : params.acao == 'visualizar' ? "Visualizar Cadastro de Parceiro" : "Editar Cadastro de Parceiro"} />

                <Card>
                    <Form onSubmit={params.id_parceiro == "novo" ? criarNovo : AtualizarParceiro}>
                        <Card.Body>
                            <Box sx={{ width: "100%" }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="fantasia"
                                                type="text"

                                                value={params.id_parceiro || ""}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="ID Parceiro"
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm">
                                            <InputSemBorda
                                                name="text"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={cpf_cnpj}
                                                onChange={function (e: any) {
                                                    carregarDadosCNPJ(e)
                                                }}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="CPF/CNPJ"
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm">
                                            <InputSemBorda
                                                name="Data de Adesão"
                                                type="date"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={data_adesao}
                                                required
                                                onChange={set_data_adesao}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Data de Adesão"
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm">
                                            <InputSemBorda
                                                name="nome"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={nome}
                                                required
                                                onChange={set_nome}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Nome"
                                            />
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col-lg-4 col-md-4 col-sm">
                                            <InputSemBorda
                                                name="e-mail"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={email}
                                                required
                                                onChange={set_email}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="E-mail"
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="celular"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={mascaraTelefoneCelular(celular)}
                                                onChange={set_celular}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Celular"
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="telefone"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={mascaraTelefoneFixo(telefone)}
                                                onChange={set_telefone}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Telefone"
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="cep"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={mascaraCEP(cep)}
                                                onChange={set_cep}
                                                onBlur={function () {
                                                    const cep_formatado = cep.replace(/[.\-/]/g, '')
                                                    set_cep(cep_formatado)
                                                    buscarCep(cep_formatado)
                                                }}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Cep"
                                            />
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col-lg-2 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="numero"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={numero}
                                                onChange={set_numero}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Numero"
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="complemento"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={complemento}
                                                onChange={set_complemento}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Complemento"
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="Logradouro"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={logradouro}
                                                onChange={set_logradouro}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Logradouro"
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="bairro"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={bairro}
                                                onChange={set_bairro}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Bairro"
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="cidade"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={cidade}
                                                onChange={set_cidade}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Cidade"
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="codmunicipio"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={codmunicipio}
                                                onChange={set_codmunicipio}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Cód. Município"
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm">
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel>UF</InputLabel>
                                                <Select
                                                    value={uf}
                                                    onChange={(e: SelectChangeEvent) =>
                                                        set_uf(e.target.value)
                                                    }
                                                    label="UF"
                                                    required
                                                    disabled={params.acao === "visualizar" ? true : false}
                                                >
                                                    <MenuItem value="">
                                                        <em>Selecione...</em>
                                                    </MenuItem>
                                                    {dadosUF.map((item: any) => (
                                                        <MenuItem value={item.uf}>{item.nome}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="ms-3 mt-3 col-lg-3 col-md-3 col-sm">
                                            <div className="form-check form-switch">
                                                <label className="form-check-label">Ativo</label>
                                                <input disabled={params.acao == "visualizar" ? true : false} className="form-check-input w-25 me-1" type="checkbox" id="flexSwitchCheckChecked" checked={status} onChange={function (e: any) {

                                                    set_status(e.target.checked)
                                                }} />

                                            </div>
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col-lg-2 col-md-3 col-sm">
                                            <InputSemBorda
                                                name="%"
                                                type="number"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={repasse}
                                                onChange={set_repasse}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="% Repasse"
                                            />
                                        </div>
                                        <div className="col">
                                            <InputSemBorda
                                                name="Observação"
                                                type="textarea"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={obs}
                                                onChange={set_obs}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Observações"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-flex my-2 gap-1 justify-content-between">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Voltar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        navigate(-1);
                                    }}
                                />
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' ? true : false}
                                    type="submit"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Salvar"
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </Card.Footer>
                    </Form>

                </Card>
            </div>
            <ModalLoading show={carregando} />
        </>
    )
}

export default ParceirosForm