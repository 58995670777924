import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";

import { Switch } from "@mui/material";
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlus } from "@mdi/js";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { Button } from "../../../../components/Formularios/Buttons/Button";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../../api/endPoints/useGet";
import { TextoFormatoCaptalize } from "../../../../components/Auxiliar/ConvTextoCaptalize";
import ApiPost from "../../../../api/endPoints/usePost";
import ApiPut from "../../../../api/endPoints/usePut";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../../components/Formularios/Table";
import ModalDetalhe from "../modalDetalheCodigoSistema";
import { TextoFormatoUpperCaseAcento } from "../../../../components/Auxiliar/ConvTextUpperRemCaracEspAcento";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../../api/endPoints/useDelete";
import { getSessionData } from "../../../../utils/storageUtils";

interface FormPerfilProps {
  idcodigo?: string;
  acao?: string;
}

const FormCodSistema = ({ idcodigo, acao }: FormPerfilProps) => {
  const token = getSessionData("MultfilialWebToken") || "";
  const navigate = useNavigate();

  const [id_codigo, setIdCodigo] = useState<string>("");
  const [tabela, setTabela] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);

  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS
  const [loading, setLoading] = useState<boolean>(false);
  const [dados, setDados] = useState<any[]>([]);
  const carregarDados = async (idcodigo: any) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/codigosistema/carregar/:id_codigo",
      parametros: {
        id_codigo: idcodigo,
      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setIdCodigo(retorno[0].id);
        setTabela(retorno[0].tabela);
        setDescricao(retorno[0].descricao);
        if (retorno[0].situacao === 1) {
          setAtivo(true);
        } else {
          setAtivo(false);
        }
        setLoading(false);
        setDados(
          retorno[0].cod_sistema.length > 0 ? retorno[0].cod_sistema : []
        );
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao carregar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (idcodigo !== "novo") {
      carregarDados(idcodigo);
    }
  }, [idcodigo]);
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS DO USUÁRIO
  const atualizarDados = async (
    idcodigo: any,
    descricao: string,
    situacao: number
  ) => {
    const dados = {
      descricao: TextoFormatoCaptalize(descricao.toLowerCase()),
      situacao,
    };

    ApiPut(`/codigosistema/atualizar/${idcodigo}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(
          `Erro ao atualizar dados. Motivo: ${erro.response.data.message}`
        );
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS DO USUÁRIO
  const gravarDados = (
    tabela: string,
    descricao: string,
    situacao: number,
    itens: any[]
  ) => {
    const dados = {
      tabela: TextoFormatoUpperCaseAcento(tabela),
      descricao: TextoFormatoCaptalize(descricao.toLowerCase()),
      situacao,
      itens,
    };
    ApiPost("/codigosistema/gravar", dados,token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idcodigo === "novo") {
      gravarDados(tabela, descricao, ativo ? 1 : 0, dados);
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(idcodigo, descricao, ativo ? 1 : 0);
      toast.info("Atualizando dados do registro...");
    }
  };

  const colunas: ITabela[] = [
    { titulo: "Código", acesso: "codigo" },
    { titulo: "Descrição", acesso: "descricao" },
    { titulo: "Def. Usuário 1", acesso: "defusr01" },
    { titulo: "Def. Usuário 2", acesso: "defusr02" },
    { titulo: "Def. Usuário 3", acesso: "defusr03" },
    { titulo: "Def. Usuário 4", acesso: "defusr04" },
    { titulo: "Def. Usuário 5", acesso: "defusr05" },
    { titulo: "Def. Usuário 6", acesso: "defusr06" },
    { titulo: "Situação", acesso: "situacao" },
  ];

  const visualizar = (dadosVisualizar: any) => {
    setDadosItens((state) => {
      return {
        ...state,
        codigo: dadosVisualizar.codigo,
        descricao: dadosVisualizar.descricao,
        defUsuario01: dadosVisualizar.defusr01,
        defUsuario02: dadosVisualizar.defusr02,
        defUsuario03: dadosVisualizar.defusr03,
        defUsuario04: dadosVisualizar.defusr04,
        defUsuario05: dadosVisualizar.defusr05,
        defUsuario06: dadosVisualizar.defusr06,
        ativo: dadosVisualizar.situacao === 1 ? true : false,
        acao: "Visualizar",
      };
    });
    setShowModalDetalhe(true);
  };
  const [dadosItens, setDadosItens] = useState({
    codigo: "",
    descricao: "",
    defUsuario01: "",
    defUsuario02: "",
    defUsuario03: "",
    defUsuario04: "",
    defUsuario05: "",
    defUsuario06: "",
    ativo: true,
    acao: "",
  });

  const editar = (dadosEditar: any) => {
    setDadosItens((state) => {
      return {
        ...state,
        codigo: dadosEditar.codigo,
        descricao: dadosEditar.descricao,
        defUsuario01: dadosEditar.defusr01,
        defUsuario02: dadosEditar.defusr02,
        defUsuario03: dadosEditar.defusr03,
        defUsuario04: dadosEditar.defusr04,
        defUsuario05: dadosEditar.defusr05,
        defUsuario06: dadosEditar.defusr06,
        ativo: dadosEditar.situacao === 1 ? true : false,
        acao: "EditarItem",
      };
    });
    setShowModalDetalhe(true);
  };
  const excluirItem = async (dadosExcluir: any) => {
    const codigo = dadosExcluir.codigo;
    if (acao !== "Visualizar") {
      await showConfirmationDialog(
        "Excluir Código do Sistema",
        `Tem certeza que deseja excluir o de código do sistema \n ${dadosExcluir.codigo} - ${dadosExcluir.descricao}?`,
        "question"
      )
        .then((resultado) => {
          if (resultado.confirmed) {
            if (acao === "Novo") {
              const dadosNovos = dados.filter(
                (item: any) => item.codigo !== codigo
              );
              setDados(dadosNovos);
              toast.success("Código excluído com sucesso!");
            } else if (acao === "Editar") {
              const headers = {
                Authorization: token,
              };
              const dados = {
                url: "/codigosistema/excluir/detalhe/:tabela/:id_codigo",
                parametros: {
                  tabela: tabela,
                  id_codigo: codigo,
                },
                headers,
              };
              ApiDelete(dados)
                .then((retorno) => {
                  toast.success(retorno.message);
                  carregarDados(idcodigo);
                })
                .catch((erro) => {
                  toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                  );
                });
            }
          }
        })
        .catch((erro) => {
          toast.error(`Erro ao excluir dados. Motivo: ${erro.message}`);
        });
    }
  };

  //#region Controla o estado do modal para adicionar detalhes a tabela
  const [showModalDetalhe, setShowModalDetalhe] = useState<boolean>(false);
  //#endregion
  const handleOpenModal = () => {
    //Limpar Campos do Modal
    setDadosItens({
      codigo: "",
      descricao: "",
      defUsuario01: "",
      defUsuario02: "",
      defUsuario03: "",
      defUsuario04: "",
      defUsuario05: "",
      defUsuario06: "",
      ativo: true,
      acao: "Novo",
    });

    setShowModalDetalhe(true);
  };
  const handleCloseModal = () => {
    // Simulação: Buscar dados de um GET ou preparar valores iniciais
    setShowModalDetalhe(false);
  };

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS  - DETALHE
  const gravarDadosDetalhe = (
    tabela: string,
    codigo: string,
    descricao: string,
    situacao: number,
    defusr01: string,
    defusr02: string,
    defusr03: string,
    defusr04: string,
    defusr05: string,
    defusr06: string
  ) => {
    const dados = {
      tabela: tabela,
      codigo: TextoFormatoUpperCaseAcento(codigo),
      descricao: TextoFormatoCaptalize(descricao),
      situacao,
      defusr01,
      defusr02,
      defusr03,
      defusr04,
      defusr05,
      defusr06,
    };
    ApiPost("/codigosistema/gravar/detalhe", dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        carregarDados(idcodigo);
        setShowModalDetalhe(false);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion
  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS  - DETALHE
  const atualizarDadosDetalhe = (
    tabela: string,
    codigo: string,
    descricao: string,
    situacao: number,
    defusr01: string,
    defusr02: string,
    defusr03: string,
    defusr04: string,
    defusr05: string,
    defusr06: string
  ) => {
    const dados = {
      tabela: tabela,
      codigo: TextoFormatoUpperCaseAcento(codigo),
      descricao: TextoFormatoCaptalize(descricao),
      situacao,
      defusr01,
      defusr02,
      defusr03,
      defusr04,
      defusr05,
      defusr06,
    };
    ApiPut(`/codigosistema/atualizar/detalhe/${codigo}/${tabela}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        carregarDados(idcodigo);
        setShowModalDetalhe(false);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao atualizar dados. Motivo: ${msg}`);
      });
  };
  //#endregion
  const handleSubmit = (dadosItens: any) => {
    const dadosTabela = {
      codigo: dadosItens.codigo,
      descricao: dadosItens.descricao,
      defusr01: dadosItens.defUsuario01,
      defusr02: dadosItens.defUsuario02,
      defusr03: dadosItens.defUsuario03,
      defusr04: dadosItens.defUsuario04,
      defusr05: dadosItens.defUsuario05,
      defusr06: dadosItens.defUsuario06,
      situacao: dadosItens.ativo ? 1 : 0,
    };

    if (acao === "Novo" && dadosItens.acao === "Novo") {
      const item = dados.some((item) => item.codigo === dadosItens.codigo);
      if (!item) {
        setShowModalDetalhe(false);
        setDados([...dados, dadosTabela]);
      } else {
        toast.error("Código já cadastrado na tabela!");
      }
    } else if (acao === "Editar" && dadosItens.acao === "Novo") {
      const item = dados.some((item) => item.codigo === dadosItens.codigo);
      if (!item) {
        gravarDadosDetalhe(
          tabela,
          dadosItens.codigo,
          dadosItens.descricao,
          dadosItens.ativo ? 1 : 0,
          dadosItens.defUsuario01,
          dadosItens.defUsuario02,
          dadosItens.defUsuario03,
          dadosItens.defUsuario04,
          dadosItens.defUsuario05,
          dadosItens.defUsuario06
        );
      } else {
        toast.error("Código já cadastrado na tabela!");
      }
    } else if (acao === "Editar" && dadosItens.acao === "EditarItem") {
      atualizarDadosDetalhe(
        tabela,
        dadosItens.codigo,
        dadosItens.descricao,
        dadosItens.ativo ? 1 : 0,
        dadosItens.defUsuario01,
        dadosItens.defUsuario02,
        dadosItens.defUsuario03,
        dadosItens.defUsuario04,
        dadosItens.defUsuario05,
        dadosItens.defUsuario06
      );
    }
  };

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Row>
              <Col sm={2}>
                <InputSemBorda
                  label="Cód. Tabela"
                  name="codtabela"
                  type="text"
                  placeholder="Cód. Tabela"
                  readonly
                  value={id_codigo || "novo"}
                  onChange={setIdCodigo}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  label="Tabela"
                  name="tabela"
                  type="text"
                  placeholder="Tabela"
                  required
                  readonly={
                    acao === "Visualizar" || acao === "Editar" ? true : false
                  }
                  value={tabela}
                  onChange={setTabela}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-uppercase"
                />
              </Col>
              <Col sm={5}>
                <InputSemBorda
                  label="Descrição"
                  name="descricao"
                  type="text"
                  placeholder="Descrição"
                  required
                  readonly={acao === "Visualizar" ? true : false}
                  value={descricao}
                  onChange={setDescricao}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
              <Col sm={2}>
                <br />
                <label htmlFor="" className="form-label me-2">
                  Ativo
                </label>
                <Switch
                  checked={ativo}
                  onChange={() => setAtivo(!ativo)}
                  disabled={acao === "Visualizar" ? true : false}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={2}>
                <Button
                  invisivel={false}
                  type="button"
                  className="btn btn-light text-bg-dark bg-white border-0"
                  descricaoBotao="Incluir Código"
                  icone={mdiPlus}
                  onclick={handleOpenModal}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Tabela
                coluna={colunas}
                itemsPerPage={5}
                dados={dados}
                onVisuClick={visualizar}
                onEditClick={editar}
                onDeleteClick={excluirItem}
                
                permissao_editar={false}
                permissao_visualizar={false}
                permissao_excluir={false}
              />
            </Row>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
              invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <Button
                invisivel={false}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalLoading show={loading} label="Carregado dados do perfil ..." />

      {/* MODAL PARA INCLUIR/EDITAR ITEM NA TABELA */}

      <ModalDetalhe
        showModal={showModalDetalhe}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        acao={dadosItens.acao}
        dadosCodigo={dadosItens.codigo}
        dadosDescricao={dadosItens.descricao}
        dadosDefUsuario01={dadosItens.defUsuario01}
        dadosDefUsuario02={dadosItens.defUsuario02}
        dadosDefUsuario03={dadosItens.defUsuario03}
        dadosDefUsuario04={dadosItens.defUsuario04}
        dadosDefUsuario05={dadosItens.defUsuario05}
        dadosDefUsuario06={dadosItens.defUsuario06}
        dadosAtivo={dadosItens.ativo}
      />
    </>
  );
};
export default FormCodSistema;
