import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../../../hooks/mascaras";
import { getSessionData } from "../../../../utils/storageUtils";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../../../components/dadosFixos/UF";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";


function BalancoForm() {

    const params = useParams()

    const navigate = useNavigate()

    const [cpf, set_cpf] = useState("")
    const [nome, set_nome] = useState("")
    const [email, set_email] = useState("")
    const [celular, set_celular] = useState("")
    const [telefone, set_telefone] = useState("")
    const [data_nascimento, set_data_nascimento] = useState("19-02-2004")
    const [cep, set_cep] = useState("")
    const [logradouro, set_logradouro] = useState("")
    const [complemento, set_complemento] = useState("")
    const [numero, set_numero] = useState("")
    const [bairro, set_bairro] = useState("")
    const [cidade, set_cidade] = useState("")
    const [codmunicipio, set_codmunicipio] = useState("")
    const [uf, set_uf] = useState("")
    const [obs, set_obs] = useState("")
    const [status, set_status] = useState(true)


    //inputs ATIVOS
    const [ativo1, set_ativo1] = useState("")
    const [valor1, set_valor1] = useState("")
    const [desc1, set_desc1] = useState("")

    const [ativo2, set_ativo2] = useState("")
    const [valor2, set_valor2] = useState("")
    const [desc2, set_desc2] = useState("")
    const [ativo3, set_ativo3] = useState("")
    const [valor3, set_valor3] = useState("")
    const [desc3, set_desc3] = useState("")
    const [ativo4, set_ativo4] = useState("")
    const [valor4, set_valor4] = useState("")
    const [desc4, set_desc4] = useState("")
    const [ativo5, set_ativo5] = useState("")
    const [valor5, set_valor5] = useState("")
    const [desc5, set_desc5] = useState("")
    const [ativo6, set_ativo6] = useState("")
    const [valor6, set_valor6] = useState("")
    const [desc6, set_desc6] = useState("")
    const [ativo7, set_ativo7] = useState("")
    const [valor7, set_valor7] = useState("")
    const [desc7, set_desc7] = useState("")
    const [ativo8, set_ativo8] = useState("")
    const [valor8, set_valor8] = useState("")
    const [desc8, set_desc8] = useState("")
    const [ativo9, set_ativo9] = useState("")
    const [valor9, set_valor9] = useState("")
    const [desc9, set_desc9] = useState("")
    const [ativo10, set_ativo10] = useState("")
    const [valor10, set_valor10] = useState("")
    const [desc10, set_desc10] = useState("")
    const [ativo11, set_ativo11] = useState("")
    const [valor11, set_valor11] = useState("")
    const [desc11, set_desc11] = useState("")
    const [ativo12, set_ativo12] = useState("")
    const [valor12, set_valor12] = useState("")
    const [desc12, set_desc12] = useState("")
    const [ativo13, set_ativo13] = useState("")
    const [valor13, set_valor13] = useState("")
    const [desc13, set_desc13] = useState("")
    const [ativo14, set_ativo14] = useState("")
    const [valor14, set_valor14] = useState("")
    const [desc14, set_desc14] = useState("")
    const [ativo15, set_ativo15] = useState("")
    const [valor15, set_valor15] = useState("")
    const [desc15, set_desc15] = useState("")
    const [ativo16, set_ativo16] = useState("")
    const [valor16, set_valor16] = useState("")
    const [desc16, set_desc16] = useState("")
    const [ativo17, set_ativo17] = useState("")
    const [valor17, set_valor17] = useState("")
    const [desc17, set_desc17] = useState("")
    const [ativo18, set_ativo18] = useState("")
    const [valor18, set_valor18] = useState("")
    const [desc18, set_desc18] = useState("")
    const [ativo19, set_ativo19] = useState("")
    const [valor19, set_valor19] = useState("")
    const [desc19, set_desc19] = useState("")
    const [ativo20, set_ativo20] = useState("")
    const [valor20, set_valor20] = useState("")
    const [desc20, set_desc20] = useState("")

    const [observacaoAtivos, set_observacaoAtivos] = useState("")


    const [passivo1, set_passivo1] = useState("")
    const [valor_passivo1, set_valor_passivo1] = useState("")
    const [descpassivo1, set_descpassivo1] = useState("")
    const [passivo2, set_passivo2] = useState("")
    const [valor_passivo2, set_valor_passivo2] = useState("")
    const [descpassivo2, set_descpassivo2] = useState("")
    const [passivo3, set_passivo3] = useState("")
    const [valor_passivo3, set_valor_passivo3] = useState("")
    const [descpassivo3, set_descpassivo3] = useState("")
    const [passivo4, set_passivo4] = useState("")
    const [valor_passivo4, set_valor_passivo4] = useState("")
    const [descpassivo4, set_descpassivo4] = useState("")
    const [passivo5, set_passivo5] = useState("")
    const [valor_passivo5, set_valor_passivo5] = useState("")
    const [descpassivo5, set_descpassivo5] = useState("")
    const [passivo6, set_passivo6] = useState("")
    const [valor_passivo6, set_valor_passivo6] = useState("")
    const [descpassivo6, set_descpassivo6] = useState("")
    const [passivo7, set_passivo7] = useState("")
    const [valor_passivo7, set_valor_passivo7] = useState("")
    const [descpassivo7, set_descpassivo7] = useState("")
    const [passivo8, set_passivo8] = useState("")
    const [valor_passivo8, set_valor_passivo8] = useState("")
    const [descpassivo8, set_descpassivo8] = useState("")
    const [passivo9, set_passivo9] = useState("")
    const [valor_passivo9, set_valor_passivo9] = useState("")
    const [descpassivo9, set_descpassivo9] = useState("")
    const [passivo10, set_passivo10] = useState("")
    const [valor_passivo10, set_valor_passivo10] = useState("")
    const [descpassivo10, set_descpassivo10] = useState("")
    const [passivo11, set_passivo11] = useState("")
    const [valor_passivo11, set_valor_passivo11] = useState("")
    const [descpassivo11, set_descpassivo11] = useState("")
    const [passivo12, set_passivo12] = useState("")
    const [valor_passivo12, set_valor_passivo12] = useState("")
    const [descpassivo12, set_descpassivo12] = useState("")
    const [passivo13, set_passivo13] = useState("")
    const [valor_passivo13, set_valor_passivo13] = useState("")
    const [descpassivo13, set_descpassivo13] = useState("")
    const [passivo14, set_passivo14] = useState("")
    const [valor_passivo14, set_valor_passivo14] = useState("")
    const [descpassivo14, set_descpassivo14] = useState("")
    const [passivo15, set_passivo15] = useState("")
    const [valor_passivo15, set_valor_passivo15] = useState("")
    const [descpassivo15, set_descpassivo15] = useState("")
    const [passivo16, set_passivo16] = useState("")
    const [valor_passivo16, set_valor_passivo16] = useState("")
    const [descpassivo16, set_descpassivo16] = useState("")
    const [passivo17, set_passivo17] = useState("")
    const [valor_passivo17, set_valor_passivo17] = useState("")
    const [descpassivo17, set_descpassivo17] = useState("")
    const [passivo18, set_passivo18] = useState("")
    const [valor_passivo18, set_valor_passivo18] = useState("")
    const [descpassivo18, set_descpassivo18] = useState("")
    const [passivo19, set_passivo19] = useState("")
    const [valor_passivo19, set_valor_passivo19] = useState("")
    const [descpassivo19, set_descpassivo19] = useState("")
    const [passivo20, set_passivo20] = useState("")
    const [valor_passivo20, set_valor_passivo20] = useState("")
    const [descpassivo20, set_descpassivo20] = useState("")

    const [observacaoPassivos, set_observacaoPassivos] = useState("")

    //#region Controla as guias
    const [guia, setGuia] = useState("1");
    const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
        setGuia(novaGuia);
    };

    function carregaBalanco(id_cliente: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/informa/balanco/${id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {

            if (resposta.data.codigo != 200) {

                toast.error(resposta.data.message)
            }
            else {

                //PASSIVOS INPUTS
                set_observacaoPassivos(resposta.data.info.orgfin_balancopassivo[0].observacao)

                set_passivo1(resposta.data.info.orgfin_balancopassivo[0]['01_passivo']);
                set_valor_passivo1(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['01_valor']));
                set_descpassivo1(resposta.data.info.orgfin_balancopassivo[0]['01_descricao']);

                set_passivo2(resposta.data.info.orgfin_balancopassivo[0]['02_passivo']);
                set_valor_passivo2(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['02_valor']));
                set_descpassivo2(resposta.data.info.orgfin_balancopassivo[0]['02_descricao']);

                set_passivo3(resposta.data.info.orgfin_balancopassivo[0]['03_passivo']);
                set_valor_passivo3(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['03_valor']));
                set_descpassivo3(resposta.data.info.orgfin_balancopassivo[0]['03_descricao']);

                set_passivo4(resposta.data.info.orgfin_balancopassivo[0]['04_passivo']);
                set_valor_passivo4(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['04_valor']));
                set_descpassivo4(resposta.data.info.orgfin_balancopassivo[0]['04_descricao']);

                set_passivo5(resposta.data.info.orgfin_balancopassivo[0]['05_passivo']);
                set_valor_passivo5(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['05_valor']));
                set_descpassivo5(resposta.data.info.orgfin_balancopassivo[0]['05_descricao']);

                set_passivo6(resposta.data.info.orgfin_balancopassivo[0]['06_passivo']);
                set_valor_passivo6(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['06_valor']));
                set_descpassivo6(resposta.data.info.orgfin_balancopassivo[0]['06_descricao']);


                set_passivo7(resposta.data.info.orgfin_balancopassivo[0]['07_passivo']);
                set_valor_passivo7(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['07_valor']));
                set_descpassivo7(resposta.data.info.orgfin_balancopassivo[0]['07_descricao']);

                set_passivo8(resposta.data.info.orgfin_balancopassivo[0]['08_passivo']);
                set_valor_passivo8(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['08_valor']));
                set_descpassivo8(resposta.data.info.orgfin_balancopassivo[0]['08_descricao']);

                set_passivo9(resposta.data.info.orgfin_balancopassivo[0]['09_passivo']);
                set_valor_passivo9(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['09_valor']));
                set_descpassivo9(resposta.data.info.orgfin_balancopassivo[0]['09_descricao']);

                set_passivo10(resposta.data.info.orgfin_balancopassivo[0]['10_passivo']);
                set_valor_passivo10(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['10_valor']));
                set_descpassivo10(resposta.data.info.orgfin_balancopassivo[0]['10_descricao']);

                set_passivo11(resposta.data.info.orgfin_balancopassivo[0]['11_passivo']);
                set_valor_passivo11(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['11_valor']));
                set_descpassivo11(resposta.data.info.orgfin_balancopassivo[0]['11_descricao']);

                set_passivo12(resposta.data.info.orgfin_balancopassivo[0]['12_passivo']);
                set_valor_passivo12(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['12_valor']));
                set_descpassivo12(resposta.data.info.orgfin_balancopassivo[0]['12_descricao']);

                set_passivo13(resposta.data.info.orgfin_balancopassivo[0]['13_passivo']);
                set_valor_passivo13(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['13_valor']));
                set_descpassivo13(resposta.data.info.orgfin_balancopassivo[0]['13_descricao']);

                set_passivo14(resposta.data.info.orgfin_balancopassivo[0]['14_passivo']);
                set_valor_passivo14(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['14_valor']));
                set_descpassivo14(resposta.data.info.orgfin_balancopassivo[0]['14_descricao']);

                set_passivo15(resposta.data.info.orgfin_balancopassivo[0]['15_passivo']);
                set_valor_passivo15(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['15_valor']));
                set_descpassivo15(resposta.data.info.orgfin_balancopassivo[0]['15_descricao']);

                set_passivo16(resposta.data.info.orgfin_balancopassivo[0]['16_passivo']);
                set_valor_passivo16(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['16_valor']));
                set_descpassivo16(resposta.data.info.orgfin_balancopassivo[0]['16_descricao']);

                set_passivo17(resposta.data.info.orgfin_balancopassivo[0]['17_passivo']);
                set_valor_passivo17(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['17_valor']));
                set_descpassivo17(resposta.data.info.orgfin_balancopassivo[0]['17_descricao']);

                set_passivo18(resposta.data.info.orgfin_balancopassivo[0]['18_passivo']);
                set_valor_passivo18(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['18_valor']));
                set_descpassivo18(resposta.data.info.orgfin_balancopassivo[0]['18_descricao']);

                set_passivo19(resposta.data.info.orgfin_balancopassivo[0]['19_passivo']);
                set_valor_passivo19(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['19_valor']));
                set_descpassivo19(resposta.data.info.orgfin_balancopassivo[0]['19_descricao']);

                set_passivo20(resposta.data.info.orgfin_balancopassivo[0]['20_passivo']);
                set_valor_passivo20(formatCusto(resposta.data.info.orgfin_balancopassivo[0]['20_valor']));
                set_descpassivo20(resposta.data.info.orgfin_balancopassivo[0]['20_descricao']);

                //INPUTS ATIVOS

                set_ativo9(resposta.data.info.orgfin_balancoativo[0]['09_Ativo']);
                set_valor9(formatCusto(resposta.data.info.orgfin_balancoativo[0]['09_valor']));
                set_desc9(resposta.data.info.orgfin_balancoativo[0]['09_descricao']);

                set_ativo10(resposta.data.info.orgfin_balancoativo[0]['10_Ativo']);
                set_valor10(formatCusto(resposta.data.info.orgfin_balancoativo[0]['10_valor']));
                set_desc10(resposta.data.info.orgfin_balancoativo[0]['10_descricao']);

                set_ativo11(resposta.data.info.orgfin_balancoativo[0]['11_Ativo']);
                set_valor11(formatCusto(resposta.data.info.orgfin_balancoativo[0]['11_valor']));
                set_desc11(resposta.data.info.orgfin_balancoativo[0]['11_descricao']);

                set_ativo12(resposta.data.info.orgfin_balancoativo[0]['12_Ativo']);
                set_valor12(formatCusto(resposta.data.info.orgfin_balancoativo[0]['12_valor']));
                set_desc12(resposta.data.info.orgfin_balancoativo[0]['12_descricao']);

                set_ativo13(resposta.data.info.orgfin_balancoativo[0]['13_Ativo']);
                set_valor13(formatCusto(resposta.data.info.orgfin_balancoativo[0]['13_valor']));
                set_desc13(resposta.data.info.orgfin_balancoativo[0]['13_descricao']);

                set_ativo14(resposta.data.info.orgfin_balancoativo[0]['14_Ativo']);
                set_valor14(formatCusto(resposta.data.info.orgfin_balancoativo[0]['14_valor']));
                set_desc14(resposta.data.info.orgfin_balancoativo[0]['14_descricao']);

                set_ativo15(resposta.data.info.orgfin_balancoativo[0]['15_Ativo']);
                set_valor15(formatCusto(resposta.data.info.orgfin_balancoativo[0]['15_valor']));
                set_desc15(resposta.data.info.orgfin_balancoativo[0]['15_descricao']);

                set_ativo16(resposta.data.info.orgfin_balancoativo[0]['16_Ativo']);
                set_valor16(formatCusto(resposta.data.info.orgfin_balancoativo[0]['16_valor']));
                set_desc16(resposta.data.info.orgfin_balancoativo[0]['16_descricao']);

                set_ativo17(resposta.data.info.orgfin_balancoativo[0]['17_Ativo']);
                set_valor17(formatCusto(resposta.data.info.orgfin_balancoativo[0]['17_valor']));
                set_desc17(resposta.data.info.orgfin_balancoativo[0]['17_descricao']);

                set_ativo18(resposta.data.info.orgfin_balancoativo[0]['18_Ativo']);
                set_valor18(formatCusto(resposta.data.info.orgfin_balancoativo[0]['18_valor']));
                set_desc18(resposta.data.info.orgfin_balancoativo[0]['18_descricao']);

                set_ativo19(resposta.data.info.orgfin_balancoativo[0]['19_Ativo']);
                set_valor19(formatCusto(resposta.data.info.orgfin_balancoativo[0]['19_valor']));
                set_desc19(resposta.data.info.orgfin_balancoativo[0]['19_descricao']);

                set_ativo20(resposta.data.info.orgfin_balancoativo[0]['20_Ativo']);
                set_valor20(formatCusto(resposta.data.info.orgfin_balancoativo[0]['20_valor']));
                set_desc20(resposta.data.info.orgfin_balancoativo[0]['20_descricao']);

                set_ativo7(resposta.data.info.orgfin_balancoativo[0]['07_Ativo']);
                set_valor7(formatCusto(resposta.data.info.orgfin_balancoativo[0]['07_valor']));
                set_desc7(resposta.data.info.orgfin_balancoativo[0]['07_descricao']);

                set_ativo8(resposta.data.info.orgfin_balancoativo[0]['08_Ativo']);
                set_valor8(formatCusto(resposta.data.info.orgfin_balancoativo[0]['08_valor']));
                set_desc8(resposta.data.info.orgfin_balancoativo[0]['08_descricao']);

                set_observacaoAtivos(resposta.data.info.orgfin_balancoativo[0].observacao)

                set_ativo1(resposta.data.info.orgfin_balancoativo[0]['01_Ativo']);
                set_valor1(formatCusto(resposta.data.info.orgfin_balancoativo[0]['01_valor']));
                set_desc1(resposta.data.info.orgfin_balancoativo[0]['01_descricao']);

                set_ativo2(resposta.data.info.orgfin_balancoativo[0]['02_Ativo']);
                set_valor2(formatCusto(resposta.data.info.orgfin_balancoativo[0]['02_valor']));
                set_desc2(resposta.data.info.orgfin_balancoativo[0]['02_descricao']);

                set_ativo3(resposta.data.info.orgfin_balancoativo[0]['03_Ativo']);
                set_valor3(formatCusto(resposta.data.info.orgfin_balancoativo[0]['03_valor']));
                set_desc3(resposta.data.info.orgfin_balancoativo[0]['03_descricao']);

                set_ativo4(resposta.data.info.orgfin_balancoativo[0]['04_Ativo']);
                set_valor4(formatCusto(resposta.data.info.orgfin_balancoativo[0]['04_valor']));
                set_desc4(resposta.data.info.orgfin_balancoativo[0]['04_descricao']);

                set_ativo5(resposta.data.info.orgfin_balancoativo[0]['05_Ativo']);
                set_valor5(formatCusto(resposta.data.info.orgfin_balancoativo[0]['05_valor']));
                set_desc5(resposta.data.info.orgfin_balancoativo[0]['05_descricao']);

                set_ativo6(resposta.data.info.orgfin_balancoativo[0]['06_Ativo']);
                set_valor6(formatCusto(resposta.data.info.info.orgfin_balancoativo[0]['06_valor']));
                set_desc6(resposta.data.info.orgfin_balancoativo[0]['06_descricao']);
            }
        }).catch(function (erro) {

            toast.error(erro)
        })
    }

    //modal loading
    const [carregando, set_carregando] = useState(false)
    function carregarCliente(id_cliente: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cadastro/cliente/${id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {

                set_carregando(false)
                if (resposta.data.codigo != 200) {

                    toast.error(resposta.data.message)
                }
                else {

                    const data = resposta.data.cliente[0].dtnascimento.split("T")
                    set_data_nascimento(data[0])

                    set_cpf(resposta.data.cliente[0].cpf)
                    set_nome(resposta.data.cliente[0].nome)
                    set_telefone(resposta.data.cliente[0].telfixo)
                    set_celular(resposta.data.cliente[0].telcelular)
                    set_email(resposta.data.cliente[0].email)
                    set_logradouro(resposta.data.cliente[0].logradouro)
                    set_numero(resposta.data.cliente[0].numero)
                    set_bairro(resposta.data.cliente[0].bairro)
                    set_complemento(resposta.data.cliente[0].compl)
                    set_codmunicipio(resposta.data.cliente[0].cnum)
                    set_cep(resposta.data.cliente[0].cep)
                    set_obs(resposta.data.cliente[0].observacao)
                    set_uf(resposta.data.cliente[0].cuf)
                    set_cidade(resposta.data.cliente[0].cidade)
                    set_status(resposta.data.cliente[0].status == 0 ? false : true)
                }
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }

    const [ListaAtivos, set_ListaAtivos] = useState([])
    const [Listapassivos, set_ListaPassivos] = useState([])
    function ListarCODSistema(tabela: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`)
            .then(function (resposta) {

                if (tabela == "ATIVOS") {

                    set_ListaAtivos(resposta.data)
                }
                else if (tabela == "PASSIVOS") {

                    set_ListaPassivos(resposta.data)
                }
                set_carregando(false)
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }


    function Cadastrar(e: any) {

        e.preventDefault()

        const dados = {

            ativo1, valor1: valor1.replaceAll(".", "").replaceAll(",", "."), desc1,
            ativo2, valor2: valor2.replaceAll(".", "").replaceAll(",", "."), desc2,
            ativo3, valor3: valor3.replaceAll(".", "").replaceAll(",", "."), desc3,
            ativo4, valor4: valor4.replaceAll(".", "").replaceAll(",", "."), desc4,
            ativo5, valor5: valor5.replaceAll(".", "").replaceAll(",", "."), desc5,
            ativo6, valor6: valor6.replaceAll(".", "").replaceAll(",", "."), desc6,
            ativo7, valor7: valor7.replaceAll(".", "").replaceAll(",", "."), desc7,
            ativo8, valor8: valor8.replaceAll(".", "").replaceAll(",", "."), desc8,
            ativo9, valor9: valor9.replaceAll(".", "").replaceAll(",", "."), desc9,
            ativo10, valor10: valor10.replaceAll(".", "").replaceAll(",", "."), desc10,
            ativo11, valor11: valor11.replaceAll(".", "").replaceAll(",", "."), desc11,
            ativo12, valor12: valor12.replaceAll(".", "").replaceAll(",", "."), desc12,
            ativo13, valor13: valor13.replaceAll(".", "").replaceAll(",", "."), desc13,
            ativo14, valor14: valor14.replaceAll(".", "").replaceAll(",", "."), desc14,
            ativo15, valor15: valor15.replaceAll(".", "").replaceAll(",", "."), desc15,
            ativo16, valor16: valor16.replaceAll(".", "").replaceAll(",", "."), desc16,
            ativo17, valor17: valor17.replaceAll(".", "").replaceAll(",", "."), desc17,
            ativo18, valor18: valor18.replaceAll(".", "").replaceAll(",", "."), desc18,
            ativo19, valor19: valor19.replaceAll(".", "").replaceAll(",", "."), desc19,
            ativo20, valor20: valor20.replaceAll(".", "").replaceAll(",", "."), desc20,
            observacaoAtivos,
            usuario_cadastro: getSessionData("NomeUsuario"),

            passivo1, valor_passivo1: valor_passivo1.replaceAll(".", "").replaceAll(",", "."), descpassivo1,
            passivo2, valor_passivo2: valor_passivo2.replaceAll(".", "").replaceAll(",", "."), descpassivo2,
            passivo3, valor_passivo3: valor_passivo3.replaceAll(".", "").replaceAll(",", "."), descpassivo3,
            passivo4, valor_passivo4: valor_passivo4.replaceAll(".", "").replaceAll(",", "."), descpassivo4,
            passivo5, valor_passivo5: valor_passivo5.replaceAll(".", "").replaceAll(",", "."), descpassivo5,
            passivo6, valor_passivo6: valor_passivo6.replaceAll(".", "").replaceAll(",", "."), descpassivo6,
            passivo7, valor_passivo7: valor_passivo7.replaceAll(".", "").replaceAll(",", "."), descpassivo7,
            passivo8, valor_passivo8: valor_passivo8.replaceAll(".", "").replaceAll(",", "."), descpassivo8,
            passivo9, valor_passivo9: valor_passivo9.replaceAll(".", "").replaceAll(",", "."), descpassivo9,
            passivo10, valor_passivo10: valor_passivo10.replaceAll(".", "").replaceAll(",", "."), descpassivo10,
            passivo11, valor_passivo11: valor_passivo11.replaceAll(".", "").replaceAll(",", "."), descpassivo11,
            passivo12, valor_passivo12: valor_passivo12.replaceAll(".", "").replaceAll(",", "."), descpassivo12,
            passivo13, valor_passivo13: valor_passivo13.replaceAll(".", "").replaceAll(",", "."), descpassivo13,
            passivo14, valor_passivo14: valor_passivo14.replaceAll(".", "").replaceAll(",", "."), descpassivo14,
            passivo15, valor_passivo15: valor_passivo15.replaceAll(".", "").replaceAll(",", "."), descpassivo15,
            passivo16, valor_passivo16: valor_passivo16.replaceAll(".", "").replaceAll(",", "."), descpassivo16,
            passivo17, valor_passivo17: valor_passivo17.replaceAll(".", "").replaceAll(",", "."), descpassivo17,
            passivo18, valor_passivo18: valor_passivo18.replaceAll(".", "").replaceAll(",", "."), descpassivo18,
            passivo19, valor_passivo19: valor_passivo19.replaceAll(".", "").replaceAll(",", "."), descpassivo19,
            passivo20, valor_passivo20: valor_passivo20.replaceAll(".", "").replaceAll(",", "."), descpassivo20,
            observacaoPassivos
        }
        

        set_carregando(true)

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/ativos/passivos/balanco/${getSessionData("DadosIdfilial")}/${params.id_cliente}`, dados, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {

            set_carregando(false)

            if (resposta.data.codigo != 200) {

                toast.error(resposta.data.message)
            }
            else {

                toast.success(resposta.data.message)
                setTimeout(() => {

                    navigate(-1)
                }, 2000)
            }
        }).catch(function (erro) {

            set_carregando(false)
            toast.error(erro)
        })
    }

    function formatCusto(custo: any) {
        // Remove pontos dos milhares e substitui vírgula decimal por ponto
        if (typeof custo === 'string') {
            custo = custo.replace(/\./g, '').replace(',', '.');
        }

        // Arredonda o número para o número especificado de casas decimais
        if (custo <= 0) {
            return "";
        } else {
            const roundedNumber = Number(custo).toFixed(2);
            const formattedNumber = Number(roundedNumber).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return formattedNumber;
        }
    }


    useEffect(function () {

        carregaBalanco(params.id_cliente)
        ListarCODSistema("ATIVOS")
        ListarCODSistema("PASSIVOS")
        carregarCliente(params.id_cliente)
    }, [])


    return (
        <>
            <div className="col">
                <ContainerTitulo titulo={"Balanço Patrimonial"} />

                <Card>
                    <Form onSubmit={Cadastrar}>
                        <Card.Body>
                            <TabContext value={guia}>
                                <TabList

                                    onChange={capturaGuia}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#000",
                                            color: "#000",
                                        },
                                    }}
                                >
                                    <Tab label="Dados Básicos" value="1" />
                                    <Tab label="Ativos" value="2" />
                                    <Tab label="Passivos" value="3" />
                                </TabList>
                                <TabPanel value="1">
                                    <Box sx={{ width: "100%" }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col d-flex">
                                                    <div className="me-3">
                                                        <InputSemBorda
                                                            name="fantasia"
                                                            type="text"

                                                            value={params.id_cliente || ""}
                                                            onChange={function () {

                                                            }}
                                                            readonly={true}
                                                            classNameInputsSemBorda="form-control bordasInferiorInput"
                                                            placeholder=" "

                                                            label="ID Cliente"
                                                        />
                                                    </div>
                                                    <div className="w-75">
                                                        <InputSemBorda
                                                            name="text"
                                                            type="text"
                                                            required
                                                            readonly={true}
                                                            value={mascaraCPF(cpf)}
                                                            onChange={set_cpf}
                                                            classNameInputsSemBorda="form-control bordasInferiorInput"
                                                            placeholder=" "
                                                            label="CPF"
                                                        />
                                                    </div>

                                                </div>

                                                <div className="col d-flex">
                                                    <div className="me-2">
                                                        <InputSemBorda
                                                            name="Data nasc."
                                                            type="date"
                                                            readonly={params.acao == "visualizar" ? true : false}
                                                            value={data_nascimento}
                                                            required
                                                            onChange={set_data_nascimento}
                                                            classNameInputsSemBorda="form-control bordasInferiorInput"
                                                            placeholder=" "
                                                            label="Data de nascimento"
                                                        />
                                                    </div>
                                                    <div className="w-75">
                                                        <InputSemBorda
                                                            name="nome"
                                                            type="text"
                                                            readonly={true}
                                                            value={nome}
                                                            required
                                                            onChange={set_nome}
                                                            classNameInputsSemBorda="form-control bordasInferiorInput"
                                                            placeholder=" "
                                                            label="Nome"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-100"></div>
                                                <div className="col">
                                                    <InputSemBorda
                                                        name="e-mail"
                                                        type="text"
                                                        readonly={true}
                                                        value={email}
                                                        required
                                                        onChange={set_email}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="E-mail"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <InputSemBorda
                                                        name="celular"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={mascaraTelefoneCelular(celular)}
                                                        onChange={set_celular}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Celular"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <InputSemBorda
                                                        name="telefone"
                                                        type="text"
                                                        readonly={true}
                                                        value={mascaraTelefoneFixo(telefone)}
                                                        onChange={set_telefone}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Telefone"
                                                    />
                                                </div>
                                                <div className="w-100"></div>
                                                <div className="col d-flex">
                                                    <div className="me-3">
                                                        <InputSemBorda
                                                            name="cep"
                                                            type="text"
                                                            required
                                                            readonly={true}
                                                            value={mascaraCEP(cep)}
                                                            onChange={set_cep}
                                                            onBlur={function () {
                                                                const cep_formatado = cep.replace(/[.\-/]/g, '')
                                                                set_cep(cep_formatado)
                                                            }}
                                                            classNameInputsSemBorda="form-control bordasInferiorInput"
                                                            placeholder=" "
                                                            label="Cep"
                                                        />
                                                    </div>
                                                    <div className="w-75">

                                                        <InputSemBorda
                                                            name="numero"
                                                            type="text"
                                                            required
                                                            readonly={true}
                                                            value={numero}
                                                            onChange={set_numero}
                                                            classNameInputsSemBorda="form-control bordasInferiorInput"
                                                            placeholder=" "
                                                            label="Numero"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <InputSemBorda
                                                        name="complemento"
                                                        type="text"
                                                        readonly={true}
                                                        value={complemento}
                                                        onChange={set_complemento}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Complemento"
                                                    />
                                                </div>
                                                <div className="col">

                                                    <InputSemBorda
                                                        name="Logradouro"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={logradouro}
                                                        onChange={set_logradouro}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Logradouro"
                                                    />

                                                </div>
                                                <div className="col">
                                                    <InputSemBorda
                                                        name="bairro"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={bairro}
                                                        onChange={set_bairro}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Bairro"
                                                    />
                                                </div>
                                                <div className="w-100"></div>
                                                <div className="col">
                                                    <InputSemBorda
                                                        name="cidade"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={cidade}
                                                        onChange={set_cidade}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Cidade"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <InputSemBorda
                                                        name="codmunicipio"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={codmunicipio}
                                                        onChange={set_codmunicipio}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Cód. Município"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <FormControl
                                                        required
                                                        variant="standard"
                                                        sx={{ m: 1.5, width: "100%" }}
                                                    >
                                                        <InputLabel>UF</InputLabel>
                                                        <Select
                                                            readOnly={params.acao == "visualizar" ? true : false}
                                                            value={uf}
                                                            onChange={(e: SelectChangeEvent) =>
                                                                set_uf(e.target.value)
                                                            }
                                                            label="UF"
                                                            required
                                                            disabled={true}
                                                        >
                                                            <MenuItem value="">
                                                                <em>Selecione...</em>
                                                            </MenuItem>
                                                            {dadosUF.map((item: any) => (
                                                                <MenuItem value={item.uf}>{item.nome}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="w-100"></div>
                                                <div className="col">
                                                    <InputSemBorda
                                                        name="Observação"
                                                        type="textarea"
                                                        readonly={true}
                                                        value={obs}
                                                        onChange={set_obs}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Observações"
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-check form-switch">
                                                        <label className="form-check-label">Ativo</label>
                                                        <input className="form-check-input w-25 me-1" disabled type="checkbox" id="flexSwitchCheckChecked" checked={status} onChange={function (e: any) {

                                                            set_status(e.target.checked)
                                                        }} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box sx={{ width: "100%" }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md col-lg d-flex overflow-auto">
                                                    <div className="col-sm col-md-1 col-lg-1 me-1">
                                                        <h4 className="text-center">#</h4>
                                                    </div>
                                                    <div className="col-sm col-md-3 col-lg-3 me-1">
                                                        <h4 className="text-center">ATIVOS</h4>
                                                    </div>
                                                    <div className="col-sm col-md-3 col-lg-3 me-1">
                                                        <h4 className="text-center">VALOR</h4>
                                                    </div>
                                                    <div className="col-sm col-md-3 col-lg-3 me-1">
                                                        <h4 className="text-center">DESCRIÇÃO</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md col-lg overflow-auto">
                                                    <div className="container">
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">1</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                                        value={ativo1}
                                                                        onChange={(e: SelectChangeEvent) =>
                                                                            set_ativo1(e.target.value)
                                                                        }
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={valor1}
                                                                    onChange={set_valor1}
                                                                    onBlur={function () {

                                                                        set_valor1(formatCusto(valor1))
                                                                    }}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={desc1}
                                                                    onChange={set_desc1}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">2</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                                        value={ativo2}
                                                                        onChange={(e: SelectChangeEvent) =>
                                                                            set_ativo2(e.target.value)
                                                                        }
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={valor2}
                                                                    onChange={set_valor2}
                                                                    onBlur={function () {

                                                                        set_valor2(formatCusto(valor2))
                                                                    }}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={desc2}
                                                                    onChange={set_desc2}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">3</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                                        value={ativo3}
                                                                        onChange={(e: SelectChangeEvent) =>
                                                                            set_ativo3(e.target.value)
                                                                        }
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={valor3}
                                                                    onChange={set_valor3}
                                                                    onBlur={function () {

                                                                        set_valor3(formatCusto(valor3))
                                                                    }}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={desc3}
                                                                    onChange={set_desc3}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">4</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                                        value={ativo4}
                                                                        onChange={(e: SelectChangeEvent) =>
                                                                            set_ativo4(e.target.value)
                                                                        }
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={valor4}
                                                                    onChange={set_valor4}
                                                                    onBlur={function () {

                                                                        set_valor4(formatCusto(valor4))
                                                                    }}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={desc4}
                                                                    onChange={set_desc4}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">5</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo5}
                                                                        onChange={(e) => set_ativo5(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor5}
                                                                    onChange={set_valor5}
                                                                    onBlur={() => set_valor5(formatCusto(valor5))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc5}
                                                                    onChange={set_desc5}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">6</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo6}
                                                                        onChange={(e) => set_ativo6(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor6}
                                                                    onChange={set_valor6}
                                                                    onBlur={() => set_valor6(formatCusto(valor6))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc6}
                                                                    onChange={set_desc6}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* Ativo 7 */}
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">7</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo7}
                                                                        onChange={(e) => set_ativo7(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor7}
                                                                    onChange={set_valor7}
                                                                    onBlur={() => set_valor7(formatCusto(valor7))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc7}
                                                                    onChange={set_desc7}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">8</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo8}
                                                                        onChange={(e) => set_ativo8(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor8}
                                                                    onChange={set_valor8}
                                                                    onBlur={() => set_valor8(formatCusto(valor8))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc8}
                                                                    onChange={set_desc8}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">9</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo9}
                                                                        onChange={(e) => set_ativo9(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor9}
                                                                    onChange={set_valor9}
                                                                    onBlur={() => set_valor9(formatCusto(valor9))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc9}
                                                                    onChange={set_desc9}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">10</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo10}
                                                                        onChange={(e) => set_ativo10(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor10}
                                                                    onChange={set_valor10}
                                                                    onBlur={() => set_valor10(formatCusto(valor10))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc10}
                                                                    onChange={set_desc10}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">11</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo11}
                                                                        onChange={(e) => set_ativo11(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor11}
                                                                    onChange={set_valor11}
                                                                    onBlur={() => set_valor11(formatCusto(valor11))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc11}
                                                                    onChange={set_desc11}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">12</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo12}
                                                                        onChange={(e) => set_ativo12(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor12}
                                                                    onChange={set_valor12}
                                                                    onBlur={() => set_valor12(formatCusto(valor12))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc12}
                                                                    onChange={set_desc12}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">13</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo13}
                                                                        onChange={(e) => set_ativo13(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor13}
                                                                    onChange={set_valor13}
                                                                    onBlur={() => set_valor13(formatCusto(valor13))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc13}
                                                                    onChange={set_desc13}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">14</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo14}
                                                                        onChange={(e) => set_ativo14(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor14}
                                                                    onChange={set_valor14}
                                                                    onBlur={() => set_valor14(formatCusto(valor14))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc14}
                                                                    onChange={set_desc14}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">15</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo15}
                                                                        onChange={(e) => set_ativo15(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor15}
                                                                    onChange={set_valor15}
                                                                    onBlur={() => set_valor15(formatCusto(valor15))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc15}
                                                                    onChange={set_desc15}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">16</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo16}
                                                                        onChange={(e) => set_ativo16(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor16}
                                                                    onChange={set_valor16}
                                                                    onBlur={() => set_valor16(formatCusto(valor16))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc16}
                                                                    onChange={set_desc16}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">17</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo17}
                                                                        onChange={(e) => set_ativo17(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor17}
                                                                    onChange={set_valor17}
                                                                    onBlur={() => set_valor17(formatCusto(valor17))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc17}
                                                                    onChange={set_desc17}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">18</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo18}
                                                                        onChange={(e) => set_ativo18(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor18}
                                                                    onChange={set_valor18}
                                                                    onBlur={() => set_valor18(formatCusto(valor18))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc18}
                                                                    onChange={set_desc18}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">19</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo19}
                                                                        onChange={(e) => set_ativo19(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor19}
                                                                    onChange={set_valor19}
                                                                    onBlur={() => set_valor19(formatCusto(valor19))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc19}
                                                                    onChange={set_desc19}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">20</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>ATIVO</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={ativo20}
                                                                        onChange={(e) => set_ativo20(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {ListaAtivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor20}
                                                                    onChange={set_valor20}
                                                                    onBlur={() => set_valor20(formatCusto(valor20))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={desc20}
                                                                    onChange={set_desc20}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100"></div>
                                                    <div className="ms-3 col-md col-lg col-sm">
                                                        <h4>Oberservações</h4>
                                                        <textarea name="" id="" cols={30} rows={15}
                                                            disabled={params.acao == "visualizar" ? true : false}
                                                            value={observacaoAtivos} onChange={function (e: any) {
                                                                set_observacaoAtivos(e.target.value)
                                                            }}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Box sx={{ width: "100%" }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md col-lg d-flex overflow-auto">
                                                    <div className="col-sm col-md-1 col-lg-1 me-1">
                                                        <h4 className="text-center">#</h4>
                                                    </div>
                                                    <div className="col-sm col-md-3 col-lg-3 me-1">
                                                        <h4 className="text-center">PASSIVOS</h4>
                                                    </div>
                                                    <div className="col-sm col-md-3 col-lg-3 me-1">
                                                        <h4 className="text-center">VALOR</h4>
                                                    </div>
                                                    <div className="col-sm col-md-3 col-lg-3 me-1">
                                                        <h4 className="text-center">DESCRIÇÃO</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md col-lg overflow-auto">
                                                    <div className="container">
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">1</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                                        value={passivo1}
                                                                        onChange={(e: SelectChangeEvent) =>
                                                                            set_passivo1(e.target.value)
                                                                        }
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={valor_passivo1}
                                                                    onChange={set_valor_passivo1}
                                                                    onBlur={function () {

                                                                        set_valor_passivo1(formatCusto(valor_passivo1))
                                                                    }}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="Descrição"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={descpassivo1}
                                                                    onChange={set_descpassivo1}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">2</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                                        value={passivo2}
                                                                        onChange={(e: SelectChangeEvent) =>
                                                                            set_passivo2(e.target.value)
                                                                        }
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={valor_passivo2}
                                                                    onChange={set_valor_passivo2}
                                                                    onBlur={function () {

                                                                        set_valor_passivo2(formatCusto(valor_passivo2))
                                                                    }}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={descpassivo2}
                                                                    onChange={set_descpassivo2}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">3</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                                        value={passivo3}
                                                                        onChange={(e: SelectChangeEvent) =>
                                                                            set_passivo3(e.target.value)
                                                                        }
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={valor_passivo3}
                                                                    onChange={set_valor_passivo3}
                                                                    onBlur={function () {

                                                                        set_valor_passivo3(formatCusto(valor_passivo3))
                                                                    }}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={descpassivo3}
                                                                    onChange={set_descpassivo3}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">4</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                                        value={passivo4}
                                                                        onChange={(e: SelectChangeEvent) =>
                                                                            set_passivo4(e.target.value)
                                                                        }
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={valor_passivo4}
                                                                    onChange={set_valor_passivo4}
                                                                    onBlur={function () {

                                                                        set_valor_passivo4(formatCusto(valor_passivo4))
                                                                    }}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao == "visualizar" ? true : false}
                                                                    type="text"
                                                                    value={descpassivo4}
                                                                    onChange={set_descpassivo4}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">5</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo5}
                                                                        onChange={(e) => set_passivo5(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo5}
                                                                    onChange={set_valor_passivo5}
                                                                    onBlur={() => set_valor_passivo5(formatCusto(valor_passivo5))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo5}
                                                                    onChange={set_descpassivo5}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">6</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo6}
                                                                        onChange={(e) => set_passivo6(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo6}
                                                                    onChange={set_valor_passivo6}
                                                                    onBlur={() => set_valor_passivo6(formatCusto(valor_passivo6))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo6}
                                                                    onChange={set_descpassivo6}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* passivo 7 */}
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">7</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo7}
                                                                        onChange={(e) => set_passivo7(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo7}
                                                                    onChange={set_valor_passivo7}
                                                                    onBlur={() => set_valor_passivo7(formatCusto(valor_passivo7))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo7}
                                                                    onChange={set_descpassivo7}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">8</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo8}
                                                                        onChange={(e) => set_passivo8(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo8}
                                                                    onChange={set_valor_passivo8}
                                                                    onBlur={() => set_valor_passivo8(formatCusto(valor_passivo8))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo8}
                                                                    onChange={set_descpassivo8}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">9</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo9}
                                                                        onChange={(e) => set_passivo9(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo9}
                                                                    onChange={set_valor_passivo9}
                                                                    onBlur={() => set_valor_passivo9(formatCusto(valor_passivo9))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo9}
                                                                    onChange={set_descpassivo9}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">10</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo10}
                                                                        onChange={(e) => set_passivo10(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo10}
                                                                    onChange={set_valor_passivo10}
                                                                    onBlur={() => set_valor_passivo10(formatCusto(valor_passivo10))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo10}
                                                                    onChange={set_descpassivo10}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">11</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo11}
                                                                        onChange={(e) => set_passivo11(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo11}
                                                                    onChange={set_valor_passivo11}
                                                                    onBlur={() => set_valor_passivo11(formatCusto(valor_passivo11))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo11}
                                                                    onChange={set_descpassivo11}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">12</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo12}
                                                                        onChange={(e) => set_passivo12(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo12}
                                                                    onChange={set_valor_passivo12}
                                                                    onBlur={() => set_valor_passivo12(formatCusto(valor_passivo12))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo12}
                                                                    onChange={set_descpassivo12}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">13</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo13}
                                                                        onChange={(e) => set_passivo13(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo13}
                                                                    onChange={set_valor_passivo13}
                                                                    onBlur={() => set_valor_passivo13(formatCusto(valor_passivo13))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo13}
                                                                    onChange={set_descpassivo13}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">14</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo14}
                                                                        onChange={(e) => set_passivo14(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo14}
                                                                    onChange={set_valor_passivo14}
                                                                    onBlur={() => set_valor_passivo14(formatCusto(valor_passivo14))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo14}
                                                                    onChange={set_descpassivo14}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">15</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo15}
                                                                        onChange={(e) => set_passivo15(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo15}
                                                                    onChange={set_valor_passivo15}
                                                                    onBlur={() => set_valor_passivo15(formatCusto(valor_passivo15))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo15}
                                                                    onChange={set_descpassivo15}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">16</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo16}
                                                                        onChange={(e) => set_passivo16(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo16}
                                                                    onChange={set_valor_passivo16}
                                                                    onBlur={() => set_valor_passivo16(formatCusto(valor_passivo16))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo16}
                                                                    onChange={set_descpassivo16}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">17</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo17}
                                                                        onChange={(e) => set_passivo17(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo17}
                                                                    onChange={set_valor_passivo17}
                                                                    onBlur={() => set_valor_passivo17(formatCusto(valor_passivo17))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo17}
                                                                    onChange={set_descpassivo17}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">18</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo18}
                                                                        onChange={(e) => set_passivo18(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo18}
                                                                    onChange={set_valor_passivo18}
                                                                    onBlur={() => set_valor_passivo18(formatCusto(valor_passivo18))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo18}
                                                                    onChange={set_descpassivo18}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">19</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo19}
                                                                        onChange={(e) => set_passivo19(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo19}
                                                                    onChange={set_valor_passivo19}
                                                                    onBlur={() => set_valor_passivo19(formatCusto(valor_passivo19))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo19}
                                                                    onChange={set_descpassivo19}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom">
                                                            <div className="col-sm col-md-1 col-lg-1 me-1">
                                                                <h4 className="mt-3 text-center">20</h4>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 me-1">
                                                                <FormControl
                                                                    variant="standard"
                                                                    sx={{ m: 1.5, width: "100%" }}
                                                                >
                                                                    <InputLabel>PASSIVOS</InputLabel>
                                                                    <Select
                                                                        readOnly={params.acao === "visualizar"}
                                                                        value={passivo20}
                                                                        onChange={(e) => set_passivo20(e.target.value)}
                                                                        label="PERFIL GERAL"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>Selecione...</em>
                                                                        </MenuItem>
                                                                        {Listapassivos.map((item: any) => (
                                                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-2">
                                                                <InputSemBorda
                                                                    name="Valor_passivo"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={valor_passivo20}
                                                                    onChange={set_valor_passivo20}
                                                                    onBlur={() => set_valor_passivo20(formatCusto(valor_passivo20))}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "

                                                                />
                                                            </div>
                                                            <div className="col-sm col-md-3 col-lg-3 ms-1">
                                                                <InputSemBorda
                                                                    name="descpassivorição"
                                                                    readonly={params.acao === "visualizar"}
                                                                    type="text"
                                                                    value={descpassivo20}
                                                                    onChange={set_descpassivo20}
                                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                                    placeholder=" "
                                                                    label="Descrição"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100"></div>
                                                    <div className="ms-3 col-md col-lg col-sm">
                                                        <h4>Oberservações</h4>
                                                        <textarea name="" id="" cols={30} rows={15}
                                                            disabled={params.acao == "visualizar" ? true : false}
                                                            value={observacaoPassivos} onChange={function (e: any) {
                                                                set_observacaoPassivos(e.target.value)
                                                            }}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                </TabPanel>
                            </TabContext>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-flex my-2 gap-1 justify-content-between">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Voltar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        navigate(-1);
                                    }}
                                />
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' ? true : false}
                                    type="submit"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Salvar"
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </Card.Footer>
                    </Form>
                </Card>
            </div>
            <ModalLoading show={carregando} />
        </>
    )
}

export default BalancoForm