import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import ContainerTitulo from "../../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../../../../hooks/mascaras";
import { getSessionData } from "../../../../../utils/storageUtils";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function Perfil() {


    const params = useParams()
    const token = getSessionData("MultfilialWebToken")
    const navigate = useNavigate()

    const [modalMesAnoBase, set_modalMesAnoBase] = useState(false)
    const [mesanobase, set_mesanobase] = useState("")
    const [meses, set_meses] = useState<any>([])
    function gerarMeses() {
        const dataToday = new Date();
        const anoAtual = dataToday.getFullYear(); // Obtém o ano atual
        const mesAtual = dataToday.getMonth() + 1; // Obtém o mês atual (de 0 a 11)
        const datas = [];

        // Gera 3 meses para trás
        let ano = anoAtual;
        let mes = mesAtual;
        for (let i = 0; i < 3; i++) {
            mes--;
            if (mes < 1) {
                mes = 12;
                ano--;
            }
            datas.unshift({ mes: mes.toString().padStart(2, '0'), ano: ano }); // Adiciona ao início do array
        }

        // Reseta para o mês atual e ano
        ano = anoAtual;
        mes = mesAtual;

        // Gera 12 meses para frente
        for (let i = 0; i < 12; i++) {
            datas.push({ mes: mes.toString().padStart(2, '0'), ano: ano });
            mes++;
            if (mes > 12) {
                mes = 1;
                ano++;
            }
        }

        // Atualiza o estado com o array de datas
        set_meses(datas);
    }
    //INPUTS ANALISE DE PERFIL
    const [PerfilGeral1, set_PerfilGeral1] = useState("")
    const [Resposta1, set_Resposta1] = useState("")
    const [Comentario1, set_Comentario1] = useState("")

    const [PerfilGeral2, set_PerfilGeral2] = useState("")
    const [Resposta2, set_Resposta2] = useState("")
    const [Comentario2, set_Comentario2] = useState("")

    const [PerfilGeral3, set_PerfilGeral3] = useState("")
    const [Resposta3, set_Resposta3] = useState("")
    const [Comentario3, set_Comentario3] = useState("")

    const [PerfilGeral4, set_PerfilGeral4] = useState("")
    const [Resposta4, set_Resposta4] = useState("")
    const [Comentario4, set_Comentario4] = useState("")

    const [PerfilGeral5, set_PerfilGeral5] = useState("")
    const [Resposta5, set_Resposta5] = useState("")
    const [Comentario5, set_Comentario5] = useState("")

    const [PerfilGeral6, set_PerfilGeral6] = useState("")
    const [Resposta6, set_Resposta6] = useState("")
    const [Comentario6, set_Comentario6] = useState("")

    const [PerfilGeral7, set_PerfilGeral7] = useState("")
    const [Resposta7, set_Resposta7] = useState("")
    const [Comentario7, set_Comentario7] = useState("")

    const [PerfilGeral8, set_PerfilGeral8] = useState("")
    const [Resposta8, set_Resposta8] = useState("")
    const [Comentario8, set_Comentario8] = useState("")

    const [PerfilGeral9, set_PerfilGeral9] = useState("")
    const [Resposta9, set_Resposta9] = useState("")
    const [Comentario9, set_Comentario9] = useState("")

    const [PerfilGeral10, set_PerfilGeral10] = useState("")
    const [Resposta10, set_Resposta10] = useState("")
    const [Comentario10, set_Comentario10] = useState("")

    const [PerfilGeral11, set_PerfilGeral11] = useState("")
    const [Resposta11, set_Resposta11] = useState("")
    const [Comentario11, set_Comentario11] = useState("")

    const [PerfilGeral12, set_PerfilGeral12] = useState("")
    const [Resposta12, set_Resposta12] = useState("")
    const [Comentario12, set_Comentario12] = useState("")

    const [PerfilGeral13, set_PerfilGeral13] = useState("")
    const [Resposta13, set_Resposta13] = useState("")
    const [Comentario13, set_Comentario13] = useState("")

    const [PerfilGeral14, set_PerfilGeral14] = useState("")
    const [Resposta14, set_Resposta14] = useState("")
    const [Comentario14, set_Comentario14] = useState("")

    const [PerfilGeral15, set_PerfilGeral15] = useState("")
    const [Resposta15, set_Resposta15] = useState("")
    const [Comentario15, set_Comentario15] = useState("")

    const [PerfilGeral16, set_PerfilGeral16] = useState("")
    const [Resposta16, set_Resposta16] = useState("")
    const [Comentario16, set_Comentario16] = useState("")

    const [PerfilGeral17, set_PerfilGeral17] = useState("")
    const [Resposta17, set_Resposta17] = useState("")
    const [Comentario17, set_Comentario17] = useState("")

    const [PerfilGeral18, set_PerfilGeral18] = useState("")
    const [Resposta18, set_Resposta18] = useState("")
    const [Comentario18, set_Comentario18] = useState("")

    const [ObservacaoAP, set_ObservacaoAP] = useState("")

    //LISTA DE ARRAY - CODSISTEMA
    const [ListaPerfilGeral, set_PerfilGeral] = useState([])
    const [ListaResposta, set_Resposta] = useState([])


    const [carregando, set_carregando] = useState(false)

    function ListarCODSistema(tabela: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`)
            .then(function (resposta) {


                if (tabela == "PERFIL") {

                    set_PerfilGeral(resposta.data)
                }
                else if (tabela == "PERFILRESP") {

                    set_Resposta(resposta.data)
                }

                set_carregando(false)
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }

    async function CriarNovaAnaliseDePerfil(e: any) {

        try {
            e.preventDefault()
            const dados = {

                PerfilGeral1,
                Resposta1,
                Comentario1,

                PerfilGeral2,
                Resposta2,
                Comentario2,

                PerfilGeral3,
                Resposta3,
                Comentario3,

                PerfilGeral4,
                Resposta4,
                Comentario4,

                PerfilGeral5,
                Resposta5,
                Comentario5,

                PerfilGeral6,
                Resposta6,
                Comentario6,

                PerfilGeral7,
                Resposta7,
                Comentario7,

                PerfilGeral8,
                Resposta8,
                Comentario8,

                PerfilGeral9,
                Resposta9,
                Comentario9,
                PerfilGeral10,
                Resposta10,
                Comentario10,
                PerfilGeral11,
                Resposta11,
                Comentario11,

                PerfilGeral12,
                Resposta12,
                Comentario12,

                PerfilGeral13,
                Resposta13,
                Comentario13,

                PerfilGeral14,
                Resposta14,
                Comentario14,

                PerfilGeral15,
                Resposta15,
                Comentario15,

                PerfilGeral16,
                Resposta16,
                Comentario16,

                PerfilGeral17,
                Resposta17,
                Comentario17,

                PerfilGeral18,
                Resposta18,
                Comentario18,

                usuarioCadastro: getSessionData("NomeUsuario"),
                ObservacaoAP,
                mesanobase
            }

            const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/analisedeperfil/${getSessionData("DadosIdfilial")}/${params.id_cliente}`, dados, {
                headers: {
                    Authorization: token
                }
            })

            if (resposta.data.codigo == 200) {

                toast.success(resposta.data.message)
                setTimeout(function () {

                    navigate(-1)
                }, 2000)
            }
            else {

                toast.error(resposta.data.message)
            }

        } catch (error: any) {

            toast.error(error)
        }
    }

    async function carregarAnaliseDePerfil(id_cliente: any, id_analisedeperfil: any) {

        try {

            set_carregando(true)
            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/analisedeperfil/${id_analisedeperfil}/${id_cliente}`, {
                headers: {
                    Authorization: token
                }
            })

            if (resposta.data.codigo == 200) {

                set_PerfilGeral1(resposta.data.analise_perfil[0].codperfil_01)
                set_Resposta1(resposta.data.analise_perfil[0].codresposta_01)
                set_Comentario1(resposta.data.analise_perfil[0].comentario_01)
                set_PerfilGeral2(resposta.data.analise_perfil[0].codperfil_02)
                set_Resposta2(resposta.data.analise_perfil[0].codresposta_02)
                set_Comentario2(resposta.data.analise_perfil[0].comentario_02)
                set_PerfilGeral3(resposta.data.analise_perfil[0].codperfil_03)
                set_Resposta3(resposta.data.analise_perfil[0].codresposta_03)
                set_Comentario3(resposta.data.analise_perfil[0].comentario_03)
                set_PerfilGeral4(resposta.data.analise_perfil[0].codperfil_04)
                set_Resposta4(resposta.data.analise_perfil[0].codresposta_04)
                set_Comentario4(resposta.data.analise_perfil[0].comentario_04)
                set_PerfilGeral5(resposta.data.analise_perfil[0].codperfil_05)
                set_Resposta5(resposta.data.analise_perfil[0].codresposta_05)
                set_Comentario5(resposta.data.analise_perfil[0].comentario_05)
                set_PerfilGeral6(resposta.data.analise_perfil[0].codperfil_06)
                set_Resposta6(resposta.data.analise_perfil[0].codresposta_06)
                set_Comentario6(resposta.data.analise_perfil[0].comentario_06)
                set_ObservacaoAP(resposta.data.analise_perfil[0].observacao)
                set_PerfilGeral7(resposta.data.analise_perfil[0].codperfil_07)
                set_Resposta7(resposta.data.analise_perfil[0].codresposta_07)
                set_Comentario7(resposta.data.analise_perfil[0].comentario_07)
                set_PerfilGeral8(resposta.data.analise_perfil[0].codperfil_08)
                set_Resposta8(resposta.data.analise_perfil[0].codresposta_08)
                set_Comentario8(resposta.data.analise_perfil[0].comentario_08)
                set_PerfilGeral9(resposta.data.analise_perfil[0].codperfil_09)
                set_Resposta9(resposta.data.analise_perfil[0].codresposta_09)
                set_Comentario9(resposta.data.analise_perfil[0].comentario_09)
                set_PerfilGeral10(resposta.data.analise_perfil[0].codperfil_10)
                set_Resposta10(resposta.data.analise_perfil[0].codresposta_10)
                set_Comentario10(resposta.data.analise_perfil[0].comentario_10)
                set_PerfilGeral11(resposta.data.analise_perfil[0].codperfil_11)
                set_Resposta11(resposta.data.analise_perfil[0].codresposta_11)
                set_Comentario11(resposta.data.analise_perfil[0].comentario_11)
                set_PerfilGeral12(resposta.data.analise_perfil[0].codperfil_12)
                set_Resposta12(resposta.data.analise_perfil[0].codresposta_12)
                set_Comentario12(resposta.data.analise_perfil[0].comentario_12)
                set_PerfilGeral13(resposta.data.analise_perfil[0].codperfil_13)
                set_Resposta13(resposta.data.analise_perfil[0].codresposta_13)
                set_Comentario13(resposta.data.analise_perfil[0].comentario_13)
                set_PerfilGeral14(resposta.data.analise_perfil[0].codperfil_14)
                set_Resposta14(resposta.data.analise_perfil[0].codresposta_14)
                set_Comentario14(resposta.data.analise_perfil[0].comentario_14)
                set_PerfilGeral15(resposta.data.analise_perfil[0].codperfil_15)
                set_Resposta15(resposta.data.analise_perfil[0].codresposta_15)
                set_Comentario15(resposta.data.analise_perfil[0].comentario_15)
                set_PerfilGeral16(resposta.data.analise_perfil[0].codperfil_16)
                set_Resposta16(resposta.data.analise_perfil[0].codresposta_16)
                set_Comentario16(resposta.data.analise_perfil[0].comentario_16)
                set_PerfilGeral17(resposta.data.analise_perfil[0].codperfil_17)
                set_Resposta17(resposta.data.analise_perfil[0].codresposta_17)
                set_Comentario17(resposta.data.analise_perfil[0].comentario_17)
                set_PerfilGeral18(resposta.data.analise_perfil[0].codperfil_18)
                set_Resposta18(resposta.data.analise_perfil[0].codresposta_18)
                set_Comentario18(resposta.data.analise_perfil[0].comentario_18)
                set_ObservacaoAP(resposta.data.analise_perfil[0].observacao)
                set_mesanobase(resposta.data.analise_perfil[0].mesanobase)
                set_carregando(false)
            }
            else {
                set_carregando(false)
                toast.error(resposta.data.message)
            }
        } catch (error: any) {

            toast.error(error)
        }
    }


    useEffect(function () {

        ListarCODSistema("PERFIL")
        ListarCODSistema("PERFILRESP")

        if (params.acao != 'novo') {

            carregarAnaliseDePerfil(params.id_cliente, params.id_analisedeperfil)
        }
        else {
            set_modalMesAnoBase(true)
            gerarMeses()
        }
    }, [])

    async function AtualizarAnaliseDePerfil(e: any) {

        try {

            e.preventDefault()
            const dados = {

                PerfilGeral1,
                Resposta1,
                Comentario1,

                PerfilGeral2,
                Resposta2,
                Comentario2,

                PerfilGeral3,
                Resposta3,
                Comentario3,

                PerfilGeral4,
                Resposta4,
                Comentario4,

                PerfilGeral5,
                Resposta5,
                Comentario5,

                PerfilGeral6,
                Resposta6,
                Comentario6,

                PerfilGeral7,
                Resposta7,
                Comentario7,

                PerfilGeral8,
                Resposta8,
                Comentario8,

                PerfilGeral9,
                Resposta9,
                Comentario9,
                PerfilGeral10,
                Resposta10,
                Comentario10,
                PerfilGeral11,
                Resposta11,
                Comentario11,

                PerfilGeral12,
                Resposta12,
                Comentario12,

                PerfilGeral13,
                Resposta13,
                Comentario13,

                PerfilGeral14,
                Resposta14,
                Comentario14,

                PerfilGeral15,
                Resposta15,
                Comentario15,

                PerfilGeral16,
                Resposta16,
                Comentario16,

                PerfilGeral17,
                Resposta17,
                Comentario17,

                PerfilGeral18,
                Resposta18,
                Comentario18,
                ObservacaoAP
            }

            set_carregando(true)
            const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/analisedeperfil/${params.id_analisedeperfil}/${params.id_cliente}`, dados, {
                headers: {
                    Authorization: token
                }
            })

            toast.success(resposta.data)
            set_carregando(false)
            setTimeout(function () {

                navigate(-1)
            }, 2000)


        } catch (error: any) {
            set_carregando(false)
            toast.error(error.response.data || error.response.statusText)
        }
    }

    return (
        <>
            <ContainerTitulo titulo={params.acao == "novo" ? "Nova Analise de Perfil " + mesanobase : params.acao == "editar" ? "Editando Analise de Perfil " + mesanobase : "Visualizando Analise de perfil " + mesanobase} />
            <Card>
                <form onSubmit={params.acao == "novo" ? CriarNovaAnaliseDePerfil : AtualizarAnaliseDePerfil}>
                    <Card.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md col-lg d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">#</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">

                                        <h4 className="text-center">PERFIL GERAL</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">

                                        <h4 className="text-center">RESPOSTA</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">

                                        <h4 className="text-center">COMENTÁRIO</h4>
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-md col-lg d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="mt-3 text-center">1</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>PERFIL GERAL</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={PerfilGeral1}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_PerfilGeral1(e.target.value)
                                                }
                                                label="PERFIL GERAL"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaPerfilGeral.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RESPOSTA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Resposta1}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Resposta1(e.target.value)
                                                }
                                                label="RESPOSTA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaResposta.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="COMENTÁRIO"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="textarea"
                                            value={Comentario1}
                                            onChange={set_Comentario1}
                                            classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="COMENTÁRIO"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>

                                <div className="col-md col-lg d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="mt-3 text-center">2</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>PERFIL GERAL</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={PerfilGeral2}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_PerfilGeral2(e.target.value)
                                                }
                                                label="PERFIL GERAL"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaPerfilGeral.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RESPOSTA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Resposta2}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Resposta2(e.target.value)
                                                }
                                                label="RESPOSTA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaResposta.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="COMENTÁRIO"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="textarea"
                                            value={Comentario2}
                                            onChange={set_Comentario2}

                                            classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="COMENTÁRIO"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>


                                <div className="col-md col-lg d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="mt-3 text-center">3</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>PERFIL GERAL</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={PerfilGeral3}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_PerfilGeral3(e.target.value)
                                                }
                                                label="PERFIL GERAL"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaPerfilGeral.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RESPOSTA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Resposta3}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Resposta3(e.target.value)
                                                }
                                                label="RESPOSTA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaResposta.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="COMENTÁRIO"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="textarea"
                                            value={Comentario3}
                                            onChange={set_Comentario3}

                                            classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="COMENTÁRIO"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>

                                <div className="col-md col-lg d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="mt-3 text-center">4</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>PERFIL GERAL</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={PerfilGeral4}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_PerfilGeral4(e.target.value)
                                                }
                                                label="PERFIL GERAL"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaPerfilGeral.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RESPOSTA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Resposta4}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Resposta4(e.target.value)
                                                }
                                                label="RESPOSTA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaResposta.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="COMENTÁRIO"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="textarea"
                                            value={Comentario4}
                                            onChange={set_Comentario4}

                                            classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="COMENTÁRIO"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>

                                <div className="col-md col-lg d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="mt-3 text-center">5</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>PERFIL GERAL</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={PerfilGeral5}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_PerfilGeral5(e.target.value)
                                                }
                                                label="PERFIL GERAL"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaPerfilGeral.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RESPOSTA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Resposta5}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Resposta5(e.target.value)
                                                }
                                                label="RESPOSTA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaResposta.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="COMENTÁRIO"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="textarea"
                                            value={Comentario5}
                                            onChange={set_Comentario5}
                                            classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="COMENTÁRIO"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>

                                <div className="col-md col-lg d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="mt-3 text-center">6</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>PERFIL GERAL</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={PerfilGeral6}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_PerfilGeral6(e.target.value)
                                                }
                                                label="PERFIL GERAL"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaPerfilGeral.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RESPOSTA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Resposta6}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Resposta6(e.target.value)
                                                }
                                                label="RESPOSTA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaResposta.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="COMENTÁRIO"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="textarea"
                                            value={Comentario6}
                                            onChange={set_Comentario6}
                                            classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="COMENTÁRIO"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-100"></div>

                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">7</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral7}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral7(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta7}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta7(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario7}
                                        onChange={set_Comentario7}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>


                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">8</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral8}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral8(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta8}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta8(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={Comentario8}
                                        onChange={set_Comentario8}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">9</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral9}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral9(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta9}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta9(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario9}
                                        onChange={set_Comentario9}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">10</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral10}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral10(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta10}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta10(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario10}
                                        onChange={set_Comentario10}

                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">11</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral11}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral11(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta11}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta11(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario11}
                                        onChange={set_Comentario11}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">12</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral12}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral12(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta12}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta12(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario12}

                                        onChange={set_Comentario12}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">13</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral13}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral13(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta13}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta13(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario13}
                                        onChange={set_Comentario13}

                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">14</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral14}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral14(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta14}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta14(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario14}
                                        onChange={set_Comentario14}

                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">15</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral15}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral15(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta15}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta15(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario15}
                                        onChange={set_Comentario15}

                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">16</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral16}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral16(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta16}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta16(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario16}
                                        onChange={set_Comentario16}

                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">17</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral17}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral17(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta17}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta17(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario17}
                                        onChange={set_Comentario17}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "

                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="w-100"></div>
                            <div className="col-md col-lg d-flex overflow-auto">
                                <div className="col-md-1 col-lg-1 me-1">
                                    <h4 className="mt-3 text-center">18</h4>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>PERFIL GERAL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PerfilGeral18}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PerfilGeral18(e.target.value)
                                            }
                                            label="PERFIL GERAL"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaPerfilGeral.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-4 col-lg-3 me-1">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>RESPOSTA</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={Resposta18}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_Resposta18(e.target.value)
                                            }
                                            label="RESPOSTA"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaResposta.map((item: any) => (
                                                <MenuItem value={item.value}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ms-2 col-md-4 col-lg-3">
                                    <InputSemBorda
                                        name="COMENTÁRIO"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={Comentario18}
                                        onChange={set_Comentario18}

                                        classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="COMENTÁRIO"
                                    />
                                </div>
                            </div>

                            <div className="ms-3 col-md col-lg col-sm">
                                <h4>Oberservações</h4>
                                <textarea name="" id="" cols={30} rows={15}
                                    disabled={params.acao == "visualizar" ? true : false}
                                    value={ObservacaoAP} onChange={function (e: any) {
                                        set_ObservacaoAP(e.target.value)
                                    }}>
                                </textarea>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={false}
                                disabled={params.acao == 'visualizar' ? true : false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                            />
                        </div>
                    </Card.Footer>
                </form>
            </Card>
            <ModalLoading show={carregando} />

            <Modal show={modalMesAnoBase}>

                <Modal.Header className="bg-white">
                    <Modal.Title>Mês/Ano Base da Analise de Perfil a ser criada.</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Defina o mês/ano do inicio do Fluxo a ser criado.</h4>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select name="" id="" onChange={function (e) {
                                    const data = e.target.value.split("-")
                                    set_mesanobase(data[1] + "/" + data[0])
                                }} className="select-control bordasInferiorInput w-100">
                                    <option value="" selected></option>
                                    {meses.map(function (data: any) {

                                        return <option className="text-center" value={`${data.ano}-${data.mes}`}>{`${data.mes}/${data.ano}`}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Cancelar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        set_modalMesAnoBase(false)
                                        navigate(-1);
                                    }}
                                />
                            </div>
                            <div className="col text-end">
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' ? true : false}
                                    type="button"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Continuar"
                                    onclick={function () {
                                        set_modalMesAnoBase(false)
                                    }}
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Perfil