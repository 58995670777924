import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import ContainerTitulo from "../../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../../../../hooks/mascaras";
import { getSessionData } from "../../../../../utils/storageUtils";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function Sucessao() {

    const params = useParams()
    const token = getSessionData("MultfilialWebToken")
    const filial = getSessionData("DadosIdfilial")
    const navigate = useNavigate()


    //LISTA DE ARRAY - CODSISTEMA
    const [ListaNacionalidade, set_ListaNacionalidade] = useState([])
    const [ListaEstadoCivil, set_ListaEstadoCivil] = useState([])
    const [ListaParentesco, set_ListaParentesco] = useState([])


    const [modalMesAnoBase, set_modalMesAnoBase] = useState(false)
    const [mesanobase, set_mesanobase] = useState("")
    const [meses, set_meses] = useState<any>([])
    function gerarMeses() {
        const dataToday = new Date();
        const anoAtual = dataToday.getFullYear(); // Obtém o ano atual
        const mesAtual = dataToday.getMonth() + 1; // Obtém o mês atual (de 0 a 11)
        const datas = [];

        // Gera 3 meses para trás
        let ano = anoAtual;
        let mes = mesAtual;
        for (let i = 0; i < 3; i++) {
            mes--;
            if (mes < 1) {
                mes = 12;
                ano--;
            }
            datas.unshift({ mes: mes.toString().padStart(2, '0'), ano: ano }); // Adiciona ao início do array
        }

        // Reseta para o mês atual e ano
        ano = anoAtual;
        mes = mesAtual;

        // Gera 12 meses para frente
        for (let i = 0; i < 12; i++) {
            datas.push({ mes: mes.toString().padStart(2, '0'), ano: ano });
            mes++;
            if (mes > 12) {
                mes = 1;
                ano++;
            }
        }

        // Atualiza o estado com o array de datas
        set_meses(datas);
    }


    //INPUTS SUCESSÃO
    const [FilhosComunsCliente, set_FilhosComunsCliente] = useState("0")
    const [FilhosParticularesCliente, set_FilhosParticularesCliente] = useState("0")
    const [FilhosComunsCompanheiro, set_FilhosComunsCompanheiro] = useState("0")
    const [FilhosParticularesCompanheiro, set_FilhosParticularesCompanheiro] = useState("0")

    const [MaeCliente, set_MaeCliente] = useState("0")
    const [MaeCompanheiro, set_MaeCompanheiro] = useState("0")
    const [PaisCliente, set_PaisCliente] = useState("0")
    const [PaisCompanheiro, set_PaisCompanheiro] = useState("0")

    const [nomeFilho1, set_nomeFilho1] = useState("")
    const [dataNascFilho1, set_dataNascFilho1] = useState("")
    const [idadeFilho1, set_idadeFilho1] = useState("")
    const [nacionalidadeFilho1, set_nacionalidadeFilho1] = useState("")
    const [estadoCivilFilho1, set_estadoCivilFilho1] = useState("")
    const [grauparentestoFilho1, set_grauparentestoFilho1] = useState("")

    const [nomeFilho2, set_nomeFilho2] = useState("")
    const [dataNascFilho2, set_dataNascFilho2] = useState("")
    const [idadeFilho2, set_idadeFilho2] = useState("")
    const [nacionalidadeFilho2, set_nacionalidadeFilho2] = useState("")
    const [estadoCivilFilho2, set_estadoCivilFilho2] = useState("")
    const [grauparentestoFilho2, set_grauparentestoFilho2] = useState("")

    const [nomeFilho3, set_nomeFilho3] = useState("")
    const [dataNascFilho3, set_dataNascFilho3] = useState("")
    const [idadeFilho3, set_idadeFilho3] = useState("")
    const [nacionalidadeFilho3, set_nacionalidadeFilho3] = useState("")
    const [estadoCivilFilho3, set_estadoCivilFilho3] = useState("")
    const [grauparentestoFilho3, set_grauparentestoFilho3] = useState("")

    const [nomeFilho4, set_nomeFilho4] = useState("")
    const [dataNascFilho4, set_dataNascFilho4] = useState("")
    const [idadeFilho4, set_idadeFilho4] = useState("")
    const [nacionalidadeFilho4, set_nacionalidadeFilho4] = useState("")
    const [estadoCivilFilho4, set_estadoCivilFilho4] = useState("")
    const [grauparentestoFilho4, set_grauparentestoFilho4] = useState("")

    const [nomeFilho5, set_nomeFilho5] = useState("")
    const [dataNascFilho5, set_dataNascFilho5] = useState("")
    const [idadeFilho5, set_idadeFilho5] = useState("")
    const [nacionalidadeFilho5, set_nacionalidadeFilho5] = useState("")
    const [estadoCivilFilho5, set_estadoCivilFilho5] = useState("")
    const [grauparentestoFilho5, set_grauparentestoFilho5] = useState("")

    const [estadoCivil, setEstadoCivil] = useState("")
    const [estadoCivilComp, setEstadoCivilComp] = useState("")

    const [observacaoSuc, set_observacaoSuc] = useState("")

    const [carregando, set_carregando] = useState(false)

    function ListarCODSistema(tabela: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`)
            .then(function (resposta) {


                if (tabela == "NACIONALIDADE") {

                    set_ListaNacionalidade(resposta.data)
                }
                else if (tabela == "ESTADOCIVIL") {

                    set_ListaEstadoCivil(resposta.data)
                }
                else if (tabela == "PARENTESCO") {

                    set_ListaParentesco(resposta.data)
                }

                set_carregando(false)
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }

    async function criarSucessao(e: any) {

        try {
            e.preventDefault()
            const dados = {
                usuarioCadastro: getSessionData("NomeUsuario"),
                mesanobase,
                FilhosComunsCliente,
                FilhosParticularesCliente,
                FilhosComunsCompanheiro,
                FilhosParticularesCompanheiro,
                MaeCliente,
                MaeCompanheiro,
                PaisCliente,
                PaisCompanheiro,

                nomeFilho1,
                dataNascFilho1,
                idadeFilho1,
                nacionalidadeFilho1,
                estadoCivilFilho1,
                grauparentestoFilho1,

                nomeFilho2,
                dataNascFilho2,
                idadeFilho2,
                nacionalidadeFilho2,
                estadoCivilFilho2,
                grauparentestoFilho2,

                nomeFilho3,
                dataNascFilho3,
                idadeFilho3,
                nacionalidadeFilho3,
                estadoCivilFilho3,
                grauparentestoFilho3,

                nomeFilho4,
                dataNascFilho4,
                idadeFilho4,
                nacionalidadeFilho4,
                estadoCivilFilho4,
                grauparentestoFilho4,

                nomeFilho5,
                dataNascFilho5,
                idadeFilho5,
                nacionalidadeFilho5,
                estadoCivilFilho5,
                grauparentestoFilho5,
                observacaoSuc,

                estadoCivil,
                estadoCivilComp
            }

            const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/sucessao/${filial}/${params.id_cliente}`, dados, {
                headers: {
                    Authorization: token
                }
            })

            toast.success(resposta.data)
            setTimeout(() => {
                navigate(-1)
            }, 2000)
        } catch (error: any) {

            toast.error(error.response.data || error.statusText)
        }
    }

    async function carregarSucessao(id_sucessao: any) {

        try {

            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/sucessao/${id_sucessao}/${params.id_cliente}`, {
                headers: {
                    Authorization: token
                }
            })

            //inputs sucessao
            set_FilhosComunsCliente(resposta.data[0].numfilhoscomum_cliente)
            set_FilhosParticularesCliente(resposta.data[0].numfilhospart_cliente)
            set_FilhosComunsCompanheiro(resposta.data[0].numfilhoscomum_comp)
            set_FilhosParticularesCompanheiro(resposta.data[0].numfilhospart_comp)
            set_PaisCliente(resposta.data[0].paivivo_cliente)
            set_MaeCliente(resposta.data[0].maeviva_cliente)
            set_PaisCompanheiro(resposta.data[0].paivivo_comp)
            set_MaeCompanheiro(resposta.data[0].maeviva_comp)

            set_nomeFilho1(resposta.data[0].dep01_nome)
            set_dataNascFilho1(resposta.data[0].dep01_nascimento == null ? resposta.data[0].dep01_nascimento : resposta.data[0].dep01_nascimento.split("T")[0])
            set_idadeFilho1(resposta.data[0].dep01_idade)
            set_nacionalidadeFilho1(resposta.data[0].dep01_nacionalidade)
            set_estadoCivilFilho1(resposta.data[0].dep01_estadocivil)
            set_grauparentestoFilho1(resposta.data[0].dep01_grauparentesco)

            set_nomeFilho2(resposta.data[0].dep02_nome)
            set_dataNascFilho2(resposta.data[0].dep02_nascimento == null ? resposta.data[0].dep02_nascimento : resposta.data[0].dep02_nascimento.split("T")[0])
            set_idadeFilho2(resposta.data[0].dep02_idade)
            set_nacionalidadeFilho2(resposta.data[0].dep02_nacionalidade)
            set_estadoCivilFilho2(resposta.data[0].dep02_estadocivil)
            set_grauparentestoFilho2(resposta.data[0].dep02_grauparentesco)

            set_nomeFilho3(resposta.data[0].dep03_nome)
            set_dataNascFilho3(resposta.data[0].dep03_nascimento == null ? resposta.data[0].dep03_nascimento : resposta.data[0].dep03_nascimento.split("T")[0])
            set_idadeFilho3(resposta.data[0].dep03_idade)
            set_nacionalidadeFilho3(resposta.data[0].dep03_nacionalidade)
            set_estadoCivilFilho3(resposta.data[0].dep03_estadocivil)
            set_grauparentestoFilho3(resposta.data[0].dep03_grauparentesco)


            set_nomeFilho4(resposta.data[0].dep04_nome)
            set_dataNascFilho4(resposta.data[0].dep04_nascimento == null ? resposta.data[0].dep04_nascimento : resposta.data[0].dep04_nascimento.split("T")[0])
            set_idadeFilho4(resposta.data[0].dep04_idade)
            set_nacionalidadeFilho4(resposta.data[0].dep04_nacionalidade)
            set_estadoCivilFilho4(resposta.data[0].dep04_estadocivil)
            set_grauparentestoFilho4(resposta.data[0].dep04_grauparentesco)

            set_nomeFilho5(resposta.data[0].dep05_nome)
            set_dataNascFilho5(resposta.data[0].dep05_nascimento == null ? resposta.data[0].dep05_nascimento : resposta.data[0].dep05_nascimento.split("T")[0])
            set_idadeFilho5(resposta.data[0].dep05_idade)
            set_nacionalidadeFilho5(resposta.data[0].dep05_nacionalidade)
            set_estadoCivilFilho5(resposta.data[0].dep05_estadocivil)
            set_grauparentestoFilho5(resposta.data[0].dep05_grauparentesco)
            set_observacaoSuc(resposta.data[0].observacao)
            set_mesanobase(resposta.data[0].mesanobase)

            setEstadoCivil(resposta.data[0].estado_civil)
            setEstadoCivilComp(resposta.data[0].estadocivilcomp)
        } catch (error: any) {
            console.log(error)
            toast.error(error.response.data || error.statusText)
        }
    }

    async function AtualizarSucessao(e: any) {
        e.preventDefault()

        try {
            const dados = {
                FilhosComunsCliente,
                FilhosParticularesCliente,
                FilhosComunsCompanheiro,
                FilhosParticularesCompanheiro,
                MaeCliente,
                MaeCompanheiro,
                PaisCliente,
                PaisCompanheiro,

                nomeFilho1,
                dataNascFilho1,
                idadeFilho1,
                nacionalidadeFilho1,
                estadoCivilFilho1,
                grauparentestoFilho1,

                nomeFilho2,
                dataNascFilho2,
                idadeFilho2,
                nacionalidadeFilho2,
                estadoCivilFilho2,
                grauparentestoFilho2,

                nomeFilho3,
                dataNascFilho3,
                idadeFilho3,
                nacionalidadeFilho3,
                estadoCivilFilho3,
                grauparentestoFilho3,

                nomeFilho4,
                dataNascFilho4,
                idadeFilho4,
                nacionalidadeFilho4,
                estadoCivilFilho4,
                grauparentestoFilho4,

                nomeFilho5,
                dataNascFilho5,
                idadeFilho5,
                nacionalidadeFilho5,
                estadoCivilFilho5,
                grauparentestoFilho5,
                observacaoSuc,

                estadoCivil,
                estadoCivilComp
            }

            const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/sucessao/${params.id_sucessao}/${params.id_cliente}`, dados, {
                headers: {
                    Authorization: token
                }
            })
            toast.success(resposta.data)
            setTimeout(() => {
                navigate(-1)
            }, 2000);
        } catch (error: any) {

            toast.error(error.response.data || error.statusText)
        }
    }

    useEffect(function () {

        ListarCODSistema("NACIONALIDADE")
        ListarCODSistema("ESTADOCIVIL")
        ListarCODSistema("PARENTESCO")

        if (params.acao == "novo") {

            set_modalMesAnoBase(true)
            gerarMeses()

            axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cadastro/cliente/${params.id_cliente}`, {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {
                setEstadoCivil(resposta.data.cliente[0].estado_civil)
            })
        }
        else {

            carregarSucessao(params.id_sucessao)
        }
    }, [])

    function calcularIdade(dataNascimento: string) {
        // Divida a data de nascimento em ano, mês e dia
        const [ano, mes, dia] = dataNascimento.split('-').map(Number);

        // Obtenha a data atual
        const hoje = new Date();
        const anoAtual = hoje.getFullYear();
        const mesAtual = hoje.getMonth() + 1; // Os meses em JavaScript são baseados em zero (0-11), então adicione 1
        const diaAtual = hoje.getDate();

        // Calcule a idade
        let idade = anoAtual - ano;

        // Verifique se o aniversário já passou este ano
        if (mesAtual < mes || (mesAtual === mes && diaAtual < dia)) {
            idade--;
        }

        return idade.toString();
    }

    return (
        <>
            <ContainerTitulo titulo={params.acao == "novo" ? "Nova Sucessão " + mesanobase : params.acao == "editar" ? "Editando Sucessão " + mesanobase : "Visualizando Sucessão " + mesanobase} />
            <Card>
                <form onSubmit={params.acao == "novo" ? criarSucessao : AtualizarSucessao}>
                    <Card.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm col-md col-lg-3">

                                    <p className="mt-4">Estado civil</p>
                                </div>
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>CLIENTE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={estadoCivil}
                                            onChange={(e: SelectChangeEvent) =>
                                                setEstadoCivil(e.target.value)
                                            }
                                            label="CLIENTE"
                                        >
                                            {ListaEstadoCivil.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>COMPANHEIRO(a)</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={estadoCivilComp}
                                            onChange={(e: SelectChangeEvent) =>
                                                setEstadoCivilComp(e.target.value)
                                            }
                                            label="COMPANHEIRO(a)"
                                        >
                                            {ListaEstadoCivil.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3">
                                    <p className="mt-4">Numero de filhos comuns</p>
                                </div>
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>CLIENTE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={FilhosComunsCliente}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_FilhosComunsCliente(e.target.value)
                                            }
                                            label="CLIENTE"
                                        >
                                            <MenuItem value="0">0</MenuItem>
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">5</MenuItem>
                                            <MenuItem value="4">4</MenuItem>
                                            <MenuItem value="5">5</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {/*
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>COMPANHEIRO(a)</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={FilhosComunsCompanheiro}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_FilhosComunsCompanheiro(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="0">0</MenuItem>
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">5</MenuItem>
                                            <MenuItem value="4">4</MenuItem>
                                            <MenuItem value="5">5</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                */}
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3">
                                    <p className="mt-4">Numero de filhos particulares</p>
                                </div>
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>CLIENTE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={FilhosParticularesCliente}
                                            onChange={(e: SelectChangeEvent) =>

                                                set_FilhosParticularesCliente(e.target.value)
                                            }
                                            label="CLIENTE"
                                        >
                                            <MenuItem value="0">0</MenuItem>
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">5</MenuItem>
                                            <MenuItem value="4">4</MenuItem>
                                            <MenuItem value="5">5</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >

                                        <InputLabel>COMPANHEIRO(a)</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={FilhosParticularesCompanheiro}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_FilhosParticularesCompanheiro(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="0">0</MenuItem>
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">5</MenuItem>
                                            <MenuItem value="4">4</MenuItem>
                                            <MenuItem value="5">5</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="container">
                            <div className="row">
                                <div className="col-sm col-md col-lg-3">
                                    <p><b>Tem pais vivos ?</b></p>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3">
                                    <p className="mt-4">Mãe</p>
                                </div>
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>CLIENTE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={MaeCliente}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_MaeCliente(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="0">0</MenuItem>
                                            <MenuItem value="1">Sim</MenuItem>
                                            <MenuItem value="0">Não</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>COMPANHEIRO(a)</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={MaeCompanheiro}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_MaeCompanheiro(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="0">0</MenuItem>
                                            <MenuItem value="1">Sim</MenuItem>
                                            <MenuItem value="0">Não</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3">
                                    <p className="mt-4">Pai</p>
                                </div>
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>CLIENTE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PaisCliente}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PaisCliente(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="0">0</MenuItem>
                                            <MenuItem value="1">Sim</MenuItem>
                                            <MenuItem value="0">Não</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>COMPANHEIRO(a)</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={PaisCompanheiro}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_PaisCompanheiro(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="0">0</MenuItem>
                                            <MenuItem value="1">Sim</MenuItem>
                                            <MenuItem value="0">Não</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="container">
                            <div className="row border-bottom">
                                <div className="col-sm col-md col-lg-3">
                                    <p><b>Filhos/Outros dependentes</b></p>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md-6 col-lg-4">
                                    <InputSemBorda
                                        readonly={params.acao == "visualizar" ? true : false}
                                        name="fantasia"
                                        type="text"
                                        value={nomeFilho1}
                                        onChange={set_nomeFilho1}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nome"
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="date"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        value={dataNascFilho1}
                                        onBlur={function () {

                                            if (dataNascFilho1 != "") {

                                                set_idadeFilho1(calcularIdade(dataNascFilho1))
                                            }
                                            else {

                                                set_idadeFilho1("")
                                            }
                                        }}
                                        onChange={set_dataNascFilho1}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nascimento"
                                    />
                                </div>
                                <div className="col-sm col-md-2 col-lg-1">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="number"
                                        readonly
                                        value={idadeFilho1}
                                        onChange={set_idadeFilho1}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Idade"
                                    />
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>NACIONALIDADE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={nacionalidadeFilho1}
                                            onChange={function (e: any) {

                                                set_nacionalidadeFilho1(e.target.value)
                                            }}
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaNacionalidade.map(function (item: any) {
                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>ESTADO CIVIL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={estadoCivilFilho1}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_estadoCivilFilho1(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaEstadoCivil.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>GRAU DE PARENTESCO</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={grauparentestoFilho1}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_grauparentestoFilho1(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaParentesco.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row border-bottom">
                                <div className="col-sm col-md-6 col-lg-4">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="text"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        value={nomeFilho2}
                                        onChange={set_nomeFilho2}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nome"
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="date"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        onBlur={function () {

                                            if (dataNascFilho2 != "") {

                                                set_idadeFilho2(calcularIdade(dataNascFilho2))
                                            }
                                            else {

                                                set_idadeFilho2("")
                                            }
                                        }}
                                        value={dataNascFilho2}
                                        onChange={set_dataNascFilho2}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nascimento"
                                    />
                                </div>
                                <div className="col-sm col-md-2 col-lg-1">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="number"
                                        readonly
                                        value={idadeFilho2}
                                        onChange={set_idadeFilho2}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Idade"
                                    />
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>NACIONALIDADE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={nacionalidadeFilho2}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_nacionalidadeFilho2(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaNacionalidade.map(function (item: any) {
                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>ESTADO CIVIL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={estadoCivilFilho2}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_estadoCivilFilho2(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaEstadoCivil.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>GRAU DE PARENTESCO</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={grauparentestoFilho2}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_grauparentestoFilho2(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaParentesco.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row border-bottom">
                                <div className="col-sm col-md-6 col-lg-4">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="text"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        value={nomeFilho3}
                                        onChange={set_nomeFilho3}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nome"
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="date"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        value={dataNascFilho3}
                                        onBlur={function () {

                                            if (dataNascFilho3 != "") {

                                                set_idadeFilho3(calcularIdade(dataNascFilho3))
                                            }
                                            else {

                                                set_idadeFilho3("")
                                            }
                                        }}
                                        onChange={set_dataNascFilho3}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nascimento"
                                    />
                                </div>
                                <div className="col-sm col-md-2 col-lg-1">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="number"
                                        readonly
                                        value={idadeFilho3}
                                        onChange={set_idadeFilho3}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Idade"
                                    />
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>NACIONALIDADE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={nacionalidadeFilho3}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_nacionalidadeFilho3(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaNacionalidade.map(function (item: any) {
                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>ESTADO CIVIL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={estadoCivilFilho3}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_estadoCivilFilho3(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaEstadoCivil.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>GRAU DE PARENTESCO</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={grauparentestoFilho3}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_grauparentestoFilho3(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaParentesco.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row border-bottom">
                                <div className="col-sm col-md-6 col-lg-4">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="text"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        value={nomeFilho4}
                                        onChange={set_nomeFilho4}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nome"
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="date"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        value={dataNascFilho4}
                                        onBlur={function () {

                                            if (dataNascFilho4 != "") {

                                                set_idadeFilho4(calcularIdade(dataNascFilho4))
                                            }
                                            else {

                                                set_idadeFilho4("")
                                            }
                                        }}
                                        onChange={set_dataNascFilho4}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nascimento"
                                    />
                                </div>
                                <div className="col-sm col-md-2 col-lg-1">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="number"
                                        readonly
                                        value={idadeFilho4}
                                        onChange={set_idadeFilho4}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Idade"
                                    />
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>NACIONALIDADE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={nacionalidadeFilho4}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_nacionalidadeFilho4(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaNacionalidade.map(function (item: any) {
                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>ESTADO CIVIL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={estadoCivilFilho4}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_estadoCivilFilho4(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaEstadoCivil.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>GRAU DE PARENTESCO</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={grauparentestoFilho4}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_grauparentestoFilho4(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaParentesco.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row border-bottom">
                                <div className="col-sm col-md-6 col-lg-4">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="text"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        value={nomeFilho5}
                                        onChange={set_nomeFilho5}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nome"
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="date"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        value={dataNascFilho5}
                                        onBlur={function () {

                                            if (dataNascFilho5 != "") {

                                                set_idadeFilho5(calcularIdade(dataNascFilho5))
                                            }
                                            else {

                                                set_idadeFilho5("")
                                            }
                                        }}
                                        onChange={set_dataNascFilho5}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Nascimento"
                                    />
                                </div>
                                <div className="col-sm col-md-2 col-lg-1">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="number"
                                        readonly
                                        value={idadeFilho5}
                                        onChange={set_idadeFilho5}
                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                        placeholder=" "
                                        label="Idade"
                                    />
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>NACIONALIDADE</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={nacionalidadeFilho5}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_nacionalidadeFilho5(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaNacionalidade.map(function (item: any) {
                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-2">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>ESTADO CIVIL</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={estadoCivilFilho5}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_estadoCivilFilho5(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaEstadoCivil.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm col-md-4 col-lg-3">
                                    <FormControl
                                        variant="standard"
                                        sx={{ m: 1.5, width: "100%" }}
                                    >
                                        <InputLabel>GRAU DE PARENTESCO</InputLabel>
                                        <Select
                                            readOnly={params.acao == "visualizar" ? true : false}
                                            value={grauparentestoFilho5}
                                            onChange={(e: SelectChangeEvent) =>
                                                set_grauparentestoFilho5(e.target.value)
                                            }
                                            label="COMPANHEIRO"
                                        >
                                            <MenuItem value="">
                                                <em>Selecione...</em>
                                            </MenuItem>
                                            {ListaParentesco.map(function (item: any) {

                                                return (

                                                    <MenuItem value={item.value}>{item.label}</MenuItem>

                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="w-100"></div>
                                <div className="ms-3 col-md col-lg col-sm">
                                    <h4>Oberservações</h4>
                                    <textarea name="" id="" cols={30} rows={15}
                                        disabled={params.acao == "visualizar" ? true : false}
                                        value={observacaoSuc} onChange={function (e: any) {
                                            set_observacaoSuc(e.target.value)
                                        }}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={false}
                                disabled={params.acao == 'visualizar' ? true : false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                            />
                        </div>
                    </Card.Footer>
                </form>
            </Card>
            <ModalLoading show={carregando} />


            <Modal show={modalMesAnoBase}>

                <Modal.Header className="bg-white">
                    <Modal.Title>Mês/Ano Base da Sucessão a ser criada.</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Defina o mês/ano do inicio do Fluxo a ser criado.</h4>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select name="" id="" onChange={function (e) {
                                    const data = e.target.value.split("-")
                                    set_mesanobase(data[1] + "/" + data[0])
                                }} className="select-control bordasInferiorInput w-100">
                                    <option value="" selected></option>
                                    {meses.map(function (data: any) {

                                        return <option className="text-center" value={`${data.ano}-${data.mes}`}>{`${data.mes}/${data.ano}`}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Cancelar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        set_modalMesAnoBase(false)
                                        navigate(-1);
                                    }}
                                />
                            </div>
                            <div className="col text-end">
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' ? true : false}
                                    type="button"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Continuar"
                                    onclick={function () {
                                        set_modalMesAnoBase(false)
                                    }}
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default Sucessao