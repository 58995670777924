import React from 'react';
const armazem = require("../../../assets/img/armazem.png") as string

interface AvatarFilialProps {
  avatarImgFilial: string | null;
  alt: string;
  className?: string;
}

const AvatarFilial: React.FC<AvatarFilialProps> = ({
  avatarImgFilial,
  alt,
  className,
}) => {
  return (
    <span className={`account-user-avatar ${className || ''}`}>
      <img
        src={avatarImgFilial !== null ? `data:image/png;base64,${avatarImgFilial}` : armazem}
        alt={alt}
        className="rounded-action-icon imgEmpresa"
      />
    </span>
  );
};

export default AvatarFilial;
