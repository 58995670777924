import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import ContainerTitulo from "../../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../../../../hooks/mascaras";
import { getSessionData } from "../../../../../utils/storageUtils";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function Riscos() {

    const params = useParams()
    const token = getSessionData("MultfilialWebToken")
    const filial = getSessionData("DadosIdfilial")
    const navigate = useNavigate()

    const [modalMesAnoBase, set_modalMesAnoBase] = useState(false)
    const [mesanobase, set_mesanobase] = useState("")
    const [meses, set_meses] = useState<any>([])
    function gerarMeses() {
        const dataToday = new Date();
        const anoAtual = dataToday.getFullYear(); // Obtém o ano atual
        const mesAtual = dataToday.getMonth() + 1; // Obtém o mês atual (de 0 a 11)
        const datas = [];

        // Gera 3 meses para trás
        let ano = anoAtual;
        let mes = mesAtual;
        for (let i = 0; i < 3; i++) {
            mes--;
            if (mes < 1) {
                mes = 12;
                ano--;
            }
            datas.unshift({ mes: mes.toString().padStart(2, '0'), ano: ano }); // Adiciona ao início do array
        }

        // Reseta para o mês atual e ano
        ano = anoAtual;
        mes = mesAtual;

        // Gera 12 meses para frente
        for (let i = 0; i < 12; i++) {
            datas.push({ mes: mes.toString().padStart(2, '0'), ano: ano });
            mes++;
            if (mes > 12) {
                mes = 1;
                ano++;
            }
        }

        // Atualiza o estado com o array de datas
        set_meses(datas);
    }

    const [carregando, set_carregando] = useState(false)

    //INPUTS ANALISE DE RISCOS
    const [Evento1, set_Evento1] = useState("")
    const [Seguradora1, set_Seguradora1] = useState("")
    const [Custo1, set_Custo1] = useState("")

    const [Evento2, set_Evento2] = useState("")
    const [Seguradora2, set_Seguradora2] = useState("")
    const [Custo2, set_Custo2] = useState("")

    const [Evento3, set_Evento3] = useState("")
    const [Seguradora3, set_Seguradora3] = useState("")
    const [Custo3, set_Custo3] = useState("")

    const [Evento4, set_Evento4] = useState("")
    const [Seguradora4, set_Seguradora4] = useState("")
    const [Custo4, set_Custo4] = useState("")

    const [Evento5, set_Evento5] = useState("")
    const [Seguradora5, set_Seguradora5] = useState("")
    const [Custo5, set_Custo5] = useState("")

    const [Evento6, set_Evento6] = useState("")
    const [Seguradora6, set_Seguradora6] = useState("")
    const [Custo6, set_Custo6] = useState("")

    const [Evento7, set_Evento7] = useState("")
    const [Seguradora7, set_Seguradora7] = useState("")
    const [Custo7, set_Custo7] = useState("")

    //---comp

    const [Evento_comp1, set_Evento_comp1] = useState("")
    const [Seguradora_comp1, set_Seguradora_comp1] = useState("")
    const [Custo_comp1, set_Custo_comp1] = useState("")

    const [Evento_comp2, set_Evento_comp2] = useState("")
    const [Seguradora_comp2, set_Seguradora_comp2] = useState("")
    const [Custo_comp2, set_Custo_comp2] = useState("")

    const [Evento_comp3, set_Evento_comp3] = useState("")
    const [Seguradora_comp3, set_Seguradora_comp3] = useState("")
    const [Custo_comp3, set_Custo_comp3] = useState("")

    const [Evento_comp4, set_Evento_comp4] = useState("")
    const [Seguradora_comp4, set_Seguradora_comp4] = useState("")
    const [Custo_comp4, set_Custo_comp4] = useState("")

    const [Evento_comp5, set_Evento_comp5] = useState("")
    const [Seguradora_comp5, set_Seguradora_comp5] = useState("")
    const [Custo_comp5, set_Custo_comp5] = useState("")

    const [Evento_comp6, set_Evento_comp6] = useState("")
    const [Seguradora_comp6, set_Seguradora_comp6] = useState("")
    const [Custo_comp6, set_Custo_comp6] = useState("")

    const [Evento_comp7, set_Evento_comp7] = useState("")
    const [Seguradora_comp7, set_Seguradora_comp7] = useState("")
    const [Custo_comp7, set_Custo_comp7] = useState("")

    const [ObservacaoAR, set_ObservacaoAR] = useState("")


    const [ListaEvento, set_ListaEvento] = useState([])
    const [ListaSeguradora, set_ListaSeguradora] = useState([])

    function ListarCODSistema(tabela: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`)
            .then(function (resposta) {


                if (tabela == "RISCOS") {

                    set_ListaEvento(resposta.data)
                }
                else if (tabela == "SEGUROS") {

                    set_ListaSeguradora(resposta.data)
                }

                set_carregando(false)
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }


    useEffect(function () {

        ListarCODSistema("RISCOS")
        ListarCODSistema("SEGUROS")

        if (params.acao == "novo") {

            set_modalMesAnoBase(true)
            gerarMeses()
        }
        else {

            CarregarAnaliseDeRisco(params.id_analisederisco, params.id_cliente)
        }
    }, [])


    async function CriarAnaliseDeRisco(e: any) {

        try {
            e.preventDefault()
            const dados = {

                usuarioCadastro: getSessionData("NomeUsuario"),
                mesanobase,
                Evento1,
                Seguradora1,
                Custo1,

                Evento2,
                Seguradora2,
                Custo2,

                Evento3,
                Seguradora3,
                Custo3,

                Evento4,
                Seguradora4,
                Custo4,

                Evento5,
                Seguradora5,
                Custo5,

                Evento6,
                Seguradora6,
                Custo6,

                Evento7,
                Seguradora7,
                Custo7,

                Evento_comp1,
                Seguradora_comp1,
                Custo_comp1,

                Evento_comp2,
                Seguradora_comp2,
                Custo_comp2,

                Evento_comp3,
                Seguradora_comp3,
                Custo_comp3,

                Evento_comp4,
                Seguradora_comp4,
                Custo_comp4,

                Evento_comp5,
                Seguradora_comp5,
                Custo_comp5,

                Evento_comp6,
                Seguradora_comp6,
                Custo_comp6,

                Evento_comp7,
                Seguradora_comp7,
                Custo_comp7,

                ObservacaoAR
            }


            const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/analisederisco/${filial}/${params.id_cliente}`, dados, {
                headers: {
                    Authorization: token
                }
            })

            toast.success(resposta.data)
            setTimeout(() => {
                navigate(-1)
            }, 2000)
        } catch (erro: any) {

            toast.error(erro.response.data || erro.response.statusText)
        }
    }


    async function CarregarAnaliseDeRisco(id_analisederisco: any, id_cliente: any) {

        try {


            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/analisederisco/${id_analisederisco}/${id_cliente}`, {
                headers: {
                    Authorization: token
                }
            })
            console.log(resposta.data[0].custocliente_01)
            set_Evento1(resposta.data[0].codevento_cliente_01)
            set_Seguradora1(resposta.data[0].codseguradora_cliente_01)
            set_Custo1(resposta.data[0].custocliente_01)
            set_Evento2(resposta.data[0].codevento_cliente_02)
            set_Seguradora2(resposta.data[0].codseguradora_cliente_02)
            set_Custo2(resposta.data[0].custocliente_02)
            set_Evento3(resposta.data[0].codevento_cliente_03)
            set_Seguradora3(resposta.data[0].codseguradora_cliente_03)
            set_Custo3(resposta.data[0].custocliente_03)
            set_Evento4(resposta.data[0].codevento_cliente_04)
            set_Seguradora4(resposta.data[0].codseguradora_cliente_04)
            set_Custo4(resposta.data[0].custocliente_04)
            set_Evento5(resposta.data[0].codevento_cliente_05)
            set_Seguradora5(resposta.data[0].codseguradora_cliente_05)
            set_Custo5(resposta.data[0].custocliente_05)
            set_Evento6(resposta.data[0].codevento_cliente_06)
            set_Seguradora6(resposta.data[0].codseguradora_cliente_06)
            set_Custo6(resposta.data[0].custocliente_06)
            set_Evento7(resposta.data[0].codevento_cliente_07)
            set_Seguradora7(resposta.data[0].codseguradora_cliente_07)
            set_Custo7(resposta.data[0].custocliente_07)

            set_Evento_comp1(resposta.data[0].codevento_comp_01)
            set_Seguradora_comp1(resposta.data[0].codseguradora_comp_01)
            set_Custo_comp1(resposta.data[0].custocomp_01)
            set_Evento_comp2(resposta.data[0].codevento_comp_02)
            set_Seguradora_comp2(resposta.data[0].codseguradora_comp_02)
            set_Custo_comp2(resposta.data[0].custocomp_02)
            set_Evento_comp3(resposta.data[0].codevento_comp_03)
            set_Seguradora_comp3(resposta.data[0].codseguradora_comp_03)
            set_Custo_comp3(resposta.data[0].custocomp_03)
            set_Evento_comp4(resposta.data[0].codevento_comp_04)
            set_Seguradora_comp4(resposta.data[0].codseguradora_comp_04)
            set_Custo_comp4(resposta.data[0].custocomp_04)
            set_Evento_comp5(resposta.data[0].codevento_comp_05)
            set_Seguradora_comp5(resposta.data[0].codseguradora_comp_05)
            set_Custo_comp5(resposta.data[0].custocomp_05)
            set_Evento_comp6(resposta.data[0].codevento_comp_06)
            set_Seguradora_comp6(resposta.data[0].codseguradora_comp_06)
            set_Custo_comp6(resposta.data[0].custocomp_06)
            set_Evento_comp7(resposta.data[0].codevento_comp_07)
            set_Seguradora_comp7(resposta.data[0].codseguradora_comp_07)
            set_Custo_comp7(resposta.data[0].custocomp_07)

            set_ObservacaoAR(resposta.data[0].observacao)
            set_mesanobase(resposta.data[0].mesanobase)
        } catch (error: any) {

            toast.error(error.response.data || error.responde.statusText)
        }
    }

    async function AtualizarAnaliseDeRisco(e: any) {
        e.preventDefault()

        const dados = {

            Evento1,
            Seguradora1,
            Custo1,

            Evento2,
            Seguradora2,
            Custo2,

            Evento3,
            Seguradora3,
            Custo3,

            Evento4,
            Seguradora4,
            Custo4,

            Evento5,
            Seguradora5,
            Custo5,

            Evento6,
            Seguradora6,
            Custo6,

            Evento7,
            Seguradora7,
            Custo7,

            Evento_comp1,
            Seguradora_comp1,
            Custo_comp1,

            Evento_comp2,
            Seguradora_comp2,
            Custo_comp2,

            Evento_comp3,
            Seguradora_comp3,
            Custo_comp3,

            Evento_comp4,
            Seguradora_comp4,
            Custo_comp4,

            Evento_comp5,
            Seguradora_comp5,
            Custo_comp5,

            Evento_comp6,
            Seguradora_comp6,
            Custo_comp6,

            Evento_comp7,
            Seguradora_comp7,
            Custo_comp7,

            ObservacaoAR
        }

        try {

            const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/analisederisco/${params.id_analisederisco}/${params.id_cliente}`, dados, {
                headers: {
                    Authorization: token
                }
            })

            toast.success(resposta.data)
            setTimeout(() => {
                navigate(-1)
            }, 2000)
        } catch (error: any) {

            toast.error(error.response.data || error.response.statusText)
        }
    }

    return (
        <>
            <ContainerTitulo titulo={params.acao == "novo" ? "Nova Analise de Riscos " + mesanobase : params.acao == "editar" ? "Editando Analise de Riscos " + mesanobase : "Visualizando Analise de Riscos " + mesanobase} />

            <Card>
                <form onSubmit={params.acao == "novo" ? CriarAnaliseDeRisco : AtualizarAnaliseDeRisco}>
                    <Card.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h4 className="text-center">AVALIAÇÃO DE RISCOS</h4>
                                    <p className="text-center">Possui cobertura para os seguintes riscos ? Qual seguradora ?</p>
                                </div>
                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">#</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <h4 className="text-center">RISCO - CLIENTE</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <h4 className="text-center">SEGURADORA</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <h4 className="text-center">COMENTÁRIO</h4>
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">1</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento1}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento1(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora1}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora1(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo1}
                                            onChange={set_Custo1}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">2</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento2}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento2(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora2}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora2(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo2}
                                            onChange={set_Custo2}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">3</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento3}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento3(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora3}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora3(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo3}
                                            onChange={set_Custo3}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">4</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento4}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento4(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora4}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora4(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo4}
                                            onChange={set_Custo4}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">5</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento5}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento5(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora5}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora5(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo5}
                                            onChange={set_Custo5}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">6</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento6}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento6(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora6}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora6(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo6}
                                            onChange={set_Custo6}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">7</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento7}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento7(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora7}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora7(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo7}
                                            onChange={set_Custo7}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="container">

                            <div className="row">
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">#</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <h4 className="text-center">RISCO - COMP.</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <h4 className="text-center">SEGURADORA</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <h4 className="text-center">COMENTÁRIO</h4>
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">1</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento_comp1}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento_comp1(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora_comp1}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora_comp1(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo_comp1}
                                            onChange={set_Custo_comp1}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">2</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento_comp2}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento_comp2(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora_comp2}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora_comp2(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo_comp2}
                                            onChange={set_Custo_comp2}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">3</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento_comp3}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento_comp3(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora_comp3}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora_comp3(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo_comp3}
                                            onChange={set_Custo_comp3}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">4</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento_comp4}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento_comp4(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora_comp4}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora_comp4(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo_comp4}
                                            onChange={set_Custo_comp4}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">5</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento_comp5}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento_comp5(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora_comp5}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora_comp5(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo_comp5}
                                            onChange={set_Custo_comp5}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">6</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento_comp6}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento_comp6(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora6}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora_comp6(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo_comp6}
                                            onChange={set_Custo_comp6}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>

                                <div className="w-100"></div>
                                <div className="col d-flex overflow-auto">
                                    <div className="col-md-1 col-lg-1 me-1">
                                        <h4 className="text-center">7</h4>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>RISCO</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Evento_comp7}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Evento_comp7(e.target.value)
                                                }
                                                label="EVENTO"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaEvento.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 col-lg-3 me-1">
                                        <FormControl
                                            variant="standard"
                                            sx={{ m: 1.5, width: "100%" }}
                                        >
                                            <InputLabel>SEGURADORA</InputLabel>
                                            <Select
                                                readOnly={params.acao == "visualizar" ? true : false}
                                                value={Seguradora_comp7}
                                                onChange={(e: SelectChangeEvent) =>
                                                    set_Seguradora_comp7(e.target.value)
                                                }
                                                label="SEGURADORA"
                                            >
                                                <MenuItem value="">
                                                    <em>Selecione...</em>
                                                </MenuItem>
                                                {ListaSeguradora.map((item: any) => (
                                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="ms-2 col-md-4 col-lg-3">
                                        <InputSemBorda
                                            name="comentario"
                                            readonly={params.acao == "visualizar" ? true : false}
                                            type="text"
                                            value={Custo_comp7}
                                            onChange={set_Custo_comp7}
                                            classNameInputsSemBorda="w-100 form-control bordasInferiorInput text-dark"
                                            placeholder=" "
                                            label="Comentário"
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="ms-3 col-md col-lg col-sm">
                                <h4>Oberservações</h4>
                                <textarea name="" id="" cols={30} rows={15}
                                    disabled={params.acao == "visualizar" ? true : false}
                                    value={ObservacaoAR} onChange={function (e: any) {
                                        set_ObservacaoAR(e.target.value)
                                    }}>
                                </textarea>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={false}
                                disabled={params.acao == 'visualizar' ? true : false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                            />
                        </div>
                    </Card.Footer>
                </form>

            </Card>

            <Modal show={modalMesAnoBase}>

                <Modal.Header className="bg-white">
                    <Modal.Title>Mês/Ano Base da Analise de Riscos a ser criada.</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Defina o mês/ano do inicio do Fluxo a ser criado.</h4>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select name="" id="" onChange={function (e) {
                                    const data = e.target.value.split("-")
                                    set_mesanobase(data[1] + "/" + data[0])
                                }} className="select-control bordasInferiorInput w-100">
                                    <option value="" selected></option>
                                    {meses.map(function (data: any) {

                                        return <option className="text-center" value={`${data.ano}-${data.mes}`}>{`${data.mes}/${data.ano}`}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Cancelar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        set_modalMesAnoBase(false)
                                        navigate(-1);
                                    }}
                                />
                            </div>
                            <div className="col text-end">
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' ? true : false}
                                    type="button"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Continuar"
                                    onclick={function () {
                                        set_modalMesAnoBase(false)
                                    }}
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            <ModalLoading show={carregando} />
        </>
    )
}

export default Riscos