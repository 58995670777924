import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { useState } from "react";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF } from "../../../../hooks/mascaras";
import { getSessionData } from "../../../../utils/storageUtils";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";

function FormularioCliente() {


    const navigate = useNavigate()

    const params = useParams()

    //modal loading
    const [carregando, set_carregando] = useState(false)

    function CriarCliente(e: any) {

        e.preventDefault()

        set_carregando(true)

        const dados = {

            id_filial: getSessionData("DadosIdfilial"),
            usuario_cadastro: getSessionData("NomeUsuario"),
            cpf: cpf,
            nome: nome,
            email: email,
            celular: celular,
            telefone: telefone
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/cliente`, dados, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {


                set_carregando(false)
                if (resposta.data.codigo == 200) {

                    toast.success(resposta.data.message)
                    setTimeout(function () {

                        navigate(-1)
                    }, 2000)
                }
                else {

                    toast.error(resposta.data.message)
                }
            }).catch(function (erro) {


                set_carregando(false)
                toast.error(erro)
            })
    }

    const [cpf, set_cpf] = useState("")
    const [nome, set_nome] = useState("")
    const [email, set_email] = useState("")
    const [celular, set_celular] = useState("")
    const [telefone, set_telefone] = useState("")



    return (
        <>
            <div className="col">
                <ContainerTitulo titulo={params.id_cliente == 'novo' ? "Incluir Pré Cadastro de Cliente" : "Editar Pré Cadastro de Cliente"} />
                <Card>
                    <Form onSubmit={CriarCliente}>
                        <Card.Body>
                            <Box sx={{ width: "100%" }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-2">
                                            <InputSemBorda
                                                name="fantasia"
                                                type="text"

                                                value={params.id_cliente || ""}
                                                onChange={function () {

                                                }}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="ID Cliente"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <InputSemBorda
                                                name="text"
                                                type="text"
                                                required
                                                value={mascaraCPF(cpf)}
                                                onChange={set_cpf}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="CPF"
                                            />
                                        </div>
                                        <div className="col">
                                            <InputSemBorda
                                                name="nome"
                                                type="text"
                                                value={nome}
                                                required
                                                onChange={set_nome}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Nome"
                                            />
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col-5">
                                            <InputSemBorda
                                                name="e-mail"
                                                type="text"
                                                value={email}
                                                required
                                                onChange={set_email}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="E-mail"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <InputSemBorda
                                                name="celular"
                                                type="text"
                                                required
                                                value={mascaraTelefoneCelular(celular)}
                                                onChange={set_celular}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Celular"
                                            />
                                        </div>
                                        <div className="col">
                                            <InputSemBorda
                                                name="telefone"
                                                type="text"
                                                value={mascaraTelefoneFixo(telefone)}
                                                onChange={set_telefone}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Telefone"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </Box>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-flex my-2 gap-1 justify-content-between">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Voltar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        navigate(-1);
                                    }}
                                />
                                <ButtonCustom
                                    invisivel={false}
                                    type="submit"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Salvar"
                                    icone={mdiCheckCircle}
                                    disabled={params.acao === "visualizar" ? true : false}
                                />
                            </div>
                        </Card.Footer>
                    </Form>
                </Card>
                <ModalLoading show={carregando} />
            </div>
        </>
    )
}

export default FormularioCliente