import ApiGet from "../../../../../api/endPoints/useGet";
import { getSessionData } from "../../../../../utils/storageUtils";

const CarregarDados = (url: string, id_empresa: string): Promise<any[]> => {
  const token = getSessionData("MultfilialWebToken") || "";
  return new Promise((resolve, reject) => {
    try {
      const headers = {
        Authorization: token,
      };
      const dados = {
        url: url,
        parametros: {
          id_empresa: id_empresa,
        },
        headers: headers,
      };
      ApiGet(dados)
        .then((retorno) => {
          resolve(retorno);
        })
        .catch((erro) => {
          reject(erro);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export default CarregarDados;
