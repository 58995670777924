import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import FormCodSistema from "./formulario";
// import FormEmpresa from "./components/formulario";

export function CodigiodoSistemaIncluir() {
  const idcodigo = useParams();
  return (
    <>
      <div className="col">
        <ContainerTitulo titulo="Incluir Código do Sistema" />
        <FormCodSistema idcodigo={idcodigo.id} acao="Novo" />
      </div>
    </>
  );
}
