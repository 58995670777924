import { Col, Row, Container, Card, Image, Stack } from "react-bootstrap";
import Logo from "../../assets/img/principal.png";
import { Button } from "../../components/Formularios/Buttons/Button";
import Input from "../../components/Formularios/Inputs/Input";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApiPost from "../../api/endPoints/usePost";
import { toast } from 'react-toastify'
import { Modal } from "react-bootstrap";
import ValidaComplexidadeSenha from "../../components/Formularios/ValidaComplexidadeSenha";
import { Button as ButtonCustom } from "../../components/Formularios/Buttons/Button"
import ApiPut from "../../api/endPoints/usePut";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
} from "@mdi/js";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const emailRef = useRef<HTMLInputElement>(null);
  const senhaRef = useRef<HTMLInputElement>(null);
  //#region ===> VALIDAR LOGIN
  const login = async (email: string, password: string) => {
    const dados = {
      email,
      password,
    };
    ApiPost("/usuario/logar", dados)
      .then((response) => {
        console.log(response)
        const { usuario, nome, email, perfil, id_usuario, token, master, vinculo, id_perfil, nivel } = response;
        console.log(response)
        sessionStorage.setItem("DadosUsuario", usuario);
        sessionStorage.setItem("NomeUsuario", nome);
        sessionStorage.setItem("EmailUsuario", email);
        sessionStorage.setItem("PerfilUsuario", perfil);
        sessionStorage.setItem("DadosUsuarioID", id_usuario);
        sessionStorage.setItem("MultfilialWebToken", token);
        sessionStorage.setItem("isPerfilSuper", master);
        sessionStorage.setItem("id_perfil", id_perfil)
        sessionStorage.setItem("NivelPerfil", nivel)

        if (vinculo >= 0)
          sessionStorage.setItem("isPerfilVinculo", vinculo);
        navigate("/");
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao realizar login. Motivo: ${msg}`)
      });
  };

  //#endregion
  const enviar = (event: any) => {
    event.preventDefault();
    login(email, senha);
  };

  //controle modal recSenha
  const [showModalRecSenha, setshowModalRecSenha] = useState(false)

  function abrirModalRecSenha() {
    setbtnEnviarEmail(false)
    setshowModalRecSenha(true)
  }


  ///PARTE DE REDEFINIÇÃO DE SENHA///



  const [Email, setEmail_REC] = useState("")
  const [codigo, setCodigo] = useState("")

  const [btnEnviarEmail, setbtnEnviarEmail] = useState(false)

  function EnviaremailRecSenha(e: any) {
    e.preventDefault()
    setbtnEnviarEmail(true)
    const dados = {
      emailUser: Email
    }

    ApiPost("/rec/senha/user", dados).then(function (resposta) {
      if (resposta.status === 200) {
        setshowModalRecSenha(false)
        //guardo no localstorage o token para verficacao
        localStorage.setItem("token_recSenha", resposta.token)
        setshowModalCodigo(true)
      }
      else if (resposta.status === 400) {
        setbtnEnviarEmail(false)
        toast.error(resposta.message)
      }
      else {
        toast.error("Erro desconhecido")
      }
    })
  }

  //controle do Modal de verificar código
  const [showModalCodigo, setshowModalCodigo] = useState(false)

  function setCodigoVal(e: any) {


    if (e.length <= 4) {
      setCodigo(e)
    }
    else {
      toast.error("O código não tem mais que 4 digitos.")
    }

  }

  function verificarCodParaSetarSenha(e: any) {
    e.preventDefault()
    const dados = {
      codigo: codigo,
      token: localStorage.getItem("token_recSenha")
    }

    ApiPost("/rec/verificar/tokenSenha", dados).then(function (resposta) {

      if (resposta.status === 200) {
        setshowModalCodigo(false)
        setModalDefinirSenha(true)
      }
      else {
        toast.error("Código invalido")
      }
    }).catch(function (erro) {
      toast.error(erro)
    })
  }

  //modal de definir a senha
  const [senhaRec, setsenhaRec] = useState("")
  const [confirmar_senha, setconfirmar_senha] = useState("")

  const [modalDefinirSenha, setModalDefinirSenha] = useState(false)

  function setNewSenha() {
    if (senhaRec === confirmar_senha && senhaRec != "" && senhaRec != null && confirmar_senha != "" && confirmar_senha != null) {
      const dados = {
        senha: senhaRec,
        email: Email
      }
      ApiPut("/redefinir/senha", dados).then(function (resposta) {
        toast.success(resposta.message)
        setModalDefinirSenha(false)
        setsenhaRec("")
        setconfirmar_senha("")
        setEmail_REC("")
        setCodigo("")
      }).catch(function (erro) {
        toast.error(erro)
      })
    }
    else {
      toast.error("As senhas não são iguais.")
    }

  }

  //fechar qualquer modal recsenha
  function fecharModalsRecSenha() {
    setshowModalRecSenha(false)
    setshowModalCodigo(false)
    setModalDefinirSenha(false)
    setsenhaRec("")
    setconfirmar_senha("")
    setEmail_REC("")
    setCodigo("")
  }

  return (
    <main className="mt-5">
      <Container>
        <Card>
          <div className="m-4">
            <Row>
              <Col sm={12} md={12} lg={6}>
                <Card
                  className="p-5 d-flex justify-content-center"
                  style={{ height: "70%" }}
                >
                  <Image
                    alt="logo"
                    src={Logo}
                  // style={{ padding: "0px", margin: "0px", top: "0px" }}
                  />
                </Card>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <Card className="p-5 ">
                  <h3 className="text-center">Controle de Acesso</h3>
                  <form onSubmit={enviar}>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <Input
                          type="email"
                          name="email"
                          value={email}
                          classNameInput="form-control"
                          classNameLabel="form-label"
                          onChange={setEmail}
                          label="Email"
                          required={true}
                          useRef={emailRef}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <Input
                          type="password"
                          name="senha"
                          value={senha}
                          classNameInput="form-control"
                          classNameLabel="form-label"
                          onChange={setSenha}
                          label="Senha"
                          required={true}
                          useRef={senhaRef}
                        />
                      </Col>
                    </Row>

                    <button type="button" className="btn btn-link"
                      style={{ display: "block", margin: "auto" }}
                      onClick={abrirModalRecSenha}>Esqueci minha senha - redefinir senha</button>

                    <Stack gap={3} className="col-md-5 mx-auto">
                      <Button
                        invisivel={false}
                        className="mt-4 btn-info rounded-pill"
                        type="submit"
                        descricaoBotao="Acessar"
                      />
                    </Stack>
                  </form>
                </Card>
              </Col>
            </Row>
          </div>
          <Card className="bg-primary p-2 mt-3">
            <section className="d-flex justify-content-end">
              <p className="text-light px-3 fs-6">
                Copyright &copy; {new Date().getFullYear()}. Todos os direitos
                reservados FKL Service.
              </p>
            </section>
          </Card>
        </Card>
      </Container>




      {/*MODAL REC SENHA */}
      <Modal show={showModalRecSenha} backdrop="static" size="lg" keyboard={false} centered >
        <Modal.Header style={{ backgroundColor: "white" }}>
          <Modal.Title>Pedido de recuperação de senha</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "white", textAlign: "center" }} >
          <p>Coloque o email cadastrado no sistema.</p>
          <p><i>Você receberá no email um código de validação.</i></p>
          <form onSubmit={EnviaremailRecSenha} style={{ textAlign: "center" }}>
            <Row>
              <Col sm={12} md={12} lg={5} style={{ margin: "auto" }}>
                <Input
                  type="email"
                  name="email"
                  value={Email}
                  classNameInput="form-control"
                  classNameLabel="form-label"
                  onChange={setEmail_REC}
                  label="Email"
                  required={true}
                />
              </Col>
            </Row>

            <div className="d-flex my-2 gap-1 justify-content-between" style={{
              width: "20%",
              margin: "auto", paddingLeft: "8px"
            }}>
              <ButtonCustom
                invisivel={btnEnviarEmail}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Enviar Email"
                icone={mdiCheckCircle}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "white", textAlign: "initial" }}>
          <ButtonCustom
            invisivel={false}
            type="button"
            className="btn btn-light text-danger bg-white border-0"
            descricaoBotao="Cancelar"
            icone={mdiKeyboardReturn}
            onclick={() => {
              fecharModalsRecSenha()
            }}
          />
        </Modal.Footer>
      </Modal>


      <Modal show={showModalCodigo} backdrop="static" size="lg" keyboard={false} centered >

        <Modal.Header style={{ backgroundColor: "white" }}>
          <Modal.Title>Código enviado</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "white" }}>
          <div>
            <div>
              <form onSubmit={verificarCodParaSetarSenha} style={{ textAlign: "center" }}>
                <p>Código enviado para: {Email}</p>
                <p><i>Você receberá no email um código de validação.</i></p>
                <Row>
                  <Col sm={12} md={12} lg={5} style={{ margin: "auto", WebkitAppearance: "none" }}>
                    <Input
                      type="number"
                      name="number"
                      value={codigo}
                      classNameInput="form-control"
                      classNameLabel="form-label"
                      onChange={function (e) {
                        setCodigoVal(e)
                      }}
                      label="Código de validação"
                      required={true}
                    />
                  </Col>
                </Row>

                <div className="d-flex my-2 gap-1 justify-content-between" style={{
                  width: "25%",
                  margin: "auto", paddingLeft: "8px"
                }}>
                  <ButtonCustom
                    invisivel={false}
                    type="submit"
                    className="btn btn-light text-info-emphasis bg-white border-0"
                    descricaoBotao="Validar Código"
                    icone={mdiCheckCircle}
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "white", textAlign: "initial" }}>
          <ButtonCustom
            invisivel={false}
            type="button"
            className="btn btn-light text-danger bg-white border-0"
            descricaoBotao="Cancelar"
            icone={mdiKeyboardReturn}
            onclick={() => {
              fecharModalsRecSenha()
            }}
          />
        </Modal.Footer>

      </Modal>


      <Modal show={modalDefinirSenha} backdrop="static" size="lg" keyboard={false} centered >

        <Modal.Header style={{ backgroundColor: "white" }}>
          <Modal.Title>Definir nova senha</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "white" }}>
          <div >
            <div >
              <form style={{ textAlign: "center" }}>
                <p>Redefina sua senha</p>
                <p><i>Escreva sua nova senha</i></p>
                <Row>
                  <Col sm={12} md={12} lg={5} style={{ margin: "auto", WebkitAppearance: "none" }}>
                    <Input
                      type="password"
                      name="pass"
                      value={senhaRec}
                      classNameInput="form-control"
                      classNameLabel="form-label"
                      onChange={setsenhaRec}
                      label="Senha"
                      required={true}
                    />
                  </Col>
                </Row>
                <div className="cad w-50" style={{ margin: "auto" }}>
                  <ValidaComplexidadeSenha senha={senhaRec} />
                </div>
                <Row>
                  <Col sm={12} md={12} lg={5} style={{ margin: "auto", WebkitAppearance: "none" }}>
                    <Input
                      type="password"
                      name="pass_confirm"
                      value={confirmar_senha}
                      classNameInput="form-control"
                      classNameLabel="form-label"
                      onChange={setconfirmar_senha}
                      label="Confirmar senha"
                      required={true}
                    />
                  </Col>
                </Row>
                <div className="cad w-50" style={{ margin: "auto" }}>
                  <ValidaComplexidadeSenha senha={confirmar_senha} />
                </div>

                <div className="d-flex my-2 gap-1 justify-content-between" style={{
                  width: "30%",
                  margin: "auto", paddingLeft: "8px"
                }}>
                  <ButtonCustom
                    invisivel={false}
                    type="button"
                    className="btn btn-light text-info-emphasis bg-white border-0"
                    descricaoBotao="Definir nova senha"
                    icone={mdiCheckCircle}
                    onclick={setNewSenha}
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "white", textAlign: "initial" }}>
          <ButtonCustom
            invisivel={false}
            type="button"
            className="btn btn-light text-danger bg-white border-0"
            descricaoBotao="Cancelar"
            icone={mdiKeyboardReturn}
            onclick={() => {
              fecharModalsRecSenha()
            }}
          />
        </Modal.Footer>
      </Modal>
    </main>
  );
}

export default Login