import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo";
import Formfilial from "./components/formulario";

export function FilialVisualizar() {
  const idfilial = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Visualizar Clínica" />
        <Formfilial idfilial={idfilial.id} acao="Visualizar" />
      </div>
    </>
  )
}
