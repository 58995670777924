import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal'
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../../../hooks/mascaras";
import { getSessionData } from "../../../../utils/storageUtils";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { useLocation } from "react-router-dom";
function NovoOtmForm() {
    const querys = new URLSearchParams(useLocation().search)
    const token = sessionStorage.getItem("MultfilialWebToken")!
    const id_filial = sessionStorage.getItem("DadosIdfilial")!
    const params = useParams()
    const mostrarModalMesAno = params.acao == "novo" ? true : false
    const [ModalMesAno, set_ModalMesAno] = useState(mostrarModalMesAno)
    const navigate = useNavigate()
    const [carregando, set_carregando] = useState(false)
    const dataHoje = new Date().toISOString()
    function formatarValor(valor: string) {
        if (!valor) return "0,00";

        // Verifica se o valor já está formatado no padrão com vírgula e ponto
        const regexFormato = /^\d{1,3}(\.\d{3})*,\d{2}$/;
        if (regexFormato.test(valor)) {
            return valor;
        }

        return parseFloat(valor)
            .toFixed(2) // Garante duas casas decimais
            .replace('.', ',') // Substitui o ponto por vírgula
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Adiciona o ponto como separador de milhar
    }
    const [id_fluxo, set_id_fluxo] = useState<any>("0")
    const [QtdeLinhasDespesas, setQtdeLinhasDespesas] = useState([1])
    async function carregarLinhasFC(id_fluxo: any) {
        try {
            set_carregando(true)
            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/criado/${id_fluxo}`, {
                headers: {
                    Authorization: token
                }
            })
            const despesasCarregados = resposta.data.despesas.length
            const novaQtdeDespesas = [1]
            for (let i = 1; i < despesasCarregados; i = i + 1) {
                const num = i + 1
                novaQtdeDespesas.push(num)
            }
            //guarda a quantidade
            setQtdeLinhasDespesas(novaQtdeDespesas)
            //set_ModalMesAno(false)
            set_carregando(false)
        } catch (error: any) {
            set_carregando(false)
            toast.error(error.response.data.message || error.message)
        }
    }
    function CriarNovaOtm() {
        const dados = {
            id_fluxo,
            mesanobase: mesAnoBase
        }
        set_carregando(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/cab/otm/${params.id_cliente}/${id_filial}`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            set_id_otm(resposta.data.id_otm)
            set_carregando(false)
            set_ModalMesAno(false)
        }).catch(function (erro) {
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }
    const [ListaFluxos, setListaFluxos] = useState([])
    function CarregarFluxosPessoaisCliente() {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cabecalho/fluxos/caixa/${params.id_cliente}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setListaFluxos(resposta.data.cabecalhos)
            set_ModalMesAno(true)
            set_carregando(false)
        }).catch(function (erro) {
            set_carregando(false)
            navigate(-1)
            toast.error(erro.response.data.message || erro.message)
        })
    }
    useEffect(function () {
        if (params.id_otimizacao == "0") {
            gerarMeses()
            CarregarFluxosPessoaisCliente()
        }
        else {
            carregarLinhasFC(params.id_fluxo)
            set_id_fluxo(params.id_fluxo)
            set_id_otm(params.id_otimizacao)
            set_mesAnoBase(querys.get("mesanobase"))
        }
    }, [])
    const [meses, set_meses] = useState<any>([])
    function gerarMeses() {
        const dataToday = new Date();
        const anoAtual = dataToday.getFullYear(); // Obtém o ano atual
        const mesAtual = dataToday.getMonth() + 1; // Obtém o mês atual (de 0 a 11)
        const datas = [];

        // Gera 3 meses para trás
        let ano = anoAtual;
        let mes = mesAtual;
        for (let i = 0; i < 3; i++) {
            mes--;
            if (mes < 1) {
                mes = 12;
                ano--;
            }
            datas.unshift({ mes: mes.toString().padStart(2, '0'), ano: ano }); // Adiciona ao início do array
        }

        // Reseta para o mês atual e ano
        ano = anoAtual;
        mes = mesAtual;

        // Gera 12 meses para frente
        for (let i = 0; i < 12; i++) {
            datas.push({ mes: mes.toString().padStart(2, '0'), ano: ano });
            mes++;
            if (mes > 12) {
                mes = 1;
                ano++;
            }
        }

        // Atualiza o estado com o array de datas
        set_meses(datas);
    }
    const [mesAnoBase, set_mesAnoBase] = useState<any>()
    const [despesasCarregadas, setDespesasCarregadas] = useState<any>([])
    useEffect(function () {
        if (QtdeLinhasDespesas.length > 0) {
            try {
                set_carregando(true)
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/criado/${id_fluxo}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(function (resposta) {
                    const despesasCarregados = resposta.data.despesas
                    setDespesasCarregadas(despesasCarregados)
                    if (despesasCarregados.length > 0) {
                        let totalMensal = 0
                        let totalAnual = 0
                        for (let i = 0; i < despesasCarregados.length; i = i + 1) {

                            const inputSelecionado = document.querySelector(`#inputdespesa${i + 1}`) as HTMLInputElement
                            inputSelecionado.value = formatarValor(resposta.data.despesas[i][`mes1`].toString())
                            const despesa = document.querySelector(`#despesa${i + 1}`) as HTMLInputElement
                            despesa.value = resposta.data.despesas[i].label
                            const anual = document.querySelector(`#inputdespesaanual${i + 1}`) as HTMLInputElement
                            anual.value = formatarValor((resposta.data.despesas[i][`mes1`] * 12).toString())

                            //calculando total mensal
                            totalMensal = totalMensal + resposta.data.despesas[i][`mes1`]
                            //calculando total anual
                            totalAnual = totalAnual + (resposta.data.despesas[i][`mes1`] * 12)
                        }
                        //setando total mensal
                        const inputTotalMensal = (document.querySelector("#totalMensal") as HTMLInputElement)
                        inputTotalMensal.value = formatarValor(totalMensal.toString())
                        //setando total anual
                        const inputTotalAnual = (document.querySelector("#totalAnual") as HTMLInputElement)
                        inputTotalAnual.value = formatarValor(totalAnual.toString())
                        CarregarOtmLinhas()
                    }
                    set_carregando(false)
                })
            } catch (error) {

            }
        }
    }, [QtdeLinhasDespesas])
    //const [ListaObjeto, setListaObjeto] = useState([])
    const [id_otm, set_id_otm] = useState<any>("0")
    function CalcularLinha(numeroLinha: number) {
        set_carregando(true)
        const novoMensal = document.querySelector(`#novomensal${numeroLinha}`) as HTMLInputElement
        novoMensal.value = formatarValor(parseFloat(novoMensal.value.replaceAll(".", "").replaceAll(",", ".")).toString())
        const valorNovoMensal = parseFloat(novoMensal.value.replaceAll(".", "").replaceAll(",", "."))
        const novoAnual = document.querySelector(`#novoanual${numeroLinha}`) as HTMLInputElement
        novoAnual.value = formatarValor((valorNovoMensal * 12).toString())
        const economiaMensal = document.querySelector(`#economiamensal${numeroLinha}`) as HTMLInputElement
        const mensalAtual = document.querySelector(`#inputdespesa${numeroLinha}`) as HTMLInputElement
        economiaMensal.value = formatarValor((parseFloat(mensalAtual.value.replaceAll(".", "").replaceAll(",", ".")) - valorNovoMensal).toString())
        const valorAnualAtual = document.querySelector(`#inputdespesaanual${numeroLinha}`) as HTMLInputElement
        const economiaAnualnovo = document.querySelector(`#economiaanual${numeroLinha}`) as HTMLInputElement
        economiaAnualnovo.value = formatarValor(((parseFloat(valorAnualAtual.value.replaceAll(".", "").replaceAll(",", ".")) - (valorNovoMensal * 12))).toString())

        const despesaLabel = (document.querySelector(`#despesa${numeroLinha}`) as HTMLInputElement).value
        const acaocombinada = (document.querySelector(`#acaocombinada${numeroLinha}`) as HTMLInputElement).value
        const linhaOtm = {
            despesa: despesaLabel,
            mensal: parseFloat(mensalAtual.value.replaceAll(".", "").replaceAll(",", ".")),
            anual: parseFloat(valorAnualAtual.value.replaceAll(".", "").replaceAll(",", ".")),
            novoMensal: valorNovoMensal,
            novoAnual: valorNovoMensal * 12,
            economiaMensal: parseFloat(mensalAtual.value.replaceAll(".", "").replaceAll(",", ".")) - valorNovoMensal,
            economiaAnual: parseFloat(valorAnualAtual.value.replaceAll(".", "").replaceAll(",", ".")) - (valorNovoMensal * 12),
            acaoCombinada: acaocombinada,
            linha: numeroLinha
        }
        const dados = {
            linha: linhaOtm
        }
        //cria ou atualiza a linha de otmz
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/cria/atualiza/linha/otm/${id_otm}/${params.id_cliente}/${id_filial}`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            //toast.success(resposta.data.message)
            CarregarOtmLinhas()
            set_carregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            set_carregando(false)
        })
    }
    function CarregarOtmLinhas() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/linhas/otmz/${params.id_cliente}/${id_otm}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            const linhas = resposta.data.linhas
            let novoTotalAnual = 0
            let novoEconomiaMensal = 0
            let novoEconomiaAnual = 0
            let totalNovoMensal = 0
            for (let i = 0; i < linhas.length; i = i + 1) {
                const linha = linhas[i]
                console.log(linha)
                //setando novo mensal da linha
                const novomensal = document.querySelector(`#novomensal${linha.linha}`) as HTMLInputElement
                novomensal.value = formatarValor(linha.novoMensal.toString())
                const novoanual = document.querySelector(`#novoanual${linha.linha}`) as HTMLInputElement
                novoanual.value = formatarValor(linha.novoAnual.toString())
                const economiamensal = document.querySelector(`#economiamensal${linha.linha}`) as HTMLInputElement
                economiamensal.value = formatarValor(linha.economiaMensal.toString())
                const economiaanual = document.querySelector(`#economiaanual${linha.linha}`) as HTMLInputElement
                economiaanual.value = formatarValor(linha.economiaAnual.toString())
                const acaocombinada = document.querySelector(`#acaocombinada${linha.linha}`) as HTMLInputElement
                acaocombinada.value = linha.acaoCombinada
                //calculando totalNovoMensal
                totalNovoMensal = totalNovoMensal + linha.novoMensal
                //calculando novoTotalAnual
                novoTotalAnual = novoTotalAnual + linha.novoAnual
                //calculando economia mensal total
                novoEconomiaMensal = novoEconomiaMensal + linha.economiaMensal
                //calculando novo economia anual
                novoEconomiaAnual = novoEconomiaAnual + linha.economiaAnual
            }
            //calculando total novo mensal
            const totalNovoMensalInput = document.querySelector("#totalNovoMensal") as HTMLInputElement
            totalNovoMensalInput.value = formatarValor(totalNovoMensal.toString())
            //totalNovoAnual
            const totalNovoAnualInput = document.querySelector("#totalNovoAnual") as HTMLInputElement
            totalNovoAnualInput.value = formatarValor(novoTotalAnual.toString())
            //totalEconomiaMensal
            const totalEconomiaMensalAnualInput = document.querySelector("#totalEconomiaMensal") as HTMLInputElement
            totalEconomiaMensalAnualInput.value = formatarValor(novoEconomiaMensal.toString())
            //setando totalEconomiaAnual
            const totalEconomiaAnualInput = document.querySelector("#totalEconomiaAnual") as HTMLInputElement
            totalEconomiaAnualInput.value = formatarValor(novoEconomiaAnual.toString())
        }).catch(function (erro) {
            console.log(erro)
            toast.error(erro.response.data.message || erro.message)
        })
    }
    return (
        <>
            <div className="col">
                <ContainerTitulo titulo={"Fluxo de caixa - OTIMIZAÇÃO"} />
            </div>
            <Card>
                <Card.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm col-md col">
                                <h4 className="text-center">PROPOSIÇÃO DE MELHORIAS NAS DESPESAS MENSAIS mês/Ano base: {mesAnoBase}</h4>
                            </div>
                            <div>
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Voltar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        navigate(-1);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row overflow-auto">
                        <div className="d-flex text-white">
                            <div className="col-sm col-md-3 col-lg-2 bg-dark py-1">
                                <p className="text-center m-0">DESPESA</p>
                            </div>
                            <div className="col-sm col-md-3 col-lg-2 bg-dark py-1">
                                <p className="text-center m-0">MENSAL</p>
                            </div>
                            <div className="col-sm col-md-3 col-lg-2 bg-dark py-1">
                                <p className="text-center m-0">ANUAL</p>
                            </div>
                            <div className="col-sm col-md-3 col-lg-2 bg-dark py-1">
                                <p className="text-center m-0">NOVO MENSAL</p>
                            </div>
                            <div className="col-sm col-md-3 col-lg-2 bg-dark py-1">
                                <p className="text-center m-0">NOVO ANUAL</p>
                            </div>
                            <div className="col-sm col-md-3 col-lg-2 bg-dark py-1">
                                <p className="text-center m-0">ECONOMIA MENSAL</p>
                            </div>
                            <div className="col-sm col-md-3 col-lg-2 bg-dark py-1">
                                <p className="text-center m-0">ECONOMIA ANUAL</p>
                            </div>
                            <div className="col-sm col-md-3 col-lg-5 bg-dark py-1">
                                <p className="text-center m-0">AÇÃO COMBINADA</p>
                            </div>
                        </div>
                        {
                            QtdeLinhasDespesas.map(function (numeroLinha) {
                                return <div className="row">
                                    <div className="d-flex text-black">
                                        <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                            <InputSemBorda
                                                name="real"
                                                type="text"
                                                id={`despesa${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={true}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                            <InputSemBorda
                                                name="real"
                                                type="text"
                                                id={`inputdespesa${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={true}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                            <InputSemBorda
                                                name="real"
                                                type="text"
                                                id={`inputdespesaanual${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={true}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                            <InputSemBorda
                                                name="real"
                                                type="text"
                                                id={`novomensal${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                onBlur={function () {
                                                    if (params.acao !== "visualizar") {
                                                        CalcularLinha(numeroLinha)
                                                    }
                                                }}
                                                readonly={params.acao == "visualizar" ? true : false}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                            <InputSemBorda
                                                name="real"
                                                type="text"
                                                id={`novoanual${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={true}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                            <InputSemBorda
                                                name="real"
                                                type="text"
                                                id={`economiamensal${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={true}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                            <InputSemBorda
                                                name="real"
                                                type="text"
                                                id={`economiaanual${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={true}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>

                                        <div className="col-sm col-md-3 col-lg-5 ms-1 py-1">
                                            <InputSemBorda
                                                name="real"
                                                type="text"
                                                id={`acaocombinada${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                onBlur={function () {
                                                    if (params.acao !== "visualizar") {
                                                        CalcularLinha(numeroLinha)
                                                    }
                                                }}
                                                readonly={params.acao == "visualizar" ? true : false}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        <div className="row">
                            <div className="d-flex text-black">
                                <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                    <p className="text-center mt-3">TOTAL</p>
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                    <InputSemBorda
                                        name="real"
                                        type="text"
                                        id="totalMensal"
                                        onChange={function () {

                                        }}
                                        readonly={true}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                    <InputSemBorda
                                        name="real"
                                        type="text"
                                        id="totalAnual"
                                        onChange={function () {

                                        }}
                                        readonly={true}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                    <InputSemBorda
                                        name="real"
                                        type="text"
                                        id="totalNovoMensal"
                                        onChange={function () {

                                        }}
                                        readonly={true}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                    <InputSemBorda
                                        name="real"
                                        type="text"
                                        id="totalNovoAnual"
                                        onChange={function () {

                                        }}
                                        readonly={true}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                    <InputSemBorda
                                        name="real"
                                        type="text"
                                        id="totalEconomiaMensal"
                                        onChange={function () {

                                        }}
                                        readonly={true}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 ms-1 py-1">
                                    <InputSemBorda
                                        name="real"
                                        type="text"
                                        id="totalEconomiaAnual"
                                        onChange={function () {

                                        }}
                                        readonly={true}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-5 ms-1 py-1">

                                </div>
                            </div>
                        </div>
                    </div>

                </Card.Body>
            </Card>
            <Modal show={ModalMesAno}>
                <Modal.Header className="bg-white">
                    <Modal.Title>Mês/Ano Base da otimização a ser criada</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Defina o mês/ano da otimização a ser criada.</h4>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select value={mesAnoBase} name="" id="" onChange={function (e) {
                                    set_mesAnoBase(e.target.value)
                                }} className="select-control bordasInferiorInput w-100">
                                    <option value=""></option>
                                    {
                                        meses.map(function (mes: any) {
                                            return <option value={mes.mes + "/" + mes.ano}>{mes.mes}/{mes.ano}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Selecione o Fluxo Caixa Pessoal / Base</h4>
                            </div>
                            <div className="w-100"></div>
                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select value={id_fluxo} name="" id="" onChange={function (e) {
                                    set_id_fluxo(e.target.value)
                                }} className="select-control bordasInferiorInput w-100">
                                    <option value=""></option>
                                    {
                                        ListaFluxos.map(function (fluxo: any) {
                                            return <option value={fluxo.id_fc}>{fluxo.mesanobase}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Cancelar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        set_ModalMesAno(false)
                                        navigate(-1)
                                    }}
                                />
                            </div>
                            <div className="col text-end">
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' || id_fluxo === "" || mesAnoBase === "" ? true : false}
                                    type="button"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Continuar"
                                    onclick={function () {
                                        carregarLinhasFC(id_fluxo)
                                        CriarNovaOtm()
                                    }}
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <ModalLoading show={carregando} />
        </>
    )
}

export default NovoOtmForm