import { useParams } from "react-router-dom";
import FormPerfil from "./components/formulario";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";

export function PermissaoEditar() {
  const idperfil = useParams();
  return (
    <>
      <div className="col mt-2">
        <ContainerTitulo titulo="Editar Perfil" />
        <FormPerfil idperfil={idperfil.id} acao="Editar" />
      </div>
    </>
  );
}
