import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../../components/Formularios/Table";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ApiGet from "../../../../api/endPoints/useGet";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiFileExcel,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
    mdiMicrosoftExcel
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../../../hooks/mascaras";
import { getSessionData } from "../../../../utils/storageUtils";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../../../components/dadosFixos/UF";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddIcon from "@mui/icons-material/Add";
import * as xlsx from "xlsx"
import * as FileSaver from "file-saver"
import exeljs from "exceljs"

function NovoFluxoCaixaList() {
    const [carregando, set_carregando] = useState(false)
    const [cpf, set_cpf] = useState("")
    const [nome, set_nome] = useState("")
    const [email, set_email] = useState("")
    const [celular, set_celular] = useState("")
    const [telefone, set_telefone] = useState("")
    const [data_nascimento, set_data_nascimento] = useState("19-02-2004")
    const [cep, set_cep] = useState("")
    const [logradouro, set_logradouro] = useState("")
    const [complemento, set_complemento] = useState("")
    const [numero, set_numero] = useState("")
    const [bairro, set_bairro] = useState("")
    const [cidade, set_cidade] = useState("")
    const [codmunicipio, set_codmunicipio] = useState("")
    const [uf, set_uf] = useState("")
    const [obs, set_obs] = useState("")
    const [status, set_status] = useState(true)
    function carregarCliente(id_cliente: any) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cadastro/cliente/${id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {
                if (resposta.data.codigo != 200) {

                    toast.error(resposta.data.message)
                }
                else {
                    const data = resposta.data.cliente[0].dtnascimento.split("T")
                    set_data_nascimento(data[0])

                    set_cpf(resposta.data.cliente[0].cpf)
                    set_nome(resposta.data.cliente[0].nome)
                    set_telefone(resposta.data.cliente[0].telfixo)
                    set_celular(resposta.data.cliente[0].telcelular)
                    set_email(resposta.data.cliente[0].email)
                    set_logradouro(resposta.data.cliente[0].logradouro)
                    set_numero(resposta.data.cliente[0].numero)
                    set_bairro(resposta.data.cliente[0].bairro)
                    set_complemento(resposta.data.cliente[0].compl)
                    set_codmunicipio(resposta.data.cliente[0].cnum)
                    set_cep(resposta.data.cliente[0].cep)
                    set_obs(resposta.data.cliente[0].observacao)
                    set_uf(resposta.data.cliente[0].cuf)
                    set_cidade(resposta.data.cliente[0].cidade)
                    set_status(resposta.data.cliente[0].status == 0 ? false : true)
                    setDadosCliente(resposta.data.cliente[0])
                }
            }).catch(function (erro) {

                toast.error(erro)
            })
    }
    //verifica permissão --- inicio
    const [permissao_editar, set_permissao_editar] = useState(false)
    const [permissao_visualizar, set_permissao_visualizar] = useState(false)
    const [permissao_excluir, set_permissao_excluir] = useState(false)
    const [permissao_incluir, set_permissao_incluir] = useState(false)
    async function CarregarPermissões() {

        const headers = {
            Authorization: getSessionData("MultfilialWebToken") || "",
        };
        const dados = {
            url: "/perfil/carregar/:idperfil",
            parametros: {
                idperfil: getSessionData("id_perfil") || "",
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response: any) {
            if (response[0].visualizarOrgFinanceira === 1) {
                set_permissao_visualizar(false)
            }
            if (response[0].EditarOrgFinanceira === 1) {
                set_permissao_editar(false)
            }
            if (response[0].ExcluirOrgFinanceira === 1) {
                set_permissao_excluir(false)
            }
            if (response[0].incluirOrgFinanceira === 1) {
                set_permissao_incluir(false)
            }
        })
    }
    useEffect(function () {
        carregarCliente(params.id_cliente)
        CarregarPermissões()
    }, [])
    //Controla as guias
    const [guia, setGuia] = useState("1");
    const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
        setGuia(novaGuia);
    }
    const token = sessionStorage.getItem("MultfilialWebToken")!
    const params = useParams()
    const navigate = useNavigate()
    //#region fluxo de caixa
    const [ListaFluxosCaixas, setListaFluxosCaias] = useState([])
    function CarregarFluxoCaixa() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cabecalho/fluxos/caixa/${params.id_cliente}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setListaFluxosCaias(resposta.data.cabecalhos)
        }).catch(function (error) {
            toast.error(error.response.data.message || error.message)
        })
    }
    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate(`/orgform/financeira/fluxocaixa/${params.id_cliente}/0/novo`)}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]
    const [busca, set_busca] = useState("")
    function Search(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()
        if (termoBusca == "") {

            CarregarFluxoCaixa()
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = ListaFluxosCaixas.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.mesanobase.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)
            })
            setListaFluxosCaias(resultados)
        }
    }
    useEffect(function () {
        Search(busca)
    }, [busca])
    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_fc" },
        { titulo: "Mês/Ano", acesso: "mesanobase" }
    ]
    // Função para converter base64 para buffer
    const [dadosCliente, setDadosCliente] = useState<any>()
    function formatarValor(valor: string) {
        if (!valor) return "0,00";

        // Verifica se o valor já está formatado no padrão com vírgula e ponto
        const regexFormato = /^\d{1,3}(\.\d{3})*,\d{2}$/;
        if (regexFormato.test(valor)) {
            return valor;
        }

        return parseFloat(valor)
            .toFixed(2) // Garante duas casas decimais
            .replace('.', ',') // Substitui o ponto por vírgula
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Adiciona o ponto como separador de milhar
    }
    function base64ToBuffer(base64: any) {
        const binaryString = atob(base64.split(',')[1]);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }
    function listarProximosMesesEAnos(numeroMes: any, anoAtual: any) {
        // Validar se o número do mês está dentro do intervalo válido (1 a 12)
        if (numeroMes < 1 || numeroMes > 12) {
            return null;
        }

        // Array de nomes dos meses
        let meses = [
            "Jan", "Fev", "Mar", "Abr",
            "Mai", "Jun", "Jul", "Ago",
            "Set", "Out", "Nov", "Dez"
        ];

        // Lista para armazenar os próximos 12 meses e anos
        let proximosMesesEAnos = [];

        // Loop para adicionar os próximos 12 meses e anos à lista
        for (let i = 0; i < 12; i++) {
            let mesIndex = (numeroMes + i - 1) % 12;
            let ano = (parseInt(anoAtual) + Math.floor((numeroMes + i - 1) / 12)).toString();
            proximosMesesEAnos.push({ mes: meses[mesIndex], ano: ano });
        }

        return proximosMesesEAnos
    }
    function ExportarFCPessoal(id_fluxo: any) {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/criado/${id_fluxo}`, {
            headers: {
                Authorization: token
            }
        }).then(async function (resposta) {
            // Cria um novo workbook e uma planilha
            const workbook = new exeljs.Workbook()
            const worksheetFC = workbook.addWorksheet('FC - Pessoal')

            const img64 = "data:image/png;base64," + getSessionData("imgFilial")!.replace(/["]/g, '')

            const imgBuffer = base64ToBuffer(img64)

            // Adiciona a imagem ao workbook
            const imagemID = workbook.addImage({
                buffer: imgBuffer,
                extension: "png"
            })

            worksheetFC.addImage(imagemID, {
                tl: { col: 0, row: 0 }, // Top-left corner position (coluna e linha são baseadas em 1, não 0)
                ext: { width: 150, height: 120 } // Defina a largura e altura que desejar
            })

            worksheetFC.getCell("A1").value = '                               PLANEJAMENTO FINANCEIRO E PATRIMONIAL: GESTÃO FINANCEIRA'
            worksheetFC.getRow(1).height = 60
            worksheetFC.getCell("A1").font = {
                size: 20,
                bold: true
            }

            worksheetFC.getCell("B2").value = `                               Projeto ${dadosCliente.cod_cliente} - ${dadosCliente.nome} - ${dadosCliente.cpf}`
            worksheetFC.getRow(2).height = 40
            worksheetFC.getCell("B2").font = {
                size: 15,
                bold: true
            }

            worksheetFC.getCell("A5").value = 'PROJEÇÃO DE RECEITAS E DESPESAS'
            worksheetFC.getRow(5).height = 30
            worksheetFC.getCell("A5").font = {
                size: 15,
                bold: true
            }

            const arrayDiaMesAno = new Date().toISOString().split("T")[0].split("-")
            worksheetFC.getCell("N2").value = arrayDiaMesAno[2] + "/" + arrayDiaMesAno[1] + "/" + arrayDiaMesAno[0]
            worksheetFC.getCell("N2").alignment = {
                vertical: "middle",
                horizontal: "center"
            }
            //retira pinta de branco rows de 1 a 6 
            for (let i = 1; i <= 160; i = i + 1) {

                worksheetFC.getCell(`A${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`B${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`C${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`E${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`D${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`F${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`G${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`H${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`I${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`J${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`K${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`L${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`M${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`N${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`M${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`O${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`P${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`Q${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`R${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`S${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`T${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`U${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`V${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFC.getCell(`W${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }
            }
            //congelo parte da tela para ficar de cabeçalho
            worksheetFC.views = [
                { state: "frozen", ySplit: 7 }
            ]
            //linha 7 deve ser o cabecalho
            const meses = listarProximosMesesEAnos(parseInt(resposta.data.cabecalho.mesanobase.split("/")[0]), resposta.data.cabecalho.mesanobase.split("/")[1])
            for (let i = 0; i < 1; i = i + 1) {
                worksheetFC.getCell("A7").value = "CONSOLIDADO"
                worksheetFC.getCell("B7").value = meses![0].mes + "/" + meses![0].ano
                worksheetFC.getCell("C7").value = meses![1].mes + "/" + meses![1].ano
                worksheetFC.getCell("D7").value = meses![2].mes + "/" + meses![2].ano
                worksheetFC.getCell("E7").value = meses![3].mes + "/" + meses![3].ano
                worksheetFC.getCell("F7").value = meses![4].mes + "/" + meses![4].ano
                worksheetFC.getCell("G7").value = meses![5].mes + "/" + meses![5].ano
                worksheetFC.getCell("H7").value = meses![6].mes + "/" + meses![6].ano
                worksheetFC.getCell("I7").value = meses![7].mes + "/" + meses![7].ano
                worksheetFC.getCell("J7").value = meses![8].mes + "/" + meses![8].ano
                worksheetFC.getCell("K7").value = meses![9].mes + "/" + meses![9].ano
                worksheetFC.getCell("L7").value = meses![10].mes + "/" + meses![10].ano
                worksheetFC.getCell("M7").value = meses![11].mes + "/" + meses![11].ano
                worksheetFC.getCell("A7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' } // Cor de preenchimento (background) preto
                };

                worksheetFC.getCell("B7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("C7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("D7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("E7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("F7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("G7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("H7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("I7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("J7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("K7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("L7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("M7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("N7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFC.getCell("O7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };
                worksheetFC.getCell("A7").font = {
                    color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                    bold: true // Deixa em negrito
                };

                worksheetFC.getCell("B7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("C7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("D7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("E7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("F7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("G7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("H7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("I7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("J7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("K7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("L7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("M7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("N7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("O7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                }
                worksheetFC.getCell("N7").value = "Total"
                worksheetFC.getCell("O7").value = "%Receita"
                worksheetFC.getCell("A7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("B7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("C7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("D7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("E7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("F7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("G7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("H7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("J7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("K7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("N7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFC.getCell("O7").alignment = { vertical: 'middle', horizontal: 'center' };
            }
            worksheetFC.getRow(7).height = 20
            const columA = worksheetFC.getColumn("A")
            columA.width = 25
            const columB = worksheetFC.getColumn("B")
            columB.width = 10
            const columnB = worksheetFC.getColumn("B");
            columnB.width = 10;
            const columnC = worksheetFC.getColumn("C");
            columnC.width = 10;
            const columnD = worksheetFC.getColumn("D");
            columnD.width = 10;
            const columnE = worksheetFC.getColumn("E");
            columnE.width = 10;
            const columnF = worksheetFC.getColumn("F");
            columnF.width = 10;
            const columnG = worksheetFC.getColumn("G");
            columnG.width = 10;
            const columnH = worksheetFC.getColumn("H");
            columnH.width = 10;
            const columnI = worksheetFC.getColumn("I");
            columnI.width = 10;
            const columnJ = worksheetFC.getColumn("J");
            columnJ.width = 10;
            const columnK = worksheetFC.getColumn("K");
            columnK.width = 10;
            const columnL = worksheetFC.getColumn("L");
            columnL.width = 10;
            const columnM = worksheetFC.getColumn("M");
            columnM.width = 10;
            const columnN = worksheetFC.getColumn("N");
            columnN.width = 10;
            const columnO = worksheetFC.getColumn("O");
            columnO.width = 10;
            const cellA8 = worksheetFC.getCell("A8")
            cellA8.value = "Receitas"
            cellA8.alignment = { horizontal: "center", vertical: "middle" }
            const cellA9 = worksheetFC.getCell("A9")
            cellA9.value = "Despesas"
            cellA9.alignment = { horizontal: "center", vertical: "middle" }
            const cellA10 = worksheetFC.getCell("A10")
            cellA10.value = "Variação"
            cellA10.alignment = { horizontal: "center", vertical: "middle" }
            const cellA11 = worksheetFC.getCell("A11")
            cellA11.alignment = { horizontal: "center", vertical: "middle" }
            cellA11.value = "Saldo Projetado"
            //calculando receitas (consolidado)
            const totalReceitasMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                totalReceitasMes.mes1 = totalReceitasMes.mes1 + resposta.data.ativos[i].mes1;
                totalReceitasMes.mes2 = totalReceitasMes.mes2 + resposta.data.ativos[i].mes2;
                totalReceitasMes.mes3 = totalReceitasMes.mes3 + resposta.data.ativos[i].mes3;
                totalReceitasMes.mes4 = totalReceitasMes.mes4 + resposta.data.ativos[i].mes4;
                totalReceitasMes.mes5 = totalReceitasMes.mes5 + resposta.data.ativos[i].mes5;
                totalReceitasMes.mes6 = totalReceitasMes.mes6 + resposta.data.ativos[i].mes6;
                totalReceitasMes.mes7 = totalReceitasMes.mes7 + resposta.data.ativos[i].mes7;
                totalReceitasMes.mes8 = totalReceitasMes.mes8 + resposta.data.ativos[i].mes8;
                totalReceitasMes.mes9 = totalReceitasMes.mes9 + resposta.data.ativos[i].mes9;
                totalReceitasMes.mes10 = totalReceitasMes.mes10 + resposta.data.ativos[i].mes10;
                totalReceitasMes.mes11 = totalReceitasMes.mes11 + resposta.data.ativos[i].mes11;
                totalReceitasMes.mes12 = totalReceitasMes.mes12 + resposta.data.ativos[i].mes12;
            }
            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                totalReceitasMes.mes1 = totalReceitasMes.mes1 + resposta.data.passivos[i].mes1;
                totalReceitasMes.mes2 = totalReceitasMes.mes2 + resposta.data.passivos[i].mes2;
                totalReceitasMes.mes3 = totalReceitasMes.mes3 + resposta.data.passivos[i].mes3;
                totalReceitasMes.mes4 = totalReceitasMes.mes4 + resposta.data.passivos[i].mes4;
                totalReceitasMes.mes5 = totalReceitasMes.mes5 + resposta.data.passivos[i].mes5;
                totalReceitasMes.mes6 = totalReceitasMes.mes6 + resposta.data.passivos[i].mes6;
                totalReceitasMes.mes7 = totalReceitasMes.mes7 + resposta.data.passivos[i].mes7;
                totalReceitasMes.mes8 = totalReceitasMes.mes8 + resposta.data.passivos[i].mes8;
                totalReceitasMes.mes9 = totalReceitasMes.mes9 + resposta.data.passivos[i].mes9;
                totalReceitasMes.mes10 = totalReceitasMes.mes10 + resposta.data.passivos[i].mes10;
                totalReceitasMes.mes11 = totalReceitasMes.mes11 + resposta.data.passivos[i].mes11;
                totalReceitasMes.mes12 = totalReceitasMes.mes12 + resposta.data.passivos[i].mes12;
            }
            const celB8 = worksheetFC.getCell("B8");
            celB8.value = formatarValor(totalReceitasMes.mes1.toString())
            const celC8 = worksheetFC.getCell("C8");
            celC8.value = formatarValor(totalReceitasMes.mes2.toString())
            const celD8 = worksheetFC.getCell("D8");
            celD8.value = formatarValor(totalReceitasMes.mes3.toString())
            const celE8 = worksheetFC.getCell("E8");
            celE8.value = formatarValor(totalReceitasMes.mes4.toString())
            const celF8 = worksheetFC.getCell("F8");
            celF8.value = formatarValor(totalReceitasMes.mes5.toString())
            const celG8 = worksheetFC.getCell("G8");
            celG8.value = formatarValor(totalReceitasMes.mes6.toString())
            const celH8 = worksheetFC.getCell("H8");
            celH8.value = formatarValor(totalReceitasMes.mes7.toString())
            const celI8 = worksheetFC.getCell("I8");
            celI8.value = formatarValor(totalReceitasMes.mes8.toString())
            const celJ8 = worksheetFC.getCell("J8");
            celJ8.value = formatarValor(totalReceitasMes.mes9.toString())
            const celK8 = worksheetFC.getCell("K8");
            celK8.value = formatarValor(totalReceitasMes.mes10.toString());
            const celL8 = worksheetFC.getCell("L8");
            celL8.value = formatarValor(totalReceitasMes.mes11.toString());
            const celM8 = worksheetFC.getCell("M8");
            celM8.value = formatarValor(totalReceitasMes.mes12.toString());
            const celN8 = worksheetFC.getCell("N8");
            celN8.value = formatarValor((totalReceitasMes.mes1 + totalReceitasMes.mes2 + totalReceitasMes.mes3 +
                totalReceitasMes.mes4 + totalReceitasMes.mes5 + totalReceitasMes.mes6 +
                totalReceitasMes.mes7 + totalReceitasMes.mes8 + totalReceitasMes.mes9 +
                totalReceitasMes.mes10 + totalReceitasMes.mes11 + totalReceitasMes.mes12).toString())
            worksheetFC.getCell("O8").value = "100"
            //gerando despesas totais(mes) consolidado
            const totalDespesasMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                totalDespesasMes.mes1 = totalDespesasMes.mes1 + resposta.data.despesas[i].mes1;
                totalDespesasMes.mes2 = totalDespesasMes.mes2 + resposta.data.despesas[i].mes2;
                totalDespesasMes.mes3 = totalDespesasMes.mes3 + resposta.data.despesas[i].mes3;
                totalDespesasMes.mes4 = totalDespesasMes.mes4 + resposta.data.despesas[i].mes4;
                totalDespesasMes.mes5 = totalDespesasMes.mes5 + resposta.data.despesas[i].mes5;
                totalDespesasMes.mes6 = totalDespesasMes.mes6 + resposta.data.despesas[i].mes6;
                totalDespesasMes.mes7 = totalDespesasMes.mes7 + resposta.data.despesas[i].mes7;
                totalDespesasMes.mes8 = totalDespesasMes.mes8 + resposta.data.despesas[i].mes8;
                totalDespesasMes.mes9 = totalDespesasMes.mes9 + resposta.data.despesas[i].mes9;
                totalDespesasMes.mes10 = totalDespesasMes.mes10 + resposta.data.despesas[i].mes10;
                totalDespesasMes.mes11 = totalDespesasMes.mes11 + resposta.data.despesas[i].mes11;
                totalDespesasMes.mes12 = totalDespesasMes.mes12 + resposta.data.despesas[i].mes12;
            }
            const celB9 = worksheetFC.getCell("B9");
            celB9.value = formatarValor(totalDespesasMes.mes1.toString());
            const celC9 = worksheetFC.getCell("C9");
            celC9.value = formatarValor(totalDespesasMes.mes2.toString());
            const celD9 = worksheetFC.getCell("D9");
            celD9.value = formatarValor(totalDespesasMes.mes3.toString());
            const celE9 = worksheetFC.getCell("E9");
            celE9.value = formatarValor(totalDespesasMes.mes4.toString());
            const celF9 = worksheetFC.getCell("F9");
            celF9.value = formatarValor(totalDespesasMes.mes5.toString());
            const celG9 = worksheetFC.getCell("G9");
            celG9.value = formatarValor(totalDespesasMes.mes6.toString());
            const celH9 = worksheetFC.getCell("H9");
            celH9.value = formatarValor(totalDespesasMes.mes7.toString());
            const celI9 = worksheetFC.getCell("I9");
            celI9.value = formatarValor(totalDespesasMes.mes8.toString());
            const celJ9 = worksheetFC.getCell("J9");
            celJ9.value = formatarValor(totalDespesasMes.mes9.toString());
            const celK9 = worksheetFC.getCell("K9");
            celK9.value = formatarValor(totalDespesasMes.mes10.toString());
            const celL9 = worksheetFC.getCell("L9");
            celL9.value = formatarValor(totalDespesasMes.mes11.toString());
            const celM9 = worksheetFC.getCell("M9");
            celM9.value = formatarValor(totalDespesasMes.mes12.toString());
            const celN9 = worksheetFC.getCell("N9");
            celN9.value = formatarValor((totalDespesasMes.mes1 + totalDespesasMes.mes2 + totalDespesasMes.mes3 +
                totalDespesasMes.mes4 + totalDespesasMes.mes5 + totalDespesasMes.mes6 +
                totalDespesasMes.mes7 + totalDespesasMes.mes8 + totalDespesasMes.mes9 +
                totalDespesasMes.mes10 + totalDespesasMes.mes11 + totalDespesasMes.mes12).toString())
            worksheetFC.getCell("O9").value = ((parseFloat(celN9.value) / parseFloat(celN8.value)) * 100).toPrecision(2).toString()
            const totalVariacaoMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < 12; i = i + 1) {
                totalVariacaoMes.mes1 = totalReceitasMes.mes1 - totalDespesasMes.mes1;
                totalVariacaoMes.mes2 = totalReceitasMes.mes2 - totalDespesasMes.mes2;
                totalVariacaoMes.mes3 = totalReceitasMes.mes3 - totalDespesasMes.mes3;
                totalVariacaoMes.mes4 = totalReceitasMes.mes4 - totalDespesasMes.mes4;
                totalVariacaoMes.mes5 = totalReceitasMes.mes5 - totalDespesasMes.mes5;
                totalVariacaoMes.mes6 = totalReceitasMes.mes6 - totalDespesasMes.mes6;
                totalVariacaoMes.mes7 = totalReceitasMes.mes7 - totalDespesasMes.mes7;
                totalVariacaoMes.mes8 = totalReceitasMes.mes8 - totalDespesasMes.mes8;
                totalVariacaoMes.mes9 = totalReceitasMes.mes9 - totalDespesasMes.mes9;
                totalVariacaoMes.mes10 = totalReceitasMes.mes10 - totalDespesasMes.mes10;
                totalVariacaoMes.mes11 = totalReceitasMes.mes11 - totalDespesasMes.mes11;
                totalVariacaoMes.mes12 = totalReceitasMes.mes12 - totalDespesasMes.mes12;
            }
            const celB10 = worksheetFC.getCell("B10");
            celB10.value = formatarValor(totalVariacaoMes.mes1.toString());
            const celC10 = worksheetFC.getCell("C10");
            celC10.value = formatarValor(totalVariacaoMes.mes2.toString());
            const celD10 = worksheetFC.getCell("D10");
            celD10.value = formatarValor(totalVariacaoMes.mes3.toString());
            const celE10 = worksheetFC.getCell("E10");
            celE10.value = formatarValor(totalVariacaoMes.mes4.toString());
            const celF10 = worksheetFC.getCell("F10");
            celF10.value = formatarValor(totalVariacaoMes.mes5.toString());
            const celG10 = worksheetFC.getCell("G10");
            celG10.value = formatarValor(totalVariacaoMes.mes6.toString());
            const celH10 = worksheetFC.getCell("H10");
            celH10.value = formatarValor(totalVariacaoMes.mes7.toString());
            const celI10 = worksheetFC.getCell("I10");
            celI10.value = formatarValor(totalVariacaoMes.mes8.toString());
            const celJ10 = worksheetFC.getCell("J10");
            celJ10.value = formatarValor(totalVariacaoMes.mes9.toString());
            const celK10 = worksheetFC.getCell("K10");
            celK10.value = formatarValor(totalVariacaoMes.mes10.toString());
            const celL10 = worksheetFC.getCell("L10");
            celL10.value = formatarValor(totalVariacaoMes.mes11.toString());
            const celM10 = worksheetFC.getCell("M10");
            celM10.value = formatarValor(totalVariacaoMes.mes12.toString());
            const totalProjetadoMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < 12; i = i + 1) {
                totalProjetadoMes.mes1 = totalVariacaoMes.mes1;
                totalProjetadoMes.mes2 = totalProjetadoMes.mes1 + totalVariacaoMes.mes2
                totalProjetadoMes.mes3 = totalProjetadoMes.mes2 + totalVariacaoMes.mes3;
                totalProjetadoMes.mes4 = totalProjetadoMes.mes3 + totalVariacaoMes.mes4;
                totalProjetadoMes.mes5 = totalProjetadoMes.mes4 + totalVariacaoMes.mes5;
                totalProjetadoMes.mes6 = totalProjetadoMes.mes5 + totalVariacaoMes.mes6;
                totalProjetadoMes.mes7 = totalProjetadoMes.mes6 + totalVariacaoMes.mes7;
                totalProjetadoMes.mes8 = totalProjetadoMes.mes7 + totalVariacaoMes.mes8;
                totalProjetadoMes.mes9 = totalProjetadoMes.mes8 + totalVariacaoMes.mes9;
                totalProjetadoMes.mes10 = totalProjetadoMes.mes9 + totalVariacaoMes.mes10;
                totalProjetadoMes.mes11 = totalProjetadoMes.mes10 + totalVariacaoMes.mes11;
                totalProjetadoMes.mes12 = totalProjetadoMes.mes11 + totalVariacaoMes.mes12
            }
            const celB11 = worksheetFC.getCell("B11");
            celB11.value = formatarValor(totalProjetadoMes.mes1.toString());
            const celC11 = worksheetFC.getCell("C11");
            celC11.value = formatarValor(totalProjetadoMes.mes2.toString());
            const celD11 = worksheetFC.getCell("D11");
            celD11.value = formatarValor(totalProjetadoMes.mes3.toString());
            const celE11 = worksheetFC.getCell("E11");
            celE11.value = formatarValor(totalProjetadoMes.mes4.toString());
            const celF11 = worksheetFC.getCell("F11");
            celF11.value = formatarValor(totalProjetadoMes.mes5.toString());
            const celG11 = worksheetFC.getCell("G11");
            celG11.value = formatarValor(totalProjetadoMes.mes6.toString());
            const celH11 = worksheetFC.getCell("H11");
            celH11.value = formatarValor(totalProjetadoMes.mes7.toString());
            const celI11 = worksheetFC.getCell("I11");
            celI11.value = formatarValor(totalProjetadoMes.mes8.toString())
            const celJ11 = worksheetFC.getCell("J11");
            celJ11.value = formatarValor(totalProjetadoMes.mes9.toString())
            const celK11 = worksheetFC.getCell("K11");
            celK11.value = formatarValor(totalProjetadoMes.mes10.toString())
            const celL11 = worksheetFC.getCell("L11");
            celL11.value = formatarValor(totalProjetadoMes.mes11.toString())
            const celM11 = worksheetFC.getCell("M11");
            celM11.value = formatarValor(totalProjetadoMes.mes12.toString())
            const cellA12 = worksheetFC.getCell("A12")
            cellA12.value = `RELAÇÃO DE RECEITAS`
            cellA12.font = {
                color: {
                    argb: "000080"
                },
                size: 15,
                bold: true
            }
            for (let i = 0; i < 1; i = i + 1) {
                worksheetFC.getCell("A13").value = "ORIGEM DA RENDA"
                worksheetFC.getCell("A13").alignment = { vertical: "middle", horizontal: "center" }
                worksheetFC.getCell("B13").value = meses![0].mes + "/" + meses![0].ano
                worksheetFC.getCell("C13").value = meses![1].mes + "/" + meses![1].ano
                worksheetFC.getCell("D13").value = meses![2].mes + "/" + meses![2].ano
                worksheetFC.getCell("E13").value = meses![3].mes + "/" + meses![3].ano
                worksheetFC.getCell("F13").value = meses![4].mes + "/" + meses![4].ano
                worksheetFC.getCell("G13").value = meses![5].mes + "/" + meses![5].ano
                worksheetFC.getCell("H13").value = meses![6].mes + "/" + meses![6].ano
                worksheetFC.getCell("I13").value = meses![7].mes + "/" + meses![7].ano
                worksheetFC.getCell("J13").value = meses![8].mes + "/" + meses![8].ano
                worksheetFC.getCell("K13").value = meses![9].mes + "/" + meses![9].ano
                worksheetFC.getCell("L13").value = meses![10].mes + "/" + meses![10].ano
                worksheetFC.getCell("M13").value = meses![11].mes + "/" + meses![11].ano
                worksheetFC.getCell("A13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' } // Cor de preenchimento (background) preto
                };

                worksheetFC.getCell("B13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("C13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("D13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("E13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("F13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("G13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("H13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("I13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("J13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("K13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("L13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("M13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("N13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFC.getCell("O13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };
                worksheetFC.getCell("A13").font = {
                    color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                    bold: true // Deixa em negrito
                };

                worksheetFC.getCell("B13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("C13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("D13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("E13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("F13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("G13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("H13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("I13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("J13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("K13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("L13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("M13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("N13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell("O13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                }
                worksheetFC.getCell("N13").value = "Total"
                worksheetFC.getCell("O13").value = "%Receita"
            }
            const cellA14 = worksheetFC.getCell("A14")
            cellA14.value = "RENDA ATIVA"
            cellA14.font = {
                bold: true
            }
            cellA14.alignment = {
                horizontal: "center", vertical: "middle"
            }
            const totalRendaAtivaMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            //soma valores MES
            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                totalRendaAtivaMes.mes1 = totalRendaAtivaMes.mes1 + resposta.data.ativos[i].mes1;
                totalRendaAtivaMes.mes2 = totalRendaAtivaMes.mes2 + resposta.data.ativos[i].mes2;
                totalRendaAtivaMes.mes3 = totalRendaAtivaMes.mes3 + resposta.data.ativos[i].mes3;
                totalRendaAtivaMes.mes4 = totalRendaAtivaMes.mes4 + resposta.data.ativos[i].mes4;
                totalRendaAtivaMes.mes5 = totalRendaAtivaMes.mes5 + resposta.data.ativos[i].mes5;
                totalRendaAtivaMes.mes6 = totalRendaAtivaMes.mes6 + resposta.data.ativos[i].mes6;
                totalRendaAtivaMes.mes7 = totalRendaAtivaMes.mes7 + resposta.data.ativos[i].mes7;
                totalRendaAtivaMes.mes8 = totalRendaAtivaMes.mes8 + resposta.data.ativos[i].mes8;
                totalRendaAtivaMes.mes9 = totalRendaAtivaMes.mes9 + resposta.data.ativos[i].mes9;
                totalRendaAtivaMes.mes10 = totalRendaAtivaMes.mes10 + resposta.data.ativos[i].mes10;
                totalRendaAtivaMes.mes11 = totalRendaAtivaMes.mes11 + resposta.data.ativos[i].mes11;
                totalRendaAtivaMes.mes12 = totalRendaAtivaMes.mes12 + resposta.data.ativos[i].mes12;
            }
            //soma valores LINHA
            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                //soma os valores do mes para dar o total da linha
                const ativo = resposta.data.ativos[i]
                const total = ativo.mes1 + ativo.mes2 + ativo.mes3 + ativo.mes4 + ativo.mes5 + ativo.mes6 + ativo.mes7 + ativo.mes8 + ativo.mes9 + ativo.mes10 + ativo.mes11 + ativo.mes12
                worksheetFC.getCell(`N${i + 15}`).value = formatarValor((total).toString())
                const totalReceita: any = worksheetFC.getCell("N8")!.value
                worksheetFC.getCell(`O${i + 15}`).value = ((total / parseFloat(totalReceita.replaceAll(".", "").replaceAll(",", "."))) * 100).toPrecision(2).toString()
            }
            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                const ativo = resposta.data.ativos[i]
                const cellA = worksheetFC.getCell(`A${15 + i}`)
                cellA.value = ativo.label
                cellA.alignment = { horizontal: "center", vertical: "middle" }
                const cellB = worksheetFC.getCell(`B${15 + i}`);
                cellB.value = formatarValor(ativo.mes1.toString());
                const cellC = worksheetFC.getCell(`C${15 + i}`);
                cellC.value = formatarValor(ativo.mes2.toString());
                const cellD = worksheetFC.getCell(`D${15 + i}`);
                cellD.value = formatarValor(ativo.mes3.toString());
                const cellE = worksheetFC.getCell(`E${15 + i}`);
                cellE.value = formatarValor(ativo.mes4.toString());
                const cellF = worksheetFC.getCell(`F${15 + i}`);
                cellF.value = formatarValor(ativo.mes5.toString())
                const cellG = worksheetFC.getCell(`G${15 + i}`);
                cellG.value = formatarValor(ativo.mes6.toString());
                const cellH = worksheetFC.getCell(`H${15 + i}`);
                cellH.value = formatarValor(ativo.mes7.toString());
                const cellI = worksheetFC.getCell(`I${15 + i}`);
                cellI.value = formatarValor(ativo.mes8.toString());
                const cellJ = worksheetFC.getCell(`J${15 + i}`);
                cellJ.value = formatarValor(ativo.mes9.toString());
                const cellK = worksheetFC.getCell(`K${15 + i}`);
                cellK.value = formatarValor(ativo.mes10.toString());
                const cellL = worksheetFC.getCell(`L${15 + i}`);
                cellL.value = formatarValor(ativo.mes11.toString());
                const cellM = worksheetFC.getCell(`M${15 + i}`);
                cellM.value = formatarValor(ativo.mes12.toString());
            }
            //preenchendo totais
            const cellA_anyAtivo = worksheetFC.getCell(`A${resposta.data.ativos.length + 15}`)
            cellA_anyAtivo.value = "Total Renda Ativa"
            cellA_anyAtivo.font = {
                bold: true
            }
            cellA_anyAtivo.alignment = {
                vertical: "middle", horizontal: "center"
            }
            const cellB_anyTotalatv1 = worksheetFC.getCell(`B${resposta.data.ativos.length + 15}`);
            cellB_anyTotalatv1.value = formatarValor(totalRendaAtivaMes.mes1.toString());
            const cellC_anyTotalatv2 = worksheetFC.getCell(`C${resposta.data.ativos.length + 15}`);
            cellC_anyTotalatv2.value = formatarValor(totalRendaAtivaMes.mes2.toString());
            const cellD_anyTotalatv3 = worksheetFC.getCell(`D${resposta.data.ativos.length + 15}`);
            cellD_anyTotalatv3.value = formatarValor(totalRendaAtivaMes.mes3.toString());
            const cellE_anyTotalatv4 = worksheetFC.getCell(`E${resposta.data.ativos.length + 15}`);
            cellE_anyTotalatv4.value = formatarValor(totalRendaAtivaMes.mes4.toString());
            const cellF_anyTotalatv5 = worksheetFC.getCell(`F${resposta.data.ativos.length + 15}`);
            cellF_anyTotalatv5.value = formatarValor(totalRendaAtivaMes.mes5.toString());
            const cellG_anyTotalatv6 = worksheetFC.getCell(`G${resposta.data.ativos.length + 15}`);
            cellG_anyTotalatv6.value = formatarValor(totalRendaAtivaMes.mes6.toString());
            const cellH_anyTotalatv7 = worksheetFC.getCell(`H${resposta.data.ativos.length + 15}`);
            cellH_anyTotalatv7.value = formatarValor(totalRendaAtivaMes.mes7.toString());
            const cellI_anyTotalatv8 = worksheetFC.getCell(`I${resposta.data.ativos.length + 15}`);
            cellI_anyTotalatv8.value = formatarValor(totalRendaAtivaMes.mes8.toString());
            const cellJ_anyTotalatv9 = worksheetFC.getCell(`J${resposta.data.ativos.length + 15}`);
            cellJ_anyTotalatv9.value = formatarValor(totalRendaAtivaMes.mes9.toString());
            const cellK_anyTotalatv10 = worksheetFC.getCell(`K${resposta.data.ativos.length + 15}`);
            cellK_anyTotalatv10.value = formatarValor(totalRendaAtivaMes.mes10.toString());
            const cellL_anyTotalatv11 = worksheetFC.getCell(`L${resposta.data.ativos.length + 15}`);
            cellL_anyTotalatv11.value = formatarValor(totalRendaAtivaMes.mes11.toString());
            const cellM_anyTotalatv12 = worksheetFC.getCell(`M${resposta.data.ativos.length + 15}`);
            cellM_anyTotalatv12.value = formatarValor(totalRendaAtivaMes.mes12.toString());
            worksheetFC.getCell(`N${resposta.data.ativos.length + 15}`).value = formatarValor((totalRendaAtivaMes.mes1 + totalRendaAtivaMes.mes2 + totalRendaAtivaMes.mes3 +
                totalRendaAtivaMes.mes4 + totalRendaAtivaMes.mes5 + totalRendaAtivaMes.mes6 +
                totalRendaAtivaMes.mes7 + totalRendaAtivaMes.mes8 + totalRendaAtivaMes.mes9 +
                totalRendaAtivaMes.mes10 + totalRendaAtivaMes.mes11 + totalRendaAtivaMes.mes12).toString())
            //gerar renda passiva
            const totalRendaPassivaMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                totalRendaPassivaMes.mes1 = totalRendaPassivaMes.mes1 + resposta.data.passivos[i].mes1;
                totalRendaPassivaMes.mes2 = totalRendaPassivaMes.mes2 + resposta.data.passivos[i].mes2;
                totalRendaPassivaMes.mes3 = totalRendaPassivaMes.mes3 + resposta.data.passivos[i].mes3;
                totalRendaPassivaMes.mes4 = totalRendaPassivaMes.mes4 + resposta.data.passivos[i].mes4;
                totalRendaPassivaMes.mes5 = totalRendaPassivaMes.mes5 + resposta.data.passivos[i].mes5;
                totalRendaPassivaMes.mes6 = totalRendaPassivaMes.mes6 + resposta.data.passivos[i].mes6;
                totalRendaPassivaMes.mes7 = totalRendaPassivaMes.mes7 + resposta.data.passivos[i].mes7;
                totalRendaPassivaMes.mes8 = totalRendaPassivaMes.mes8 + resposta.data.passivos[i].mes8;
                totalRendaPassivaMes.mes9 = totalRendaPassivaMes.mes9 + resposta.data.passivos[i].mes9;
                totalRendaPassivaMes.mes10 = totalRendaPassivaMes.mes10 + resposta.data.passivos[i].mes10;
                totalRendaPassivaMes.mes11 = totalRendaPassivaMes.mes11 + resposta.data.passivos[i].mes11;
                totalRendaPassivaMes.mes12 = totalRendaPassivaMes.mes12 + resposta.data.passivos[i].mes12;
            }
            //soma valores LINHA
            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                //soma os valores do mes para dar o total da linha
                const passivo = resposta.data.passivos[i]
                const total = passivo.mes1 + passivo.mes2 + passivo.mes3 + passivo.mes4 + passivo.mes5 + passivo.mes6 + passivo.mes7 + passivo.mes8 + passivo.mes9 + passivo.mes10 + passivo.mes11 + passivo.mes12
                worksheetFC.getCell(`N${i + 17 + resposta.data.ativos.length}`).value = formatarValor((total).toString())
                const totalReceita: any = worksheetFC.getCell("N8")!.value
                worksheetFC.getCell(`O${i + 17 + resposta.data.ativos.length}`).value = ((total / parseFloat(totalReceita.replaceAll(".", "").replaceAll(",", "."))) * 100).toPrecision(2).toString()
            }
            const cellA_any = worksheetFC.getCell(`A${resposta.data.ativos.length + 16}`)
            cellA_any.value = "RENDA PASSIVA"
            cellA_any.font = {
                bold: true
            }
            cellA_any.alignment = { horizontal: "center", vertical: "middle" }
            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                const passivo = resposta.data.passivos[i]
                const ultimoAtivo = resposta.data.ativos.length + 17
                const cellA = worksheetFC.getCell(`A${ultimoAtivo + i}`)
                cellA.value = passivo.label
                cellA.alignment = { horizontal: "center", vertical: "middle" }
                const cellB = worksheetFC.getCell(`B${ultimoAtivo + i}`);
                cellB.value = formatarValor(passivo.mes1.toString());
                const cellC = worksheetFC.getCell(`C${ultimoAtivo + i}`);
                cellC.value = formatarValor(passivo.mes2.toString());
                const cellD = worksheetFC.getCell(`D${ultimoAtivo + i}`);
                cellD.value = formatarValor(passivo.mes3.toString());
                const cellE = worksheetFC.getCell(`E${ultimoAtivo + i}`);
                cellE.value = formatarValor(passivo.mes4.toString());
                const cellF = worksheetFC.getCell(`F${ultimoAtivo + i}`);
                cellF.value = formatarValor(passivo.mes5.toString())
                const cellG = worksheetFC.getCell(`G${ultimoAtivo + i}`);
                cellG.value = formatarValor(passivo.mes6.toString());
                const cellH = worksheetFC.getCell(`H${ultimoAtivo + i}`);
                cellH.value = formatarValor(passivo.mes7.toString());
                const cellI = worksheetFC.getCell(`I${ultimoAtivo + i}`);
                cellI.value = formatarValor(passivo.mes8.toString());
                const cellJ = worksheetFC.getCell(`J${ultimoAtivo + i}`);
                cellJ.value = formatarValor(passivo.mes9.toString());
                const cellK = worksheetFC.getCell(`K${ultimoAtivo + i}`);
                cellK.value = formatarValor(passivo.mes10.toString());
                const cellL = worksheetFC.getCell(`L${ultimoAtivo + i}`);
                cellL.value = formatarValor(passivo.mes11.toString());
                const cellM = worksheetFC.getCell(`M${ultimoAtivo + i}`);
                cellM.value = formatarValor(passivo.mes12.toString());
            }
            //preenchendo totais
            const cellA_anyPassivo = worksheetFC.getCell(`A${resposta.data.ativos.length + resposta.data.passivos.length + 17}`)
            cellA_anyPassivo.value = "Total Renda Passiva"
            cellA_anyPassivo.font = {
                bold: true
            }
            cellA_anyPassivo.alignment = {
                vertical: "middle", horizontal: "center"
            }
            const cellB_anyTotalpassivo1 = worksheetFC.getCell(`B${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellB_anyTotalpassivo1.value = formatarValor(totalRendaPassivaMes.mes1.toString());
            const cellC_anyTotalpassivo2 = worksheetFC.getCell(`C${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellC_anyTotalpassivo2.value = formatarValor(totalRendaPassivaMes.mes2.toString());
            const cellD_anyTotalpassivo3 = worksheetFC.getCell(`D${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellD_anyTotalpassivo3.value = formatarValor(totalRendaPassivaMes.mes3.toString());
            const cellE_anyTotalpassivo4 = worksheetFC.getCell(`E${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellE_anyTotalpassivo4.value = formatarValor(totalRendaPassivaMes.mes4.toString());
            const cellF_anyTotalpassivo5 = worksheetFC.getCell(`F${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellF_anyTotalpassivo5.value = formatarValor(totalRendaPassivaMes.mes5.toString());
            const cellG_anyTotalpassivo6 = worksheetFC.getCell(`G${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellG_anyTotalpassivo6.value = formatarValor(totalRendaPassivaMes.mes6.toString());
            const cellH_anyTotalpassivo7 = worksheetFC.getCell(`H${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellH_anyTotalpassivo7.value = formatarValor(totalRendaPassivaMes.mes7.toString());
            const cellI_anyTotalpassivo8 = worksheetFC.getCell(`I${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellI_anyTotalpassivo8.value = formatarValor(totalRendaPassivaMes.mes8.toString());
            const cellJ_anyTotalpassivo9 = worksheetFC.getCell(`J${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellJ_anyTotalpassivo9.value = formatarValor(totalRendaPassivaMes.mes9.toString());
            const cellK_anyTotalpassivo10 = worksheetFC.getCell(`K${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellK_anyTotalpassivo10.value = formatarValor(totalRendaPassivaMes.mes10.toString());
            const cellL_anyTotalpassivo11 = worksheetFC.getCell(`L${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellL_anyTotalpassivo11.value = formatarValor(totalRendaPassivaMes.mes11.toString());
            const cellM_anyTotalpassivo12 = worksheetFC.getCell(`M${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellM_anyTotalpassivo12.value = formatarValor(totalRendaPassivaMes.mes12.toString());
            worksheetFC.getCell(`N${resposta.data.ativos.length + resposta.data.passivos.length + 17}`).value = formatarValor((totalRendaPassivaMes.mes1 + totalRendaPassivaMes.mes2 + totalRendaPassivaMes.mes3 +
                totalRendaPassivaMes.mes4 + totalRendaPassivaMes.mes5 + totalRendaPassivaMes.mes6 +
                totalRendaPassivaMes.mes7 + totalRendaPassivaMes.mes8 + totalRendaPassivaMes.mes9 +
                totalRendaPassivaMes.mes10 + totalRendaPassivaMes.mes11 + totalRendaPassivaMes.mes12).toString())
            //#region despesa
            const linha = 18 + resposta.data.ativos.length + resposta.data.passivos.length
            for (let i = 0; i < 1; i = i + 1) {
                worksheetFC.getCell(`A${linha}`).value = "DESPESAS"
                worksheetFC.getCell(`A${linha}`).alignment = { vertical: "middle", horizontal: "center" }
                worksheetFC.getCell(`B${linha}`).value = meses![0].mes + "/" + meses![0].ano
                worksheetFC.getCell(`C${linha}`).value = meses![1].mes + "/" + meses![1].ano
                worksheetFC.getCell(`D${linha}`).value = meses![2].mes + "/" + meses![2].ano
                worksheetFC.getCell(`E${linha}`).value = meses![3].mes + "/" + meses![3].ano
                worksheetFC.getCell(`F${linha}`).value = meses![4].mes + "/" + meses![4].ano
                worksheetFC.getCell(`G${linha}`).value = meses![5].mes + "/" + meses![5].ano
                worksheetFC.getCell(`H${linha}`).value = meses![6].mes + "/" + meses![6].ano
                worksheetFC.getCell(`I${linha}`).value = meses![7].mes + "/" + meses![7].ano
                worksheetFC.getCell(`J${linha}`).value = meses![8].mes + "/" + meses![8].ano
                worksheetFC.getCell(`K${linha}`).value = meses![9].mes + "/" + meses![9].ano
                worksheetFC.getCell(`L${linha}`).value = meses![10].mes + "/" + meses![10].ano
                worksheetFC.getCell(`M${linha}`).value = meses![11].mes + "/" + meses![11].ano
                worksheetFC.getCell(`A${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' } // Cor de preenchimento (background) preto
                };

                worksheetFC.getCell(`B${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`C${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`D${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`E${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`F${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`G${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`H${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`I${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`J${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`K${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`L${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`M${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`N${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFC.getCell(`O${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };
                worksheetFC.getCell(`A${linha}`).font = {
                    color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                    bold: true // Deixa em negrito
                };

                worksheetFC.getCell(`B${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`C${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`D${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`E${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`F${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`G${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`H${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`I${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`J${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`K${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`L${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`M${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`N${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFC.getCell(`O${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                }

                worksheetFC.getCell(`N${linha} `).value = "Total"
                worksheetFC.getCell(`O${linha}`).value = "%Receita"
            }
            //gerar despesa
            const totalDespesaMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                totalDespesaMes.mes1 = totalDespesaMes.mes1 + resposta.data.despesas[i].mes1;
                totalDespesaMes.mes2 = totalDespesaMes.mes2 + resposta.data.despesas[i].mes2;
                totalDespesaMes.mes3 = totalDespesaMes.mes3 + resposta.data.despesas[i].mes3;
                totalDespesaMes.mes4 = totalDespesaMes.mes4 + resposta.data.despesas[i].mes4;
                totalDespesaMes.mes5 = totalDespesaMes.mes5 + resposta.data.despesas[i].mes5;
                totalDespesaMes.mes6 = totalDespesaMes.mes6 + resposta.data.despesas[i].mes6;
                totalDespesaMes.mes7 = totalDespesaMes.mes7 + resposta.data.despesas[i].mes7;
                totalDespesaMes.mes8 = totalDespesaMes.mes8 + resposta.data.despesas[i].mes8;
                totalDespesaMes.mes9 = totalDespesaMes.mes9 + resposta.data.despesas[i].mes9;
                totalDespesaMes.mes10 = totalDespesaMes.mes10 + resposta.data.despesas[i].mes10;
                totalDespesaMes.mes11 = totalDespesaMes.mes11 + resposta.data.despesas[i].mes11;
                totalDespesaMes.mes12 = totalDespesaMes.mes12 + resposta.data.despesas[i].mes12;
            }
            //soma valores LINHA
            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                //soma os valores do mes para dar o total da linha
                const despesa = resposta.data.despesas[i]
                const total = despesa.mes1 + despesa.mes2 + despesa.mes3 + despesa.mes4 + despesa.mes5 + despesa.mes6 + despesa.mes7 + despesa.mes8 + despesa.mes9 + despesa.mes10 + despesa.mes11 + despesa.mes12
                worksheetFC.getCell(`N${i + linha + 1}`).value = formatarValor((total).toString())
                const totalDespesa: any = worksheetFC.getCell("N9")!.value
                worksheetFC.getCell(`O${i + linha + 1}`).value = ((total / parseFloat(totalDespesa.replaceAll(".", "").replaceAll(",", "."))) * 100).toString()
            }
            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                const despesa = resposta.data.despesas[i]
                const cellA = worksheetFC.getCell(`A${linha + 1 + i}`)
                cellA.value = despesa.label
                cellA.alignment = { horizontal: "center", vertical: "middle" }
                const cellB = worksheetFC.getCell(`B${linha + 1 + i}`);
                cellB.value = formatarValor(despesa.mes1.toString());
                const cellC = worksheetFC.getCell(`C${linha + 1 + i}`);
                cellC.value = formatarValor(despesa.mes2.toString());
                const cellD = worksheetFC.getCell(`D${linha + 1 + i}`);
                cellD.value = formatarValor(despesa.mes3.toString());
                const cellE = worksheetFC.getCell(`E${linha + 1 + i}`);
                cellE.value = formatarValor(despesa.mes4.toString());
                const cellF = worksheetFC.getCell(`F${linha + 1 + i}`);
                cellF.value = formatarValor(despesa.mes5.toString())
                const cellG = worksheetFC.getCell(`G${linha + 1 + i}`);
                cellG.value = formatarValor(despesa.mes6.toString());
                const cellH = worksheetFC.getCell(`H${linha + 1 + i}`);
                cellH.value = formatarValor(despesa.mes7.toString());
                const cellI = worksheetFC.getCell(`I${linha + 1 + i}`);
                cellI.value = formatarValor(despesa.mes8.toString());
                const cellJ = worksheetFC.getCell(`J${linha + 1 + i}`);
                cellJ.value = formatarValor(despesa.mes9.toString());
                const cellK = worksheetFC.getCell(`K${linha + 1 + i}`);
                cellK.value = formatarValor(despesa.mes10.toString());
                const cellL = worksheetFC.getCell(`L${linha + 1 + i}`);
                cellL.value = formatarValor(despesa.mes11.toString());
                const cellM = worksheetFC.getCell(`M${linha + 1 + i}`);
                cellM.value = formatarValor(despesa.mes12.toString());
            }
            //preenchendo totais
            const cellA_anyDespesas = worksheetFC.getCell(`A${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`)
            cellA_anyDespesas.value = "Total Despesas"
            cellA_anyDespesas.font = {
                bold: true
            }
            cellA_anyDespesas.alignment = {
                vertical: "middle", horizontal: "center"
            }
            const cellB_anyTotaldespesa1 = worksheetFC.getCell(`B${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellB_anyTotaldespesa1.value = formatarValor(totalDespesaMes.mes1.toString());
            const cellC_anyTotaldespesa2 = worksheetFC.getCell(`C${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellC_anyTotaldespesa2.value = formatarValor(totalDespesaMes.mes2.toString());
            const cellD_anyTotaldespesa3 = worksheetFC.getCell(`D${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellD_anyTotaldespesa3.value = formatarValor(totalDespesaMes.mes3.toString());
            const cellE_anyTotaldespesa4 = worksheetFC.getCell(`E${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellE_anyTotaldespesa4.value = formatarValor(totalDespesaMes.mes4.toString());
            const cellF_anyTotaldespesa5 = worksheetFC.getCell(`F${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellF_anyTotaldespesa5.value = formatarValor(totalDespesaMes.mes5.toString());
            const cellG_anyTotaldespesa6 = worksheetFC.getCell(`G${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellG_anyTotaldespesa6.value = formatarValor(totalDespesaMes.mes6.toString());
            const cellH_anyTotaldespesa7 = worksheetFC.getCell(`H${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellH_anyTotaldespesa7.value = formatarValor(totalDespesaMes.mes7.toString());
            const cellI_anyTotaldespesa8 = worksheetFC.getCell(`I${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellI_anyTotaldespesa8.value = formatarValor(totalDespesaMes.mes8.toString());
            const cellJ_anyTotaldespesa9 = worksheetFC.getCell(`J${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellJ_anyTotaldespesa9.value = formatarValor(totalDespesaMes.mes9.toString());
            const cellK_anyTotaldespesa10 = worksheetFC.getCell(`K${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellK_anyTotaldespesa10.value = formatarValor(totalDespesaMes.mes10.toString());
            const cellL_anyTotaldespesa11 = worksheetFC.getCell(`L${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellL_anyTotaldespesa11.value = formatarValor(totalDespesaMes.mes11.toString());
            const cellM_anyTotaldespesa12 = worksheetFC.getCell(`M${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellM_anyTotaldespesa12.value = formatarValor(totalDespesaMes.mes12.toString());
            worksheetFC.getCell(`N${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`).value = formatarValor((totalDespesaMes.mes1 + totalDespesaMes.mes2 + totalDespesaMes.mes3 +
                totalDespesaMes.mes4 + totalDespesaMes.mes5 + totalDespesaMes.mes6 +
                totalDespesaMes.mes7 + totalDespesaMes.mes8 + totalDespesaMes.mes9 +
                totalDespesaMes.mes10 + totalDespesaMes.mes11 + totalDespesaMes.mes12).toString())
            // Gera o arquivo Excel em formato de blob
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            FileSaver.saveAs(blob, "FC Pessoal.xlsx")
            set_carregando(false)
        }).catch(function (erro) {
            console.log(erro)
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }
    //#region Otimização
    const [ListaOtm, setListaOtm] = useState([])
    function CarregarOtm() {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/todos/cabecalhos/${params.id_cliente}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setListaOtm(resposta.data.cabecalhos)
            set_carregando(false)
        }).catch(function (error) {
            set_carregando(false)
            toast.error(error.response.data.message || error.message)
        })
    }
    const actions2: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate(`/orgform/financeira/otimizacao/${params.id_cliente}/0/novo/0`)}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]
    const [buscaOtm, set_buscaOtm] = useState("")
    function SearchOtm(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()
        if (termoBusca == "") {

            CarregarOtm()
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = ListaOtm.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.mesanobase.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)
            })
            setListaOtm(resultados)
        }
    }
    useEffect(function () {
        SearchOtm(busca)
    }, [buscaOtm])
    const colunasOtm: ITabela[] = [
        { titulo: "ID", acesso: "id_otm" },
        { titulo: "Mês/Ano", acesso: "mesanobase" }
    ]
    function ExportarOtimizacao(id_otm: any, otmCabecalho: any) {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/linhas/otmz/${params.id_cliente}/${id_otm}`, {
            headers: {
                Authorization: token
            }
        }).then(async function (resposta) {
            const linhas = resposta.data.linhas
            const workbookOtimizacao = new exeljs.Workbook()
            const worksheetOtimizacao = workbookOtimizacao.addWorksheet("Otimização")
            const img64 = "data:image/png;base64," + getSessionData("imgFilial")!.replace(/["]/g, '')

            const imgBuffer = base64ToBuffer(img64)

            // Adiciona a imagem ao workbook
            const imagemID = workbookOtimizacao.addImage({
                buffer: imgBuffer,
                extension: "png"
            })

            worksheetOtimizacao.addImage(imagemID, {
                tl: { col: 0, row: 0 }, // Top-left corner position (coluna e linha são baseadas em 1, não 0)
                ext: { width: 150, height: 120 } // Defina a largura e altura que desejar
            })

            worksheetOtimizacao.getCell("A1").value = '                               PLANEJAMENTO FINANCEIRO E PATRIMONIAL: GESTÃO FINANCEIRA'
            worksheetOtimizacao.getRow(1).height = 60
            worksheetOtimizacao.getCell("A1").font = {
                size: 20,
                bold: true
            }

            worksheetOtimizacao.getCell("B2").value = `                               Projeto ${dadosCliente.cod_cliente} - ${dadosCliente.nome} - ${dadosCliente.cpf}`
            worksheetOtimizacao.getRow(2).height = 40
            worksheetOtimizacao.getCell("B2").font = {
                size: 15,
                bold: true
            }

            worksheetOtimizacao.getCell("A5").value = 'PROPOSIÇÃO DE MELHORIAS NAS DESPESAS MENSAIS'
            worksheetOtimizacao.getRow(5).height = 30
            worksheetOtimizacao.getCell("A5").font = {
                size: 15,
                bold: true
            }

            const arrayDiaMesAno = new Date().toISOString().split("T")[0].split("-")
            worksheetOtimizacao.getCell("N2").value = arrayDiaMesAno[2] + "/" + arrayDiaMesAno[1] + "/" + arrayDiaMesAno[0]
            worksheetOtimizacao.getCell("N2").alignment = {
                vertical: "middle",
                horizontal: "center"
            }
            //retira pinta de branco rows de 1 a 6 
            for (let i = 1; i <= 160; i = i + 1) {

                worksheetOtimizacao.getCell(`A${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`B${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`C${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`E${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`D${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`F${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`G${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`H${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`I${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`J${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`K${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`L${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`M${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`N${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`M${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`O${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`P${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`Q${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`R${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`S${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`T${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`U${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`V${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetOtimizacao.getCell(`W${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }
            }
            //montando cabecalho
            const columA = worksheetOtimizacao.getColumn("A")
            columA.width = 20
            const columB = worksheetOtimizacao.getColumn("B")
            columB.width = 15
            const columC = worksheetOtimizacao.getColumn("C")
            columC.width = 15
            const columD = worksheetOtimizacao.getColumn("D")
            columD.width = 15
            const columE = worksheetOtimizacao.getColumn("E")
            columE.width = 15
            const columF = worksheetOtimizacao.getColumn("F")
            columF.width = 25
            const columG = worksheetOtimizacao.getColumn("G")
            columG.width = 25
            const columH = worksheetOtimizacao.getColumn("H")
            columH.width = 35
            const cellA6 = worksheetOtimizacao.getCell("A6")
            cellA6.alignment = { horizontal: "center", vertical: "middle" }
            cellA6.font = {
                bold: true,
                color: {
                    argb: "FFFFFF,"
                }
            }
            cellA6.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' }
            }
            cellA6.value = "DESPESA"
            const cellB6 = worksheetOtimizacao.getCell("B6")
            cellB6.alignment = { horizontal: "center", vertical: "middle" }
            cellB6.font = {
                bold: true,
                color: {
                    argb: "FFFFFF,"
                }
            }
            cellB6.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' }
            }
            cellB6.value = "MENSAL"
            const cellC6 = worksheetOtimizacao.getCell("C6")
            cellC6.alignment = { horizontal: "center", vertical: "middle" }
            cellC6.font = {
                bold: true,
                color: {
                    argb: "FFFFFF,"
                }
            }
            cellC6.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' }
            }
            cellC6.value = "ANUAL"
            const cellD6 = worksheetOtimizacao.getCell("D6")
            cellD6.alignment = { horizontal: "center", vertical: "middle" }
            cellD6.font = {
                bold: true,
                color: {
                    argb: "FFFFFF,"
                }
            }
            cellD6.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' }
            }
            cellD6.value = "NOVO MENSAL"
            const cellE6 = worksheetOtimizacao.getCell("E6")
            cellE6.alignment = { horizontal: "center", vertical: "middle" }
            cellE6.font = {
                bold: true,
                color: {
                    argb: "FFFFFF,"
                }
            }
            cellE6.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' }
            }
            cellE6.value = "NOVO ANUAL"
            const cellF6 = worksheetOtimizacao.getCell("F6")
            cellF6.alignment = { horizontal: "center", vertical: "middle" }
            cellF6.font = {
                bold: true,
                color: {
                    argb: "FFFFFF,"
                }
            }
            cellF6.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' }
            }
            cellF6.value = "ECONOMIA MENSAL"
            const cellG7 = worksheetOtimizacao.getCell("G6")
            cellG7.alignment = { horizontal: "center", vertical: "middle" }
            cellG7.font = {
                bold: true,
                color: {
                    argb: "FFFFFF,"
                }
            }
            cellG7.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' }
            }
            cellG7.value = "ECONOMIA ANUAL"
            const cellH6 = worksheetOtimizacao.getCell("H6")
            cellH6.alignment = { horizontal: "center", vertical: "middle" }
            cellH6.font = {
                bold: true,
                color: {
                    argb: "FFFFFF"
                }
            }
            cellH6.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' }
            }
            cellH6.value = "AÇÃO COMBINADA"
            let valorMensalAtual = 0
            let valorAnualAtual = 0
            let valorNovoMensal = 0
            let valorNovoAnual = 0
            let valorEconomiaMensal = 0
            let valorEconomiaAnual = 0
            for (let i = 0; i < linhas.length; i = i + 1) {
                const linha = linhas[i]
                const cellA6 = worksheetOtimizacao.getCell(`A${i + 7}`)
                cellA6.alignment = { horizontal: "center", vertical: "middle" }
                cellA6.value = linha.despesa
                const cellB6 = worksheetOtimizacao.getCell(`B${i + 7}`)
                cellB6.alignment = { horizontal: "center", vertical: "middle" }
                cellB6.value = formatarValor(linha.mensal.toString())
                valorMensalAtual = valorMensalAtual + linha.mensal
                const cellC6 = worksheetOtimizacao.getCell(`C${i + 7}`)
                cellC6.alignment = { horizontal: "center", vertical: "middle" }
                cellC6.value = formatarValor(linha.anual.toString())
                valorAnualAtual = valorAnualAtual + linha.anual
                const cellD6 = worksheetOtimizacao.getCell(`D${i + 7}`)
                cellD6.alignment = { horizontal: "center", vertical: "middle" }
                cellD6.value = formatarValor(linha.novoMensal.toString())
                valorNovoMensal = valorNovoMensal + linha.novoMensal
                const cellE6 = worksheetOtimizacao.getCell(`E${i + 7}`)
                cellE6.alignment = { horizontal: "center", vertical: "middle" }
                cellE6.value = formatarValor(linha.novoAnual.toString())
                valorNovoAnual = valorNovoAnual + linha.novoAnual
                const cellF6 = worksheetOtimizacao.getCell(`F${i + 7}`)
                cellF6.alignment = { horizontal: "center", vertical: "middle" }
                cellF6.value = formatarValor(linha.economiaMensal.toString())
                valorEconomiaMensal = valorEconomiaMensal + linha.economiaMensal
                const cellG7 = worksheetOtimizacao.getCell(`G${i + 7}`)
                cellG7.alignment = { horizontal: "center", vertical: "middle" }
                cellG7.value = formatarValor(linha.economiaAnual.toString())
                valorEconomiaAnual = valorEconomiaAnual + linha.economiaAnual
                const cellH7 = worksheetOtimizacao.getCell(`H${i + 7}`)
                cellH7.alignment = { horizontal: "center", vertical: "middle" }
                cellH7.value = linha.acaoCombinada
            }
            //gerando os totais
            const index = linhas.length + 7
            const cellA = worksheetOtimizacao.getCell(`A${index}`)
            cellA.value = "TOTAL"
            cellA.alignment = { horizontal: "center", vertical: "justify" }
            const cellB = worksheetOtimizacao.getCell(`B${index}`)
            cellB.value = formatarValor(valorMensalAtual.toString())
            cellB.alignment = { horizontal: "center", vertical: "justify" }
            const cellC = worksheetOtimizacao.getCell(`C${index}`)
            cellC.value = formatarValor(valorAnualAtual.toString())
            cellC.alignment = { horizontal: "center", vertical: "justify" }
            const cellD = worksheetOtimizacao.getCell(`D${index}`)
            cellD.value = formatarValor(valorNovoMensal.toString())
            cellD.alignment = { horizontal: "center", vertical: "justify" }
            const cellE = worksheetOtimizacao.getCell(`E${index}`)
            cellE.value = formatarValor(valorNovoAnual.toString())
            cellE.alignment = { horizontal: "center", vertical: "justify" }
            const cellF = worksheetOtimizacao.getCell(`F${index}`)
            cellF.value = formatarValor(valorEconomiaMensal.toString())
            cellF.alignment = { horizontal: "center", vertical: "justify" }
            const cellG = worksheetOtimizacao.getCell(`G${index}`)
            cellG.value = formatarValor(valorEconomiaAnual.toString())
            cellG.alignment = { horizontal: "center", vertical: "justify" }
            // Gera o arquivo Excel em formato de blob
            const buffer = await workbookOtimizacao.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            FileSaver.saveAs(blob, "Otimização.xlsx")
            set_carregando(false)
        }).catch(function (erro) {
            console.log(erro)
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }
    //#region fc proposto
    const [ListaFCProposto, setListaFCProposto] = useState([])
    function CarregarFCProposto() {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cabecalhos/fc/proposto/${params.id_cliente}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setListaFCProposto(resposta.data.cabecalhos)
            set_carregando(false)
        }).catch(function (error) {
            set_carregando(false)
            toast.error(error.response.data.message || error.message)
        })
    }
    const actions3: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate(`/orgform/financeira/fcproposto/${params.id_cliente}/0/novo/0`)}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]
    const colunasFcproposto: ITabela[] = [
        { titulo: "ID", acesso: "id_fc_proposto" },
        { titulo: "Mês/Ano", acesso: "mesanobase" }
    ]
    const [buscafcproposto, set_buscafcproposto] = useState("")
    function SearchFCPropsoto(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()
        if (termoBusca == "") {

            CarregarFCProposto()
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = ListaFCProposto.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.mesanobase.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)
            })
            setListaFCProposto(resultados)
        }
    }
    useEffect(function () {
        SearchFCPropsoto(buscafcproposto)
    }, [buscafcproposto])
    function ExportarFCProposto(id_fluxo: any) {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/proposto/criado/${id_fluxo}`, {
            headers: {
                Authorization: token
            }
        }).then(async function (resposta) {
            // Cria um novo workbook e uma planilha
            const workbook = new exeljs.Workbook()
            const worksheetFCProposto = workbook.addWorksheet('FC - Proposto')

            const img64 = "data:image/png;base64," + getSessionData("imgFilial")!.replace(/["]/g, '')

            const imgBuffer = base64ToBuffer(img64)

            // Adiciona a imagem ao workbook
            const imagemID = workbook.addImage({
                buffer: imgBuffer,
                extension: "png"
            })

            worksheetFCProposto.addImage(imagemID, {
                tl: { col: 0, row: 0 }, // Top-left corner position (coluna e linha são baseadas em 1, não 0)
                ext: { width: 150, height: 120 } // Defina a largura e altura que desejar
            })

            worksheetFCProposto.getCell("A1").value = '                               PLANEJAMENTO FINANCEIRO E PATRIMONIAL: GESTÃO FINANCEIRA'
            worksheetFCProposto.getRow(1).height = 60
            worksheetFCProposto.getCell("A1").font = {
                size: 20,
                bold: true
            }

            worksheetFCProposto.getCell("B2").value = `                               Projeto ${dadosCliente.cod_cliente} - ${dadosCliente.nome} - ${dadosCliente.cpf}`
            worksheetFCProposto.getRow(2).height = 40
            worksheetFCProposto.getCell("B2").font = {
                size: 15,
                bold: true
            }

            worksheetFCProposto.getCell("A5").value = 'PROJEÇÃO DE RECEITAS E DESPESAS'
            worksheetFCProposto.getRow(5).height = 30
            worksheetFCProposto.getCell("A5").font = {
                size: 15,
                bold: true
            }

            const arrayDiaMesAno = new Date().toISOString().split("T")[0].split("-")
            worksheetFCProposto.getCell("N2").value = arrayDiaMesAno[2] + "/" + arrayDiaMesAno[1] + "/" + arrayDiaMesAno[0]
            worksheetFCProposto.getCell("N2").alignment = {
                vertical: "middle",
                horizontal: "center"
            }
            //retira pinta de branco rows de 1 a 6 
            for (let i = 1; i <= 160; i = i + 1) {

                worksheetFCProposto.getCell(`A${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`B${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`C${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`E${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`D${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`F${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`G${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`H${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`I${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`J${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`K${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`L${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`M${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`N${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`M${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`O${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`P${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`Q${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`R${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`S${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`T${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`U${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`V${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }

                worksheetFCProposto.getCell(`W${i}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                }
            }
            //congelo parte da tela para ficar de cabeçalho
            worksheetFCProposto.views = [
                { state: "frozen", ySplit: 7 }
            ]
            //linha 7 deve ser o cabecalho
            const meses = listarProximosMesesEAnos(parseInt(resposta.data.cabecalho.mesanobase.split("/")[0]), resposta.data.cabecalho.mesanobase.split("/")[1])
            for (let i = 0; i < 1; i = i + 1) {
                worksheetFCProposto.getCell("A7").value = "CONSOLIDADO"
                worksheetFCProposto.getCell("B7").value = meses![0].mes + "/" + meses![0].ano
                worksheetFCProposto.getCell("C7").value = meses![1].mes + "/" + meses![1].ano
                worksheetFCProposto.getCell("D7").value = meses![2].mes + "/" + meses![2].ano
                worksheetFCProposto.getCell("E7").value = meses![3].mes + "/" + meses![3].ano
                worksheetFCProposto.getCell("F7").value = meses![4].mes + "/" + meses![4].ano
                worksheetFCProposto.getCell("G7").value = meses![5].mes + "/" + meses![5].ano
                worksheetFCProposto.getCell("H7").value = meses![6].mes + "/" + meses![6].ano
                worksheetFCProposto.getCell("I7").value = meses![7].mes + "/" + meses![7].ano
                worksheetFCProposto.getCell("J7").value = meses![8].mes + "/" + meses![8].ano
                worksheetFCProposto.getCell("K7").value = meses![9].mes + "/" + meses![9].ano
                worksheetFCProposto.getCell("L7").value = meses![10].mes + "/" + meses![10].ano
                worksheetFCProposto.getCell("M7").value = meses![11].mes + "/" + meses![11].ano
                worksheetFCProposto.getCell("A7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' } // Cor de preenchimento (background) preto
                };

                worksheetFCProposto.getCell("B7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("C7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("D7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("E7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("F7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("G7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("H7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("I7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("J7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("K7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("L7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("M7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("N7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };

                worksheetFCProposto.getCell("O7").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }
                };
                worksheetFCProposto.getCell("A7").font = {
                    color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                    bold: true // Deixa em negrito
                };

                worksheetFCProposto.getCell("B7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("C7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("D7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("E7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("F7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("G7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("H7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("I7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("J7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("K7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("L7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("M7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("N7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("O7").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                }
                worksheetFCProposto.getCell("N7").value = "Total"
                worksheetFCProposto.getCell("O7").value = "%Receita"
                worksheetFCProposto.getCell("A7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("B7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("C7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("D7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("E7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("F7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("G7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("H7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("J7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("K7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("N7").alignment = { vertical: 'middle', horizontal: 'center' };
                worksheetFCProposto.getCell("O7").alignment = { vertical: 'middle', horizontal: 'center' };
            }
            worksheetFCProposto.getRow(7).height = 20
            const columA = worksheetFCProposto.getColumn("A")
            columA.width = 25
            const columB = worksheetFCProposto.getColumn("B")
            columB.width = 10
            const columnB = worksheetFCProposto.getColumn("B");
            columnB.width = 10;
            const columnC = worksheetFCProposto.getColumn("C");
            columnC.width = 10;
            const columnD = worksheetFCProposto.getColumn("D");
            columnD.width = 10;
            const columnE = worksheetFCProposto.getColumn("E");
            columnE.width = 10;
            const columnF = worksheetFCProposto.getColumn("F");
            columnF.width = 10;
            const columnG = worksheetFCProposto.getColumn("G");
            columnG.width = 10;
            const columnH = worksheetFCProposto.getColumn("H");
            columnH.width = 10;
            const columnI = worksheetFCProposto.getColumn("I");
            columnI.width = 10;
            const columnJ = worksheetFCProposto.getColumn("J");
            columnJ.width = 10;
            const columnK = worksheetFCProposto.getColumn("K");
            columnK.width = 10;
            const columnL = worksheetFCProposto.getColumn("L");
            columnL.width = 10;
            const columnM = worksheetFCProposto.getColumn("M");
            columnM.width = 10;
            const columnN = worksheetFCProposto.getColumn("N");
            columnN.width = 10;
            const columnO = worksheetFCProposto.getColumn("O");
            columnO.width = 10;
            const cellA8 = worksheetFCProposto.getCell("A8")
            cellA8.value = "Receitas"
            cellA8.alignment = { horizontal: "center", vertical: "middle" }
            const cellA9 = worksheetFCProposto.getCell("A9")
            cellA9.value = "Despesas"
            cellA9.alignment = { horizontal: "center", vertical: "middle" }
            const cellA10 = worksheetFCProposto.getCell("A10")
            cellA10.value = "Variação"
            cellA10.alignment = { horizontal: "center", vertical: "middle" }
            const cellA11 = worksheetFCProposto.getCell("A11")
            cellA11.alignment = { horizontal: "center", vertical: "middle" }
            cellA11.value = "Saldo Projetado"
            //calculando receitas (consolidado)
            const totalReceitasMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                totalReceitasMes.mes1 = totalReceitasMes.mes1 + resposta.data.ativos[i].mes1;
                totalReceitasMes.mes2 = totalReceitasMes.mes2 + resposta.data.ativos[i].mes2;
                totalReceitasMes.mes3 = totalReceitasMes.mes3 + resposta.data.ativos[i].mes3;
                totalReceitasMes.mes4 = totalReceitasMes.mes4 + resposta.data.ativos[i].mes4;
                totalReceitasMes.mes5 = totalReceitasMes.mes5 + resposta.data.ativos[i].mes5;
                totalReceitasMes.mes6 = totalReceitasMes.mes6 + resposta.data.ativos[i].mes6;
                totalReceitasMes.mes7 = totalReceitasMes.mes7 + resposta.data.ativos[i].mes7;
                totalReceitasMes.mes8 = totalReceitasMes.mes8 + resposta.data.ativos[i].mes8;
                totalReceitasMes.mes9 = totalReceitasMes.mes9 + resposta.data.ativos[i].mes9;
                totalReceitasMes.mes10 = totalReceitasMes.mes10 + resposta.data.ativos[i].mes10;
                totalReceitasMes.mes11 = totalReceitasMes.mes11 + resposta.data.ativos[i].mes11;
                totalReceitasMes.mes12 = totalReceitasMes.mes12 + resposta.data.ativos[i].mes12;
            }
            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                totalReceitasMes.mes1 = totalReceitasMes.mes1 + resposta.data.passivos[i].mes1;
                totalReceitasMes.mes2 = totalReceitasMes.mes2 + resposta.data.passivos[i].mes2;
                totalReceitasMes.mes3 = totalReceitasMes.mes3 + resposta.data.passivos[i].mes3;
                totalReceitasMes.mes4 = totalReceitasMes.mes4 + resposta.data.passivos[i].mes4;
                totalReceitasMes.mes5 = totalReceitasMes.mes5 + resposta.data.passivos[i].mes5;
                totalReceitasMes.mes6 = totalReceitasMes.mes6 + resposta.data.passivos[i].mes6;
                totalReceitasMes.mes7 = totalReceitasMes.mes7 + resposta.data.passivos[i].mes7;
                totalReceitasMes.mes8 = totalReceitasMes.mes8 + resposta.data.passivos[i].mes8;
                totalReceitasMes.mes9 = totalReceitasMes.mes9 + resposta.data.passivos[i].mes9;
                totalReceitasMes.mes10 = totalReceitasMes.mes10 + resposta.data.passivos[i].mes10;
                totalReceitasMes.mes11 = totalReceitasMes.mes11 + resposta.data.passivos[i].mes11;
                totalReceitasMes.mes12 = totalReceitasMes.mes12 + resposta.data.passivos[i].mes12;
            }
            const celB8 = worksheetFCProposto.getCell("B8");
            celB8.value = formatarValor(totalReceitasMes.mes1.toString())
            const celC8 = worksheetFCProposto.getCell("C8");
            celC8.value = formatarValor(totalReceitasMes.mes2.toString())
            const celD8 = worksheetFCProposto.getCell("D8");
            celD8.value = formatarValor(totalReceitasMes.mes3.toString())
            const celE8 = worksheetFCProposto.getCell("E8");
            celE8.value = formatarValor(totalReceitasMes.mes4.toString())
            const celF8 = worksheetFCProposto.getCell("F8");
            celF8.value = formatarValor(totalReceitasMes.mes5.toString())
            const celG8 = worksheetFCProposto.getCell("G8");
            celG8.value = formatarValor(totalReceitasMes.mes6.toString())
            const celH8 = worksheetFCProposto.getCell("H8");
            celH8.value = formatarValor(totalReceitasMes.mes7.toString())
            const celI8 = worksheetFCProposto.getCell("I8");
            celI8.value = formatarValor(totalReceitasMes.mes8.toString())
            const celJ8 = worksheetFCProposto.getCell("J8");
            celJ8.value = formatarValor(totalReceitasMes.mes9.toString())
            const celK8 = worksheetFCProposto.getCell("K8");
            celK8.value = formatarValor(totalReceitasMes.mes10.toString());
            const celL8 = worksheetFCProposto.getCell("L8");
            celL8.value = formatarValor(totalReceitasMes.mes11.toString());
            const celM8 = worksheetFCProposto.getCell("M8");
            celM8.value = formatarValor(totalReceitasMes.mes12.toString());
            const celN8 = worksheetFCProposto.getCell("N8");
            celN8.value = formatarValor((totalReceitasMes.mes1 + totalReceitasMes.mes2 + totalReceitasMes.mes3 +
                totalReceitasMes.mes4 + totalReceitasMes.mes5 + totalReceitasMes.mes6 +
                totalReceitasMes.mes7 + totalReceitasMes.mes8 + totalReceitasMes.mes9 +
                totalReceitasMes.mes10 + totalReceitasMes.mes11 + totalReceitasMes.mes12).toString())
            worksheetFCProposto.getCell("O8").value = "100"
            //gerando despesas totais(mes) consolidado
            const totalDespesasMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                totalDespesasMes.mes1 = totalDespesasMes.mes1 + resposta.data.despesas[i].mes1;
                totalDespesasMes.mes2 = totalDespesasMes.mes2 + resposta.data.despesas[i].mes2;
                totalDespesasMes.mes3 = totalDespesasMes.mes3 + resposta.data.despesas[i].mes3;
                totalDespesasMes.mes4 = totalDespesasMes.mes4 + resposta.data.despesas[i].mes4;
                totalDespesasMes.mes5 = totalDespesasMes.mes5 + resposta.data.despesas[i].mes5;
                totalDespesasMes.mes6 = totalDespesasMes.mes6 + resposta.data.despesas[i].mes6;
                totalDespesasMes.mes7 = totalDespesasMes.mes7 + resposta.data.despesas[i].mes7;
                totalDespesasMes.mes8 = totalDespesasMes.mes8 + resposta.data.despesas[i].mes8;
                totalDespesasMes.mes9 = totalDespesasMes.mes9 + resposta.data.despesas[i].mes9;
                totalDespesasMes.mes10 = totalDespesasMes.mes10 + resposta.data.despesas[i].mes10;
                totalDespesasMes.mes11 = totalDespesasMes.mes11 + resposta.data.despesas[i].mes11;
                totalDespesasMes.mes12 = totalDespesasMes.mes12 + resposta.data.despesas[i].mes12;
            }
            const celB9 = worksheetFCProposto.getCell("B9");
            celB9.value = formatarValor(totalDespesasMes.mes1.toString());
            const celC9 = worksheetFCProposto.getCell("C9");
            celC9.value = formatarValor(totalDespesasMes.mes2.toString());
            const celD9 = worksheetFCProposto.getCell("D9");
            celD9.value = formatarValor(totalDespesasMes.mes3.toString());
            const celE9 = worksheetFCProposto.getCell("E9");
            celE9.value = formatarValor(totalDespesasMes.mes4.toString());
            const celF9 = worksheetFCProposto.getCell("F9");
            celF9.value = formatarValor(totalDespesasMes.mes5.toString());
            const celG9 = worksheetFCProposto.getCell("G9");
            celG9.value = formatarValor(totalDespesasMes.mes6.toString());
            const celH9 = worksheetFCProposto.getCell("H9");
            celH9.value = formatarValor(totalDespesasMes.mes7.toString());
            const celI9 = worksheetFCProposto.getCell("I9");
            celI9.value = formatarValor(totalDespesasMes.mes8.toString());
            const celJ9 = worksheetFCProposto.getCell("J9");
            celJ9.value = formatarValor(totalDespesasMes.mes9.toString());
            const celK9 = worksheetFCProposto.getCell("K9");
            celK9.value = formatarValor(totalDespesasMes.mes10.toString());
            const celL9 = worksheetFCProposto.getCell("L9");
            celL9.value = formatarValor(totalDespesasMes.mes11.toString());
            const celM9 = worksheetFCProposto.getCell("M9");
            celM9.value = formatarValor(totalDespesasMes.mes12.toString());
            const celN9 = worksheetFCProposto.getCell("N9");
            celN9.value = formatarValor((totalDespesasMes.mes1 + totalDespesasMes.mes2 + totalDespesasMes.mes3 +
                totalDespesasMes.mes4 + totalDespesasMes.mes5 + totalDespesasMes.mes6 +
                totalDespesasMes.mes7 + totalDespesasMes.mes8 + totalDespesasMes.mes9 +
                totalDespesasMes.mes10 + totalDespesasMes.mes11 + totalDespesasMes.mes12).toString())
            worksheetFCProposto.getCell("O9").value = ((parseFloat(celN9.value) / parseFloat(celN8.value)) * 100).toPrecision(2).toString()
            const totalVariacaoMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < 12; i = i + 1) {
                totalVariacaoMes.mes1 = totalReceitasMes.mes1 - totalDespesasMes.mes1;
                totalVariacaoMes.mes2 = totalReceitasMes.mes2 - totalDespesasMes.mes2;
                totalVariacaoMes.mes3 = totalReceitasMes.mes3 - totalDespesasMes.mes3;
                totalVariacaoMes.mes4 = totalReceitasMes.mes4 - totalDespesasMes.mes4;
                totalVariacaoMes.mes5 = totalReceitasMes.mes5 - totalDespesasMes.mes5;
                totalVariacaoMes.mes6 = totalReceitasMes.mes6 - totalDespesasMes.mes6;
                totalVariacaoMes.mes7 = totalReceitasMes.mes7 - totalDespesasMes.mes7;
                totalVariacaoMes.mes8 = totalReceitasMes.mes8 - totalDespesasMes.mes8;
                totalVariacaoMes.mes9 = totalReceitasMes.mes9 - totalDespesasMes.mes9;
                totalVariacaoMes.mes10 = totalReceitasMes.mes10 - totalDespesasMes.mes10;
                totalVariacaoMes.mes11 = totalReceitasMes.mes11 - totalDespesasMes.mes11;
                totalVariacaoMes.mes12 = totalReceitasMes.mes12 - totalDespesasMes.mes12;
            }
            const celB10 = worksheetFCProposto.getCell("B10");
            celB10.value = formatarValor(totalVariacaoMes.mes1.toString());
            const celC10 = worksheetFCProposto.getCell("C10");
            celC10.value = formatarValor(totalVariacaoMes.mes2.toString());
            const celD10 = worksheetFCProposto.getCell("D10");
            celD10.value = formatarValor(totalVariacaoMes.mes3.toString());
            const celE10 = worksheetFCProposto.getCell("E10");
            celE10.value = formatarValor(totalVariacaoMes.mes4.toString());
            const celF10 = worksheetFCProposto.getCell("F10");
            celF10.value = formatarValor(totalVariacaoMes.mes5.toString());
            const celG10 = worksheetFCProposto.getCell("G10");
            celG10.value = formatarValor(totalVariacaoMes.mes6.toString());
            const celH10 = worksheetFCProposto.getCell("H10");
            celH10.value = formatarValor(totalVariacaoMes.mes7.toString());
            const celI10 = worksheetFCProposto.getCell("I10");
            celI10.value = formatarValor(totalVariacaoMes.mes8.toString());
            const celJ10 = worksheetFCProposto.getCell("J10");
            celJ10.value = formatarValor(totalVariacaoMes.mes9.toString());
            const celK10 = worksheetFCProposto.getCell("K10");
            celK10.value = formatarValor(totalVariacaoMes.mes10.toString());
            const celL10 = worksheetFCProposto.getCell("L10");
            celL10.value = formatarValor(totalVariacaoMes.mes11.toString());
            const celM10 = worksheetFCProposto.getCell("M10");
            celM10.value = formatarValor(totalVariacaoMes.mes12.toString());
            const totalProjetadoMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < 12; i = i + 1) {
                totalProjetadoMes.mes1 = totalVariacaoMes.mes1;
                totalProjetadoMes.mes2 = totalProjetadoMes.mes1 + totalVariacaoMes.mes2
                totalProjetadoMes.mes3 = totalProjetadoMes.mes2 + totalVariacaoMes.mes3;
                totalProjetadoMes.mes4 = totalProjetadoMes.mes3 + totalVariacaoMes.mes4;
                totalProjetadoMes.mes5 = totalProjetadoMes.mes4 + totalVariacaoMes.mes5;
                totalProjetadoMes.mes6 = totalProjetadoMes.mes5 + totalVariacaoMes.mes6;
                totalProjetadoMes.mes7 = totalProjetadoMes.mes6 + totalVariacaoMes.mes7;
                totalProjetadoMes.mes8 = totalProjetadoMes.mes7 + totalVariacaoMes.mes8;
                totalProjetadoMes.mes9 = totalProjetadoMes.mes8 + totalVariacaoMes.mes9;
                totalProjetadoMes.mes10 = totalProjetadoMes.mes9 + totalVariacaoMes.mes10;
                totalProjetadoMes.mes11 = totalProjetadoMes.mes10 + totalVariacaoMes.mes11;
                totalProjetadoMes.mes12 = totalProjetadoMes.mes11 + totalVariacaoMes.mes12
            }
            const celB11 = worksheetFCProposto.getCell("B11");
            celB11.value = formatarValor(totalProjetadoMes.mes1.toString());
            const celC11 = worksheetFCProposto.getCell("C11");
            celC11.value = formatarValor(totalProjetadoMes.mes2.toString());
            const celD11 = worksheetFCProposto.getCell("D11");
            celD11.value = formatarValor(totalProjetadoMes.mes3.toString());
            const celE11 = worksheetFCProposto.getCell("E11");
            celE11.value = formatarValor(totalProjetadoMes.mes4.toString());
            const celF11 = worksheetFCProposto.getCell("F11");
            celF11.value = formatarValor(totalProjetadoMes.mes5.toString());
            const celG11 = worksheetFCProposto.getCell("G11");
            celG11.value = formatarValor(totalProjetadoMes.mes6.toString());
            const celH11 = worksheetFCProposto.getCell("H11");
            celH11.value = formatarValor(totalProjetadoMes.mes7.toString());
            const celI11 = worksheetFCProposto.getCell("I11");
            celI11.value = formatarValor(totalProjetadoMes.mes8.toString())
            const celJ11 = worksheetFCProposto.getCell("J11");
            celJ11.value = formatarValor(totalProjetadoMes.mes9.toString())
            const celK11 = worksheetFCProposto.getCell("K11");
            celK11.value = formatarValor(totalProjetadoMes.mes10.toString())
            const celL11 = worksheetFCProposto.getCell("L11");
            celL11.value = formatarValor(totalProjetadoMes.mes11.toString())
            const celM11 = worksheetFCProposto.getCell("M11");
            celM11.value = formatarValor(totalProjetadoMes.mes12.toString())
            const cellA12 = worksheetFCProposto.getCell("A12")
            cellA12.value = `RELAÇÃO DE RECEITAS`
            cellA12.font = {
                color: {
                    argb: "000080"
                },
                size: 15,
                bold: true
            }
            for (let i = 0; i < 1; i = i + 1) {
                worksheetFCProposto.getCell("A13").value = "ORIGEM DA RENDA"
                worksheetFCProposto.getCell("A13").alignment = { vertical: "middle", horizontal: "center" }
                worksheetFCProposto.getCell("B13").value = meses![0].mes + "/" + meses![0].ano
                worksheetFCProposto.getCell("C13").value = meses![1].mes + "/" + meses![1].ano
                worksheetFCProposto.getCell("D13").value = meses![2].mes + "/" + meses![2].ano
                worksheetFCProposto.getCell("E13").value = meses![3].mes + "/" + meses![3].ano
                worksheetFCProposto.getCell("F13").value = meses![4].mes + "/" + meses![4].ano
                worksheetFCProposto.getCell("G13").value = meses![5].mes + "/" + meses![5].ano
                worksheetFCProposto.getCell("H13").value = meses![6].mes + "/" + meses![6].ano
                worksheetFCProposto.getCell("I13").value = meses![7].mes + "/" + meses![7].ano
                worksheetFCProposto.getCell("J13").value = meses![8].mes + "/" + meses![8].ano
                worksheetFCProposto.getCell("K13").value = meses![9].mes + "/" + meses![9].ano
                worksheetFCProposto.getCell("L13").value = meses![10].mes + "/" + meses![10].ano
                worksheetFCProposto.getCell("M13").value = meses![11].mes + "/" + meses![11].ano
                worksheetFCProposto.getCell("A13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' } // Cor de preenchimento (background) preto
                };

                worksheetFCProposto.getCell("B13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("C13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("D13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("E13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("F13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("G13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("H13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("I13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("J13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("K13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("L13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("M13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("N13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };

                worksheetFCProposto.getCell("O13").fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000080' }
                };
                worksheetFCProposto.getCell("A13").font = {
                    color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                    bold: true // Deixa em negrito
                };

                worksheetFCProposto.getCell("B13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("C13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("D13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("E13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("F13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("G13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("H13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("I13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("J13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("K13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("L13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("M13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("N13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell("O13").font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                }
                worksheetFCProposto.getCell("N13").value = "Total"
                worksheetFCProposto.getCell("O13").value = "%Receita"
            }
            const cellA14 = worksheetFCProposto.getCell("A14")
            cellA14.value = "RENDA ATIVA"
            cellA14.font = {
                bold: true
            }
            cellA14.alignment = {
                horizontal: "center", vertical: "middle"
            }
            const totalRendaAtivaMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            //soma valores MES
            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                totalRendaAtivaMes.mes1 = totalRendaAtivaMes.mes1 + resposta.data.ativos[i].mes1;
                totalRendaAtivaMes.mes2 = totalRendaAtivaMes.mes2 + resposta.data.ativos[i].mes2;
                totalRendaAtivaMes.mes3 = totalRendaAtivaMes.mes3 + resposta.data.ativos[i].mes3;
                totalRendaAtivaMes.mes4 = totalRendaAtivaMes.mes4 + resposta.data.ativos[i].mes4;
                totalRendaAtivaMes.mes5 = totalRendaAtivaMes.mes5 + resposta.data.ativos[i].mes5;
                totalRendaAtivaMes.mes6 = totalRendaAtivaMes.mes6 + resposta.data.ativos[i].mes6;
                totalRendaAtivaMes.mes7 = totalRendaAtivaMes.mes7 + resposta.data.ativos[i].mes7;
                totalRendaAtivaMes.mes8 = totalRendaAtivaMes.mes8 + resposta.data.ativos[i].mes8;
                totalRendaAtivaMes.mes9 = totalRendaAtivaMes.mes9 + resposta.data.ativos[i].mes9;
                totalRendaAtivaMes.mes10 = totalRendaAtivaMes.mes10 + resposta.data.ativos[i].mes10;
                totalRendaAtivaMes.mes11 = totalRendaAtivaMes.mes11 + resposta.data.ativos[i].mes11;
                totalRendaAtivaMes.mes12 = totalRendaAtivaMes.mes12 + resposta.data.ativos[i].mes12;
            }
            //soma valores LINHA
            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                //soma os valores do mes para dar o total da linha
                const ativo = resposta.data.ativos[i]
                const total = ativo.mes1 + ativo.mes2 + ativo.mes3 + ativo.mes4 + ativo.mes5 + ativo.mes6 + ativo.mes7 + ativo.mes8 + ativo.mes9 + ativo.mes10 + ativo.mes11 + ativo.mes12
                worksheetFCProposto.getCell(`N${i + 15}`).value = formatarValor((total).toString())
                const totalReceita: any = worksheetFCProposto.getCell("N8")!.value
                worksheetFCProposto.getCell(`O${i + 15}`).value = ((total / parseFloat(totalReceita.replaceAll(".", "").replaceAll(",", "."))) * 100).toPrecision(2).toString()
            }
            console.log(resposta.data.ativos.length)
            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                const ativo = resposta.data.ativos[i]
                const cellA = worksheetFCProposto.getCell(`A${15 + i}`)
                cellA.value = ativo.label
                cellA.alignment = { horizontal: "center", vertical: "middle" }
                const cellB = worksheetFCProposto.getCell(`B${15 + i}`);
                cellB.value = formatarValor(ativo.mes1.toString());
                const cellC = worksheetFCProposto.getCell(`C${15 + i}`);
                cellC.value = formatarValor(ativo.mes2.toString());
                const cellD = worksheetFCProposto.getCell(`D${15 + i}`);
                cellD.value = formatarValor(ativo.mes3.toString());
                const cellE = worksheetFCProposto.getCell(`E${15 + i}`);
                cellE.value = formatarValor(ativo.mes4.toString());
                const cellF = worksheetFCProposto.getCell(`F${15 + i}`);
                cellF.value = formatarValor(ativo.mes5.toString())
                const cellG = worksheetFCProposto.getCell(`G${15 + i}`);
                cellG.value = formatarValor(ativo.mes6.toString());
                const cellH = worksheetFCProposto.getCell(`H${15 + i}`);
                cellH.value = formatarValor(ativo.mes7.toString());
                const cellI = worksheetFCProposto.getCell(`I${15 + i}`);
                cellI.value = formatarValor(ativo.mes8.toString());
                const cellJ = worksheetFCProposto.getCell(`J${15 + i}`);
                cellJ.value = formatarValor(ativo.mes9.toString());
                const cellK = worksheetFCProposto.getCell(`K${15 + i}`);
                cellK.value = formatarValor(ativo.mes10.toString());
                const cellL = worksheetFCProposto.getCell(`L${15 + i}`);
                cellL.value = formatarValor(ativo.mes11.toString());
                const cellM = worksheetFCProposto.getCell(`M${15 + i}`);
                cellM.value = formatarValor(ativo.mes12.toString());
                console.log(`M${15 + i}`)
            }
            //preenchendo totais
            const cellA_anyAtivo = worksheetFCProposto.getCell(`A${resposta.data.ativos.length + 15}`)
            cellA_anyAtivo.value = "Total Renda ativa"
            cellA_anyAtivo.font = {
                bold: true
            }
            cellA_anyAtivo.alignment = {
                vertical: "middle", horizontal: "center"
            }
            const cellB_anyTotalatv1 = worksheetFCProposto.getCell(`B${resposta.data.ativos.length + 15}`);
            cellB_anyTotalatv1.value = formatarValor(totalRendaAtivaMes.mes1.toString());
            const cellC_anyTotalatv2 = worksheetFCProposto.getCell(`C${resposta.data.ativos.length + 15}`);
            cellC_anyTotalatv2.value = formatarValor(totalRendaAtivaMes.mes2.toString());
            const cellD_anyTotalatv3 = worksheetFCProposto.getCell(`D${resposta.data.ativos.length + 15}`);
            cellD_anyTotalatv3.value = formatarValor(totalRendaAtivaMes.mes3.toString());
            const cellE_anyTotalatv4 = worksheetFCProposto.getCell(`E${resposta.data.ativos.length + 15}`);
            cellE_anyTotalatv4.value = formatarValor(totalRendaAtivaMes.mes4.toString());
            const cellF_anyTotalatv5 = worksheetFCProposto.getCell(`F${resposta.data.ativos.length + 15}`);
            cellF_anyTotalatv5.value = formatarValor(totalRendaAtivaMes.mes5.toString());
            const cellG_anyTotalatv6 = worksheetFCProposto.getCell(`G${resposta.data.ativos.length + 15}`);
            cellG_anyTotalatv6.value = formatarValor(totalRendaAtivaMes.mes6.toString());
            const cellH_anyTotalatv7 = worksheetFCProposto.getCell(`H${resposta.data.ativos.length + 15}`);
            cellH_anyTotalatv7.value = formatarValor(totalRendaAtivaMes.mes7.toString());
            const cellI_anyTotalatv8 = worksheetFCProposto.getCell(`I${resposta.data.ativos.length + 15}`);
            cellI_anyTotalatv8.value = formatarValor(totalRendaAtivaMes.mes8.toString());
            const cellJ_anyTotalatv9 = worksheetFCProposto.getCell(`J${resposta.data.ativos.length + 15}`);
            cellJ_anyTotalatv9.value = formatarValor(totalRendaAtivaMes.mes9.toString());
            const cellK_anyTotalatv10 = worksheetFCProposto.getCell(`K${resposta.data.ativos.length + 15}`);
            cellK_anyTotalatv10.value = formatarValor(totalRendaAtivaMes.mes10.toString());
            const cellL_anyTotalatv11 = worksheetFCProposto.getCell(`L${resposta.data.ativos.length + 15}`);
            cellL_anyTotalatv11.value = formatarValor(totalRendaAtivaMes.mes11.toString());
            const cellM_anyTotalatv12 = worksheetFCProposto.getCell(`M${resposta.data.ativos.length + 15}`);
            cellM_anyTotalatv12.value = formatarValor(totalRendaAtivaMes.mes12.toString());
            worksheetFCProposto.getCell(`N${resposta.data.ativos.length + 15}`).value = formatarValor((totalRendaAtivaMes.mes1 + totalRendaAtivaMes.mes2 + totalRendaAtivaMes.mes3 +
                totalRendaAtivaMes.mes4 + totalRendaAtivaMes.mes5 + totalRendaAtivaMes.mes6 +
                totalRendaAtivaMes.mes7 + totalRendaAtivaMes.mes8 + totalRendaAtivaMes.mes9 +
                totalRendaAtivaMes.mes10 + totalRendaAtivaMes.mes11 + totalRendaAtivaMes.mes12).toString())
            //gerar renda passiva
            const totalRendaPassivaMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                totalRendaPassivaMes.mes1 = totalRendaPassivaMes.mes1 + resposta.data.passivos[i].mes1;
                totalRendaPassivaMes.mes2 = totalRendaPassivaMes.mes2 + resposta.data.passivos[i].mes2;
                totalRendaPassivaMes.mes3 = totalRendaPassivaMes.mes3 + resposta.data.passivos[i].mes3;
                totalRendaPassivaMes.mes4 = totalRendaPassivaMes.mes4 + resposta.data.passivos[i].mes4;
                totalRendaPassivaMes.mes5 = totalRendaPassivaMes.mes5 + resposta.data.passivos[i].mes5;
                totalRendaPassivaMes.mes6 = totalRendaPassivaMes.mes6 + resposta.data.passivos[i].mes6;
                totalRendaPassivaMes.mes7 = totalRendaPassivaMes.mes7 + resposta.data.passivos[i].mes7;
                totalRendaPassivaMes.mes8 = totalRendaPassivaMes.mes8 + resposta.data.passivos[i].mes8;
                totalRendaPassivaMes.mes9 = totalRendaPassivaMes.mes9 + resposta.data.passivos[i].mes9;
                totalRendaPassivaMes.mes10 = totalRendaPassivaMes.mes10 + resposta.data.passivos[i].mes10;
                totalRendaPassivaMes.mes11 = totalRendaPassivaMes.mes11 + resposta.data.passivos[i].mes11;
                totalRendaPassivaMes.mes12 = totalRendaPassivaMes.mes12 + resposta.data.passivos[i].mes12;
            }
            //soma valores LINHA
            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                //soma os valores do mes para dar o total da linha
                const passivo = resposta.data.passivos[i]
                const total = passivo.mes1 + passivo.mes2 + passivo.mes3 + passivo.mes4 + passivo.mes5 + passivo.mes6 + passivo.mes7 + passivo.mes8 + passivo.mes9 + passivo.mes10 + passivo.mes11 + passivo.mes12
                worksheetFCProposto.getCell(`N${i + 17 + resposta.data.ativos.length}`).value = formatarValor((total).toString())
                const totalReceita: any = worksheetFCProposto.getCell("N8")!.value
                worksheetFCProposto.getCell(`O${i + 17 + resposta.data.ativos.length}`).value = ((total / parseFloat(totalReceita.replaceAll(".", "").replaceAll(",", "."))) * 100).toPrecision(2).toString()
            }
            const cellA_any = worksheetFCProposto.getCell(`A${resposta.data.ativos.length + 16}`)
            cellA_any.value = "RENDA PASSIVA"
            cellA_any.font = {
                bold: true
            }
            cellA_any.alignment = { horizontal: "center", vertical: "middle" }
            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                const passivo = resposta.data.passivos[i]
                const ultimoAtivo = resposta.data.ativos.length + 17
                const cellA = worksheetFCProposto.getCell(`A${ultimoAtivo + i}`)
                cellA.value = passivo.label
                cellA.alignment = { horizontal: "center", vertical: "middle" }
                const cellB = worksheetFCProposto.getCell(`B${ultimoAtivo + i}`);
                cellB.value = formatarValor(passivo.mes1.toString());
                const cellC = worksheetFCProposto.getCell(`C${ultimoAtivo + i}`);
                cellC.value = formatarValor(passivo.mes2.toString());
                const cellD = worksheetFCProposto.getCell(`D${ultimoAtivo + i}`);
                cellD.value = formatarValor(passivo.mes3.toString());
                const cellE = worksheetFCProposto.getCell(`E${ultimoAtivo + i}`);
                cellE.value = formatarValor(passivo.mes4.toString());
                const cellF = worksheetFCProposto.getCell(`F${ultimoAtivo + i}`);
                cellF.value = formatarValor(passivo.mes5.toString())
                const cellG = worksheetFCProposto.getCell(`G${ultimoAtivo + i}`);
                cellG.value = formatarValor(passivo.mes6.toString());
                const cellH = worksheetFCProposto.getCell(`H${ultimoAtivo + i}`);
                cellH.value = formatarValor(passivo.mes7.toString());
                const cellI = worksheetFCProposto.getCell(`I${ultimoAtivo + i}`);
                cellI.value = formatarValor(passivo.mes8.toString());
                const cellJ = worksheetFCProposto.getCell(`J${ultimoAtivo + i}`);
                cellJ.value = formatarValor(passivo.mes9.toString());
                const cellK = worksheetFCProposto.getCell(`K${ultimoAtivo + i}`);
                cellK.value = formatarValor(passivo.mes10.toString());
                const cellL = worksheetFCProposto.getCell(`L${ultimoAtivo + i}`);
                cellL.value = formatarValor(passivo.mes11.toString());
                const cellM = worksheetFCProposto.getCell(`M${ultimoAtivo + i}`);
                cellM.value = formatarValor(passivo.mes12.toString());
            }
            //preenchendo totais
            const cellA_anyPassivo = worksheetFCProposto.getCell(`A${resposta.data.ativos.length + resposta.data.passivos.length + 17}`)
            cellA_anyPassivo.value = "Total Renda Passiva"
            cellA_anyPassivo.font = {
                bold: true
            }
            cellA_anyPassivo.alignment = {
                vertical: "middle", horizontal: "center"
            }
            const cellB_anyTotalpassivo1 = worksheetFCProposto.getCell(`B${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellB_anyTotalpassivo1.value = formatarValor(totalRendaPassivaMes.mes1.toString());
            const cellC_anyTotalpassivo2 = worksheetFCProposto.getCell(`C${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellC_anyTotalpassivo2.value = formatarValor(totalRendaPassivaMes.mes2.toString());
            const cellD_anyTotalpassivo3 = worksheetFCProposto.getCell(`D${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellD_anyTotalpassivo3.value = formatarValor(totalRendaPassivaMes.mes3.toString());
            const cellE_anyTotalpassivo4 = worksheetFCProposto.getCell(`E${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellE_anyTotalpassivo4.value = formatarValor(totalRendaPassivaMes.mes4.toString());
            const cellF_anyTotalpassivo5 = worksheetFCProposto.getCell(`F${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellF_anyTotalpassivo5.value = formatarValor(totalRendaPassivaMes.mes5.toString());
            const cellG_anyTotalpassivo6 = worksheetFCProposto.getCell(`G${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellG_anyTotalpassivo6.value = formatarValor(totalRendaPassivaMes.mes6.toString());
            const cellH_anyTotalpassivo7 = worksheetFCProposto.getCell(`H${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellH_anyTotalpassivo7.value = formatarValor(totalRendaPassivaMes.mes7.toString());
            const cellI_anyTotalpassivo8 = worksheetFCProposto.getCell(`I${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellI_anyTotalpassivo8.value = formatarValor(totalRendaPassivaMes.mes8.toString());
            const cellJ_anyTotalpassivo9 = worksheetFCProposto.getCell(`J${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellJ_anyTotalpassivo9.value = formatarValor(totalRendaPassivaMes.mes9.toString());
            const cellK_anyTotalpassivo10 = worksheetFCProposto.getCell(`K${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellK_anyTotalpassivo10.value = formatarValor(totalRendaPassivaMes.mes10.toString());
            const cellL_anyTotalpassivo11 = worksheetFCProposto.getCell(`L${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellL_anyTotalpassivo11.value = formatarValor(totalRendaPassivaMes.mes11.toString());
            const cellM_anyTotalpassivo12 = worksheetFCProposto.getCell(`M${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
            cellM_anyTotalpassivo12.value = formatarValor(totalRendaPassivaMes.mes12.toString());
            worksheetFCProposto.getCell(`N${resposta.data.ativos.length + resposta.data.passivos.length + 17}`).value = formatarValor((totalRendaPassivaMes.mes1 + totalRendaPassivaMes.mes2 + totalRendaPassivaMes.mes3 +
                totalRendaPassivaMes.mes4 + totalRendaPassivaMes.mes5 + totalRendaPassivaMes.mes6 +
                totalRendaPassivaMes.mes7 + totalRendaPassivaMes.mes8 + totalRendaPassivaMes.mes9 +
                totalRendaPassivaMes.mes10 + totalRendaPassivaMes.mes11 + totalRendaPassivaMes.mes12).toString())
            //#region despesa
            const linha = 18 + resposta.data.ativos.length + resposta.data.passivos.length
            for (let i = 0; i < 1; i = i + 1) {
                worksheetFCProposto.getCell(`A${linha}`).value = "DESPESAS"
                worksheetFCProposto.getCell(`A${linha}`).alignment = { vertical: "middle", horizontal: "center" }
                worksheetFCProposto.getCell(`B${linha}`).value = meses![0].mes + "/" + meses![0].ano
                worksheetFCProposto.getCell(`C${linha}`).value = meses![1].mes + "/" + meses![1].ano
                worksheetFCProposto.getCell(`D${linha}`).value = meses![2].mes + "/" + meses![2].ano
                worksheetFCProposto.getCell(`E${linha}`).value = meses![3].mes + "/" + meses![3].ano
                worksheetFCProposto.getCell(`F${linha}`).value = meses![4].mes + "/" + meses![4].ano
                worksheetFCProposto.getCell(`G${linha}`).value = meses![5].mes + "/" + meses![5].ano
                worksheetFCProposto.getCell(`H${linha}`).value = meses![6].mes + "/" + meses![6].ano
                worksheetFCProposto.getCell(`I${linha}`).value = meses![7].mes + "/" + meses![7].ano
                worksheetFCProposto.getCell(`J${linha}`).value = meses![8].mes + "/" + meses![8].ano
                worksheetFCProposto.getCell(`K${linha}`).value = meses![9].mes + "/" + meses![9].ano
                worksheetFCProposto.getCell(`L${linha}`).value = meses![10].mes + "/" + meses![10].ano
                worksheetFCProposto.getCell(`M${linha}`).value = meses![11].mes + "/" + meses![11].ano
                worksheetFCProposto.getCell(`A${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' } // Cor de preenchimento (background) preto
                };

                worksheetFCProposto.getCell(`B${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`C${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`D${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`E${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`F${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`G${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`H${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`I${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`J${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`K${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`L${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`M${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`N${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };

                worksheetFCProposto.getCell(`O${linha}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dc3545' }
                };
                worksheetFCProposto.getCell(`A${linha}`).font = {
                    color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                    bold: true // Deixa em negrito
                };

                worksheetFCProposto.getCell(`B${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`C${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`D${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`E${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`F${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`G${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`H${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`I${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`J${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`K${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`L${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`M${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`N${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                };

                worksheetFCProposto.getCell(`O${linha}`).font = {
                    color: { argb: 'FFFFFFFF' },
                    bold: true
                }

                worksheetFCProposto.getCell(`N${linha} `).value = "Total"
                worksheetFCProposto.getCell(`O${linha}`).value = "%Receita"
            }
            //gerar despesa
            const totalDespesaMes = {
                mes1: 0,
                mes2: 0,
                mes3: 0,
                mes4: 0,
                mes5: 0,
                mes6: 0,
                mes7: 0,
                mes8: 0,
                mes9: 0,
                mes10: 0,
                mes11: 0,
                mes12: 0
            }
            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                totalDespesaMes.mes1 = totalDespesaMes.mes1 + resposta.data.despesas[i].mes1;
                totalDespesaMes.mes2 = totalDespesaMes.mes2 + resposta.data.despesas[i].mes2;
                totalDespesaMes.mes3 = totalDespesaMes.mes3 + resposta.data.despesas[i].mes3;
                totalDespesaMes.mes4 = totalDespesaMes.mes4 + resposta.data.despesas[i].mes4;
                totalDespesaMes.mes5 = totalDespesaMes.mes5 + resposta.data.despesas[i].mes5;
                totalDespesaMes.mes6 = totalDespesaMes.mes6 + resposta.data.despesas[i].mes6;
                totalDespesaMes.mes7 = totalDespesaMes.mes7 + resposta.data.despesas[i].mes7;
                totalDespesaMes.mes8 = totalDespesaMes.mes8 + resposta.data.despesas[i].mes8;
                totalDespesaMes.mes9 = totalDespesaMes.mes9 + resposta.data.despesas[i].mes9;
                totalDespesaMes.mes10 = totalDespesaMes.mes10 + resposta.data.despesas[i].mes10;
                totalDespesaMes.mes11 = totalDespesaMes.mes11 + resposta.data.despesas[i].mes11;
                totalDespesaMes.mes12 = totalDespesaMes.mes12 + resposta.data.despesas[i].mes12;
            }
            //soma valores LINHA
            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                //soma os valores do mes para dar o total da linha
                const despesa = resposta.data.despesas[i]
                const total = despesa.mes1 + despesa.mes2 + despesa.mes3 + despesa.mes4 + despesa.mes5 + despesa.mes6 + despesa.mes7 + despesa.mes8 + despesa.mes9 + despesa.mes10 + despesa.mes11 + despesa.mes12
                worksheetFCProposto.getCell(`N${i + linha + 1}`).value = formatarValor((total).toString())
                const totalDespesa: any = worksheetFCProposto.getCell("N9")!.value
                worksheetFCProposto.getCell(`O${i + linha + 1}`).value = ((total / parseFloat(totalDespesa.replaceAll(".", "").replaceAll(",", "."))) * 100).toString()
            }
            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                const despesa = resposta.data.despesas[i]
                const cellA = worksheetFCProposto.getCell(`A${linha + 1 + i}`)
                cellA.value = despesa.label
                cellA.alignment = { horizontal: "center", vertical: "middle" }
                const cellB = worksheetFCProposto.getCell(`B${linha + 1 + i}`);
                cellB.value = formatarValor(despesa.mes1.toString());
                const cellC = worksheetFCProposto.getCell(`C${linha + 1 + i}`);
                cellC.value = formatarValor(despesa.mes2.toString());
                const cellD = worksheetFCProposto.getCell(`D${linha + 1 + i}`);
                cellD.value = formatarValor(despesa.mes3.toString());
                const cellE = worksheetFCProposto.getCell(`E${linha + 1 + i}`);
                cellE.value = formatarValor(despesa.mes4.toString());
                const cellF = worksheetFCProposto.getCell(`F${linha + 1 + i}`);
                cellF.value = formatarValor(despesa.mes5.toString())
                const cellG = worksheetFCProposto.getCell(`G${linha + 1 + i}`);
                cellG.value = formatarValor(despesa.mes6.toString());
                const cellH = worksheetFCProposto.getCell(`H${linha + 1 + i}`);
                cellH.value = formatarValor(despesa.mes7.toString());
                const cellI = worksheetFCProposto.getCell(`I${linha + 1 + i}`);
                cellI.value = formatarValor(despesa.mes8.toString());
                const cellJ = worksheetFCProposto.getCell(`J${linha + 1 + i}`);
                cellJ.value = formatarValor(despesa.mes9.toString());
                const cellK = worksheetFCProposto.getCell(`K${linha + 1 + i}`);
                cellK.value = formatarValor(despesa.mes10.toString());
                const cellL = worksheetFCProposto.getCell(`L${linha + 1 + i}`);
                cellL.value = formatarValor(despesa.mes11.toString());
                const cellM = worksheetFCProposto.getCell(`M${linha + 1 + i}`);
                cellM.value = formatarValor(despesa.mes12.toString());
            }
            //preenchendo totais
            const cellA_anyDespesas = worksheetFCProposto.getCell(`A${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`)
            cellA_anyDespesas.value = "Total de Despesas"
            cellA_anyDespesas.font = {
                bold: true
            }
            cellA_anyDespesas.alignment = {
                vertical: "middle", horizontal: "center"
            }
            const cellB_anyTotaldespesa1 = worksheetFCProposto.getCell(`B${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellB_anyTotaldespesa1.value = formatarValor(totalDespesaMes.mes1.toString());
            const cellC_anyTotaldespesa2 = worksheetFCProposto.getCell(`C${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellC_anyTotaldespesa2.value = formatarValor(totalDespesaMes.mes2.toString());
            const cellD_anyTotaldespesa3 = worksheetFCProposto.getCell(`D${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellD_anyTotaldespesa3.value = formatarValor(totalDespesaMes.mes3.toString());
            const cellE_anyTotaldespesa4 = worksheetFCProposto.getCell(`E${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellE_anyTotaldespesa4.value = formatarValor(totalDespesaMes.mes4.toString());
            const cellF_anyTotaldespesa5 = worksheetFCProposto.getCell(`F${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellF_anyTotaldespesa5.value = formatarValor(totalDespesaMes.mes5.toString());
            const cellG_anyTotaldespesa6 = worksheetFCProposto.getCell(`G${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellG_anyTotaldespesa6.value = formatarValor(totalDespesaMes.mes6.toString());
            const cellH_anyTotaldespesa7 = worksheetFCProposto.getCell(`H${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellH_anyTotaldespesa7.value = formatarValor(totalDespesaMes.mes7.toString());
            const cellI_anyTotaldespesa8 = worksheetFCProposto.getCell(`I${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellI_anyTotaldespesa8.value = formatarValor(totalDespesaMes.mes8.toString());
            const cellJ_anyTotaldespesa9 = worksheetFCProposto.getCell(`J${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellJ_anyTotaldespesa9.value = formatarValor(totalDespesaMes.mes9.toString());
            const cellK_anyTotaldespesa10 = worksheetFCProposto.getCell(`K${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellK_anyTotaldespesa10.value = formatarValor(totalDespesaMes.mes10.toString());
            const cellL_anyTotaldespesa11 = worksheetFCProposto.getCell(`L${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellL_anyTotaldespesa11.value = formatarValor(totalDespesaMes.mes11.toString());
            const cellM_anyTotaldespesa12 = worksheetFCProposto.getCell(`M${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
            cellM_anyTotaldespesa12.value = formatarValor(totalDespesaMes.mes12.toString());
            worksheetFCProposto.getCell(`N${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`).value = formatarValor((totalDespesaMes.mes1 + totalDespesaMes.mes2 + totalDespesaMes.mes3 +
                totalDespesaMes.mes4 + totalDespesaMes.mes5 + totalDespesaMes.mes6 +
                totalDespesaMes.mes7 + totalDespesaMes.mes8 + totalDespesaMes.mes9 +
                totalDespesaMes.mes10 + totalDespesaMes.mes11 + totalDespesaMes.mes12).toString())
            // Gera o arquivo Excel em formato de blob
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            FileSaver.saveAs(blob, "FC Proposto.xlsx")
            set_carregando(false)
        }).catch(function (erro) {
            console.log(erro)
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }
    return (
        <>
            <div className="col">
                <ContainerTitulo titulo={"Fluxo de Caixa"} />
                <Card>
                    <Card.Body>
                        <TabContext value={guia}>
                            <TabList

                                onChange={capturaGuia}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#000",
                                        color: "#000",
                                    },
                                }}
                            >
                                <Tab label="Dados Básicos" value="1" />
                                <Tab label="FC Pessoal" value="2" />
                                <Tab label="Otimização" value="3" />
                                <Tab label="FC Proposto" value="4" />
                            </TabList>
                            <TabPanel value="1">
                                <Box sx={{ width: "100%" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col d-flex">
                                                <div className="me-3">
                                                    <InputSemBorda
                                                        name="fantasia"
                                                        type="text"

                                                        value={params.id_cliente || ""}
                                                        onChange={function () {

                                                        }}
                                                        readonly={true}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "

                                                        label="ID Cliente"
                                                    />
                                                </div>
                                                <div className="w-75">
                                                    <InputSemBorda
                                                        name="text"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={mascaraCPF(cpf)}
                                                        onChange={set_cpf}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="CPF"
                                                    />
                                                </div>

                                            </div>

                                            <div className="col d-flex">
                                                <div className="me-2">
                                                    <InputSemBorda
                                                        name="Data nasc."
                                                        type="date"
                                                        readonly={params.acao == "visualizar" ? true : false}
                                                        value={data_nascimento}
                                                        required
                                                        onChange={set_data_nascimento}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Data de nascimento"
                                                    />
                                                </div>
                                                <div className="w-75">
                                                    <InputSemBorda
                                                        name="nome"
                                                        type="text"
                                                        readonly={true}
                                                        value={nome}
                                                        required
                                                        onChange={set_nome}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Nome"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="e-mail"
                                                    type="text"
                                                    readonly={true}
                                                    value={email}
                                                    required
                                                    onChange={set_email}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="E-mail"
                                                />
                                            </div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="celular"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={mascaraTelefoneCelular(celular)}
                                                    onChange={set_celular}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Celular"
                                                />
                                            </div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="telefone"
                                                    type="text"
                                                    readonly={true}
                                                    value={mascaraTelefoneFixo(telefone)}
                                                    onChange={set_telefone}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Telefone"
                                                />
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col d-flex">
                                                <div className="me-3">
                                                    <InputSemBorda
                                                        name="cep"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={mascaraCEP(cep)}
                                                        onChange={set_cep}
                                                        onBlur={function () {
                                                            const cep_formatado = cep.replace(/[.\-/]/g, '')
                                                            set_cep(cep_formatado)
                                                        }}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Cep"
                                                    />
                                                </div>
                                                <div className="w-75">

                                                    <InputSemBorda
                                                        name="numero"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={numero}
                                                        onChange={set_numero}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Numero"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col">
                                                <InputSemBorda
                                                    name="complemento"
                                                    type="text"
                                                    readonly={true}
                                                    value={complemento}
                                                    onChange={set_complemento}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Complemento"
                                                />
                                            </div>
                                            <div className="col">

                                                <InputSemBorda
                                                    name="Logradouro"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={logradouro}
                                                    onChange={set_logradouro}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Logradouro"
                                                />

                                            </div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="bairro"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={bairro}
                                                    onChange={set_bairro}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Bairro"
                                                />
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="cidade"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={cidade}
                                                    onChange={set_cidade}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Cidade"
                                                />
                                            </div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="codmunicipio"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={codmunicipio}
                                                    onChange={set_codmunicipio}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Cód. Município"
                                                />
                                            </div>
                                            <div className="col">
                                                <FormControl
                                                    required
                                                    variant="standard"
                                                    sx={{ m: 1.5, width: "100%" }}
                                                >
                                                    <InputLabel>UF</InputLabel>
                                                    <Select
                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                        value={uf}
                                                        onChange={(e: SelectChangeEvent) =>
                                                            set_uf(e.target.value)
                                                        }
                                                        label="UF"
                                                        required
                                                        disabled={true}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Selecione...</em>
                                                        </MenuItem>
                                                        {dadosUF.map((item: any) => (
                                                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="Observação"
                                                    type="textarea"
                                                    readonly={true}
                                                    value={obs}
                                                    onChange={set_obs}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Observações"
                                                />
                                            </div>
                                            <div className="col-3">
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label">Ativo</label>
                                                    <input className="form-check-input w-25 me-1" disabled type="checkbox" id="flexSwitchCheckChecked" checked={status} onChange={function (e: any) {

                                                        set_status(e.target.checked)
                                                    }} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </TabPanel>
                            <TabPanel value="2">
                                <Box sx={{ width: "100%" }}>
                                    <Row>
                                        <SpeedDial
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            icon={<SpeedDialIcon />}
                                        >
                                            {actions.map((action: any) => (

                                                <SpeedDialAction
                                                    hidden={action.name == "Incluir" ? permissao_incluir : false}
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </Row>
                                    <Row>
                                        <div className="d-flex my-2 gap-1 ">
                                            <Col sm={11}>
                                                <InputSemBorda
                                                    name="fantasia"
                                                    type="text"
                                                    value={busca}
                                                    onChange={set_busca}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Procurar mês ano base"
                                                />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Tabela
                                            coluna={colunas}
                                            dados={ListaFluxosCaixas}
                                            itemsPerPage={10}
                                            onVisuClick={function (dado) {
                                                sessionStorage.setItem("mesanobase", dado.mesanobase)
                                                navigate(`/orgform/financeira/fluxocaixa/${params.id_cliente}/${dado.id_fc}/visualizar`)
                                            }}
                                            onEditClick={function (dado) {
                                                sessionStorage.setItem("mesanobase", dado.mesanobase)
                                                navigate(`/orgform/financeira/fluxocaixa/${params.id_cliente}/${dado.id_fc}/editar`)
                                            }}
                                            onDeleteClick={async function (dado) {
                                                await showConfirmationDialog(
                                                    "Excluir Fluxo Pessoal",
                                                    `Tem certeza que deseja excluir o fluxo?`,
                                                    "question"
                                                ).then(function (resposta) {
                                                    if (resposta.confirmed) {
                                                        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/fc/filial/${dado.id_fc}`, {
                                                            headers: {
                                                                Authorization: token
                                                            }
                                                        }).then(function (resp) {
                                                            toast.success(resp.data.message)
                                                            Search(busca)
                                                        }).catch(function (erro) {
                                                            toast.error(erro.response.data.message || erro.message)
                                                        })
                                                    }
                                                })
                                            }}
                                            onEnvio={function (dado) {
                                                ExportarFCPessoal(dado.id_fc)
                                            }}
                                            descEnvio={"Exportar .xls"}
                                            id="codigo"
                                            usaEnvio={true}
                                            iconeEnvio={mdiFileExcel}
                                            permissao_editar={permissao_editar}
                                            permissao_visualizar={permissao_visualizar}
                                            permissao_excluir={permissao_excluir}
                                            permissao_envio={false}
                                        />
                                    </Row>
                                </Box>
                            </TabPanel>
                            <TabPanel value="3">
                                <Box sx={{ width: "100%" }}>
                                    <Row>
                                        <SpeedDial
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            icon={<SpeedDialIcon />}
                                        >
                                            {actions2.map((action: any) => (

                                                <SpeedDialAction
                                                    hidden={action.name == "Incluir" ? permissao_incluir : false}
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </Row>
                                    <Row>
                                        <div className="d-flex my-2 gap-1 ">
                                            <Col sm={11}>
                                                <InputSemBorda
                                                    name="fantasia"
                                                    type="text"
                                                    value={buscaOtm}
                                                    onChange={set_buscaOtm}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Procurar mês ano base"
                                                />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Tabela
                                            coluna={colunasOtm}
                                            dados={ListaOtm}
                                            itemsPerPage={10}
                                            onVisuClick={function (dado) {
                                                navigate(`/orgform/financeira/otimizacao/${params.id_cliente}/${dado.id_otm}/visualizar/${dado.id_fc}?mesanobase=${dado.mesanobase}`)
                                            }}
                                            onEditClick={function (dado) {
                                                navigate(`/orgform/financeira/otimizacao/${params.id_cliente}/${dado.id_otm}/editar/${dado.id_fc}?mesanobase=${dado.mesanobase}`)
                                            }}
                                            onDeleteClick={async function (dado) {
                                                await showConfirmationDialog(
                                                    "Excluir Otimização",
                                                    `Tem certeza que deseja excluir a Otimização?`,
                                                    "question"
                                                ).then(function (resposta) {
                                                    if (resposta.confirmed) {
                                                        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/otm/permanente/${params.id_cliente}/${dado.id_otm}`, {
                                                            headers: {
                                                                Authorization: token
                                                            }
                                                        }).then(function (resp) {
                                                            toast.success(resp.data.message)
                                                            SearchOtm(buscaOtm)
                                                        }).catch(function (erro) {
                                                            toast.error(erro.response.data.message || erro.message)
                                                        })
                                                    }
                                                })
                                            }}
                                            onEnvio={function (dado) {
                                                ExportarOtimizacao(dado.id_otm, dado)
                                            }}
                                            descEnvio={"Exportar .xls"}
                                            id="codigo"
                                            usaEnvio={true}
                                            iconeEnvio={mdiFileExcel}
                                            permissao_editar={permissao_editar}
                                            permissao_visualizar={permissao_visualizar}
                                            permissao_excluir={permissao_excluir}
                                            permissao_envio={false}
                                        />
                                    </Row>
                                </Box>
                            </TabPanel>
                            <TabPanel value="4">
                                <Box sx={{ width: "100%" }}>
                                    <Row>
                                        <SpeedDial
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            icon={<SpeedDialIcon />}
                                        >
                                            {actions3.map((action: any) => (

                                                <SpeedDialAction
                                                    hidden={action.name == "Incluir" ? permissao_incluir : false}
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </Row>
                                    <Row>
                                        <div className="d-flex my-2 gap-1 ">
                                            <Col sm={11}>
                                                <InputSemBorda
                                                    name="fantasia"
                                                    type="text"
                                                    value={buscafcproposto}
                                                    onChange={set_buscafcproposto}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Procurar mês ano base"
                                                />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Tabela
                                            coluna={colunasFcproposto}
                                            dados={ListaFCProposto}
                                            itemsPerPage={10}
                                            onVisuClick={function (dado) {
                                                sessionStorage.setItem("mesanobase", dado.mesanobase)
                                                navigate(`/orgform/financeira/fcproposto/${params.id_cliente}/${dado.id_otm}/visualizar/${dado.id_fc}`)
                                            }}
                                            onEditClick={function (dado) {
                                                sessionStorage.setItem("mesanobase", dado.mesanobase)
                                                navigate(`/orgform/financeira/fcproposto/${params.id_cliente}/${dado.id_otm}/editar/${dado.id_fc}`)
                                            }}
                                            onDeleteClick={async function (dado) {
                                                await showConfirmationDialog(
                                                    "Excluir FC Proposto",
                                                    `Tem certeza que deseja excluir o FC proposto?`,
                                                    "question"
                                                ).then(function (resposta) {
                                                    if (resposta.confirmed) {
                                                        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/fc/propsoto/${dado.id_fc_proposto}`, {
                                                            headers: {
                                                                Authorization: token
                                                            }
                                                        }).then(function (resp) {
                                                            toast.success(resp.data.message)
                                                            SearchFCPropsoto(buscafcproposto)
                                                        }).catch(function (erro) {
                                                            toast.error(erro.response.data.message || erro.message)
                                                        })
                                                    }
                                                })
                                            }}
                                            onEnvio={function (dado) {
                                                ExportarFCProposto(dado.id_fc_proposto)
                                            }}
                                            descEnvio={"Exportar .xls"}
                                            id="codigo"
                                            usaEnvio={true}
                                            iconeEnvio={mdiFileExcel}
                                            permissao_editar={true}
                                            permissao_visualizar={permissao_visualizar}
                                            permissao_excluir={permissao_excluir}
                                            permissao_envio={false}
                                        />
                                    </Row>
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Card.Body>
                </Card>
            </div>
            <ModalLoading show={carregando} />
        </>
    )
}

export default NovoFluxoCaixaList