import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { mdiCloudUpload, mdiFileExcel, mdiPlus } from "@mdi/js";
import { useDropzone } from 'react-dropzone'
import Dropzone from 'react-dropzone'
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import Tabela from "../../../../components/Formularios/Table";
import AddIcon from "@mui/icons-material/Add";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
    mdiDownload
} from "@mdi/js";

import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../../../hooks/mascaras";
import { getSessionData } from "../../../../utils/storageUtils";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../../../components/dadosFixos/UF";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useCallback } from "react";
import FileSaver, { saveAs } from "file-saver";
import exceljs from "exceljs"


function FormOrgFinanceira() {

    const params = useParams()
    const navigate = useNavigate()
    const token = getSessionData("MultfilialWebToken")

    const [cpf, set_cpf] = useState("")
    const [nome, set_nome] = useState("")
    const [email, set_email] = useState("")
    const [celular, set_celular] = useState("")
    const [telefone, set_telefone] = useState("")
    const [data_nascimento, set_data_nascimento] = useState("19-02-2004")
    const [cep, set_cep] = useState("")
    const [logradouro, set_logradouro] = useState("")
    const [complemento, set_complemento] = useState("")
    const [numero, set_numero] = useState("")
    const [bairro, set_bairro] = useState("")
    const [cidade, set_cidade] = useState("")
    const [codmunicipio, set_codmunicipio] = useState("")
    const [uf, set_uf] = useState("")
    const [obs, set_obs] = useState("")
    const [status, set_status] = useState(true)
    const [prioridade, set_prioridade] = useState("")

    //---comp

    //modal loading
    const [carregando, set_carregando] = useState(false)

    const [dadosCliente, setDadosCliente] = useState<any>([])
    function carregarCliente(id_cliente: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cadastro/cliente/${id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {

                set_carregando(false)
                if (resposta.data.codigo != 200) {

                    toast.error(resposta.data.message)
                }
                else {
                    setDadosCliente(resposta.data.cliente[0])
                    const data = resposta.data.cliente[0].dtnascimento.split("T")
                    set_data_nascimento(data[0])

                    set_cpf(resposta.data.cliente[0].cpf)
                    set_nome(resposta.data.cliente[0].nome)
                    set_telefone(resposta.data.cliente[0].telfixo)
                    set_celular(resposta.data.cliente[0].telcelular)
                    set_email(resposta.data.cliente[0].email)
                    set_logradouro(resposta.data.cliente[0].logradouro)
                    set_numero(resposta.data.cliente[0].numero)
                    set_bairro(resposta.data.cliente[0].bairro)
                    set_complemento(resposta.data.cliente[0].compl)
                    set_codmunicipio(resposta.data.cliente[0].cnum)
                    set_cep(resposta.data.cliente[0].cep)
                    set_obs(resposta.data.cliente[0].observacao)
                    set_uf(resposta.data.cliente[0].cuf)
                    set_cidade(resposta.data.cliente[0].cidade)
                    set_status(resposta.data.cliente[0].status == 0 ? false : true)
                    set_prioridade(resposta.data.cliente[0].prioridade)
                    sessionStorage.setItem("dataNascimento", data[0])
                }
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }


    //#region Controla as guias
    const [guia, setGuia] = useState("1");
    const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
        setGuia(novaGuia);
    };

    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={function () {
                        navigate(`/org/analiseperfil/list/${params.id_cliente}/novo/0`)
                    }}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    //#region INICIO ANALISE PERFIL
    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_perfil" },
        { titulo: "Mês/Ano Base", acesso: "mesanobase" }
    ]

    const [buscaAnalisePerfil, set_buscaAnalisePerfil] = useState("")
    const [ListaAnalisePerfil, setListaAnalisePerfil] = useState<any>([])
    async function GetAnalisePerfil(id_cliente: any, id_filial: any) {

        try {
            set_carregando(true)
            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/todas/analisedeperfil/${id_cliente}/${id_filial}`, {
                headers: {
                    Authorization: token
                }
            })
            set_carregando(false)
            if (resposta.data.codigo == 200) {

                setListaAnalisePerfil(resposta.data.analise_perfil)
            }
            else {

                toast.error(resposta.data.message)
            }
        } catch (error: any) {
            set_carregando(false)
            toast.error(error)
        }
    }

    async function VisualizarAnalisePerfil(dado: any) {

        navigate(`/org/analiseperfil/list/${params.id_cliente}/visualizar/${dado.id_perfil}`)
    }

    async function ExcluirAnalisePerfil(dado: any) {

        try {

            await showConfirmationDialog("Excluir Analise de Perfil ?",
                "Confirmar?", "question").then(async function (resposta) {

                    if (resposta.confirmed) {
                        set_carregando(true)
                        const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/analisedeperfil/${dado.id_perfil}/${dado.id_cliente}`, {
                            headers: {
                                Authorization: token
                            }
                        })
                        set_carregando(false)
                        toast.success(resposta.data)
                        GetAnalisePerfil(params.id_cliente, getSessionData("DadosIdfilial"))
                    }
                })
        } catch (error: any) {

            toast.error(error)
        }
    }

    async function EditarAnalisePerfil(dado: any) {

        navigate(`/org/analiseperfil/list/${params.id_cliente}/editar/${dado.id_perfil}`)
    }

    function SearchAnalisePerfil(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {

            GetAnalisePerfil(params.id_cliente, getSessionData("DadosIdfilial"))
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = ListaAnalisePerfil.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.mesanobase.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)
            })


            setListaAnalisePerfil(resultados)
        }
    }

    useEffect(function () {

        SearchAnalisePerfil(buscaAnalisePerfil)
    }, [buscaAnalisePerfil])

    //#region FIM ANALISE PERFIL

    //#region INICIO ANALISE RISCOS
    const [ListaAnaliseDeRiscos, set_ListaAnaliseDeRiscos] = useState([])
    const [buscaAnaliseRisco, set_buscaAnaliseRisco] = useState("")

    function SearchAnaliseRisco(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {

            GetAnaliseRisco(params.id_cliente, getSessionData("DadosIdfilial"))
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = ListaAnaliseDeRiscos.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.mesanobase.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)
            })


            set_ListaAnaliseDeRiscos(resultados)
        }
    }

    async function GetAnaliseRisco(id_cliente: any, id_filial: any) {

        try {
            set_carregando(true)
            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/analisederiscos/all/${id_filial}/${id_cliente}`, {
                headers: {
                    Authorization: token
                }
            })
            set_ListaAnaliseDeRiscos(resposta.data)
            set_carregando(false)

        } catch (error: any) {
            set_carregando(false)
            toast.error(error.response.data || error.responde.statusText)
        }
    }

    async function VisualizarAnaliseRisco(dado: any) {

        navigate(`/org/analiserisco/list/${params.id_cliente}/visualizar/${dado.id_riscos}`)
    }

    async function ExcluirAnaliseRisco(dado: any) {

        try {

            await showConfirmationDialog("Excluir Analise de Riscos ?",
                "Confirmar?", "question").then(async function (resposta) {

                    if (resposta.confirmed) {
                        set_carregando(true)
                        const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/analisederisco/${dado.id_riscos}/${dado.id_cliente}`, {
                            headers: {
                                Authorization: token
                            }
                        })
                        set_carregando(false)
                        toast.success(resposta.data)
                        GetAnaliseRisco(params.id_cliente, getSessionData("DadosIdfilial"))
                    }
                })
        } catch (error: any) {

            toast.error(error)
        }
    }

    async function EditarAnaliseRisco(dado: any) {

        navigate(`/org/analiserisco/list/${params.id_cliente}/editar/${dado.id_riscos}`)
    }

    useEffect(function () {

        SearchAnaliseRisco(buscaAnaliseRisco)
    }, [buscaAnaliseRisco])

    const actions2: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={function () {
                        navigate(`/org/analiserisco/list/${params.id_cliente}/novo/0`)
                    }}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]


    const colunas2: ITabela[] = [
        { titulo: "ID", acesso: "id_riscos" },
        { titulo: "Mês/Ano Base", acesso: "mesanobase" }
    ]


    //#region FIM ANALISE RISCOS

    //#region INICIO ANALISE SUCESSAO
    const [ListaSucessao, set_ListaSucessao] = useState([])
    const [buscaSucessao, set_buscaSucessao] = useState("")

    function SearchSucessao(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {

            GetSucessao(params.id_cliente, getSessionData("DadosIdfilial"))
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = ListaSucessao.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.mesanobase.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)
            })


            set_ListaSucessao(resultados)
        }
    }

    async function GetSucessao(id_cliente: any, id_filial: any) {

        try {
            set_carregando(true)
            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/sucessao/all/${id_filial}/${id_cliente}`, {
                headers: {
                    Authorization: token
                }
            })
            set_ListaSucessao(resposta.data)
            set_carregando(false)

        } catch (error: any) {
            set_carregando(false)
            toast.error(error.response.data || error.responde.statusText)
        }
    }

    async function VisualizarSucessao(dado: any) {

        navigate(`/org/sucessao/list/${params.id_cliente}/visualizar/${dado.id_sucessao}`)
    }

    async function ExcluirSucessao(dado: any) {

        try {

            await showConfirmationDialog("Excluir Sucessão ?",
                "Confirmar?", "question").then(async function (resposta) {

                    if (resposta.confirmed) {
                        set_carregando(true)
                        const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/sucessao/${dado.id_sucessao}/${dado.id_cliente}`, {
                            headers: {
                                Authorization: token
                            }
                        })
                        set_carregando(false)
                        toast.success(resposta.data)
                        GetSucessao(params.id_cliente, getSessionData("DadosIdfilial"))
                    }
                })
        } catch (error: any) {

            toast.error(error)
        }
    }

    async function EditarSucessao(dado: any) {

        navigate(`/org/sucessao/list/${params.id_cliente}/editar/${dado.id_sucessao}`)
    }

    useEffect(function () {

        SearchSucessao(buscaSucessao)
    }, [buscaSucessao])

    const actions3: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={function () {
                        navigate(`/org/sucessao/list/${params.id_cliente}/novo/0`)
                    }}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]


    const colunas3: ITabela[] = [
        { titulo: "ID", acesso: "id_sucessao" },
        { titulo: "Mês/Ano Base", acesso: "mesanobase" }
    ]


    //#region INICIO INDEPENDENCIA FINANCEIRA
    const [ListaIndpFinanceira, set_ListaIndpFinanceira] = useState([])
    const [buscaIndpFinanceira, set_buscaIndpFinanceira] = useState("")

    function SearchIndpFinanceira(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {

            CarregarIndpFinanceiras(params.id_cliente!, getSessionData("DadosIdfilial")!)
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = ListaIndpFinanceira.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.mesanobase.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)
            })


            set_ListaIndpFinanceira(resultados)
        }
    }

    useEffect(function () {

        SearchIndpFinanceira(buscaIndpFinanceira)
    }, [buscaIndpFinanceira])


    function CarregarIndpFinanceiras(id_cliente: string, id_filial: string) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/todas/indepn/financeiras/${id_cliente}/${id_filial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            set_carregando(false)
            set_ListaIndpFinanceira(resposta.data.independenciasFinanceiras)
        }).catch(function (erro) {
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }

    async function ExcluirIndpFinanceiras(dado: any) {

        try {

            await showConfirmationDialog("Excluir Independênca Financeira ?",
                "Confirmar?", "question").then(async function (resposta) {

                    if (resposta.confirmed) {
                        set_carregando(true)
                        const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/indep/${dado.id_indepenfin}`, {
                            headers: {
                                Authorization: token
                            }
                        })
                        set_carregando(false)
                        toast.success(resposta.data.message)
                        CarregarIndpFinanceiras(params.id_cliente!, getSessionData("DadosIdfilial")!)
                    }
                })
        } catch (error: any) {

            toast.error(error)
        }
    }

    async function Editarindep(dado: any) {

        navigate(`/org/independencia/list/${params.id_cliente}/editar/${dado.id_indepenfin}`)
    }

    async function Visualizarindep(dado: any) {

        navigate(`/org/independencia/list/${params.id_cliente}/visualizar/${dado.id_indepenfin}`)
    }

    const actions5: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={function () {
                        navigate(`/org/independencia/list/${params.id_cliente}/novo/0`)
                    }}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    const colunas5: ITabela[] = [
        { titulo: "ID", acesso: "id_indepenfin" },
        { titulo: "Mês/Ano Base", acesso: "mesanobase" }
    ]

    //#region INICIO DOCUMENTOS
    const [ListaTiposDoc, set_ListaTiposDoc] = useState([])
    const [ListaPrioridade, set_Prioridade] = useState([])
    function ListarCODSistema(tabela: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`)
            .then(function (resposta) {

                if (tabela == "TIPDOCUM") {

                    set_ListaTiposDoc(resposta.data)
                }
                else if (tabela == "PRIORIDADE") {

                    set_Prioridade(resposta.data)
                }
                else if (tabela == "PERFIL") {

                    set_PerfilGeral(resposta.data)
                }
                else if (tabela == "PERFILRESP") {

                    set_Resposta(resposta.data)
                }
                else if (tabela == "RISCOS") {

                    set_ListaEvento(resposta.data)
                }
                else if (tabela == "SEGUROS") {

                    set_ListaSeguradora(resposta.data)
                }
                else if (tabela == "NACIONALIDADE") {

                    set_ListaNacionalidade(resposta.data)
                }
                else if (tabela == "ESTADOCIVIL") {

                    set_ListaEstadoCivil(resposta.data)
                }
                else if (tabela == "PARENTESCO") {

                    set_ListaParentesco(resposta.data)
                }
                set_carregando(false)
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }


    const [ListaDocumentos, setListaDocumentos] = useState([])
    const dropzoneStyles: React.CSSProperties = {
        border: '2px dashed #ffa500 ',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: '20px',
    };

    const colunas4: ITabela[] = [
        { titulo: "ID", acesso: "id_documento" },
        { titulo: "Tipo", acesso: "tipo_doc" },
        { titulo: "Nome", acesso: "nome_documento" },
        { titulo: "Descrição", acesso: "descricao" }
    ]


    async function visulizarArquivo(dado: any) {

        const byteCharacters = atob(dado.arquivo_base64.split(",")[1])
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: dado.arquivo_base64.split(";")[0].split(":")[1] })

        FileSaver.saveAs(blob, dado.nome_documento)
    }

    async function excluirArquivo(dado: any) {

        try {

            await showConfirmationDialog("Excluir Arquivo ?",
                "Confirmar?", "question").then(async function (resposta) {

                    if (resposta.confirmed) {
                        set_carregando(true)
                        const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/docs/${dado.id_cliente}/${dado.id_filial}/${dado.id_documento}`, {
                            headers: {
                                Authorization: token
                            }
                        })
                        set_carregando(false)
                        toast.success(resposta.data)
                        CarregarDocs(params.id_cliente, getSessionData("DadosIdfilial"))
                    }
                })
        } catch (error: any) {

            toast.error(error.response || error.statusText || error)
        }
    }

    const [tipo_doc, set_tipo_doc] = useState("")
    const [nome_documento, set_nome_documento] = useState("")
    const [descricao_documento, set_descricao_documento] = useState("")
    const [arquivoBase64, set_arquivoBase64] = useState<any>("")

    function ImportarArquivo(file: any) {

        set_nome_documento(file.name.split('.')[0])
        set_tipo_doc(file.name.split('.')[1])

        if (file) {
            const reader = new FileReader()
            reader.onloadend = function () {

                set_arquivoBase64(reader.result)
            }

            reader.readAsDataURL(file)
        }
    }

    async function InserirArquivoNoBanco() {

        try {
            set_carregando(true)
            const dados = {
                tipo_doc,
                nome_documento,
                descricao_documento,
                arquivoBase64,
                usuario_criacao: getSessionData("DadosNomefilial")
            }

            const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/inserir/arquivo/${params.id_cliente}/${getSessionData("DadosIdfilial")}`, dados, {
                headers: {
                    Authorization: token
                }
            })
            set_tipo_doc("")
            set_nome_documento("")
            set_descricao_documento("")
            set_arquivoBase64("")
            const input = document.querySelector("#FilesFiles") as HTMLInputElement
            input.value = ""
            toast.success(resposta.data)
            set_carregando(false)
            CarregarDocs(params.id_cliente, getSessionData("DadosIdfilial"))
        } catch (error: any) {

            set_carregando(false)
            toast.error(error.response || error.statusText || error)
        }
    }

    async function CarregarDocs(id_cliente: any, id_filial: any) {

        try {
            set_carregando(true)
            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/docs/${id_cliente}/${id_filial}`, {
                headers: {
                    Authorization: token
                }
            })
            set_carregando(false)
            setListaDocumentos(resposta.data)
        } catch (error: any) {
            set_carregando(false)
            toast.error(error.response || error.statusText || error)
        }
    }

    function AtualizarPrioridade() {

        const dados = {
            prioridade
        }
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/prioridade/${params.id_cliente}/${getSessionData("DadosIdfilial")}`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {

            toast.success(resposta.data)
            setTimeout(() => {
                navigate(-1)
            }, 2000);
        }).catch(function (erro) {

            toast.error(erro.responde.data || erro.statusText)
        })
    }

    useEffect(function () {

        carregarCliente(params.id_cliente)
        ListarCODSistema("TIPDOCUM")
        ListarCODSistema("PRIORIDADE")
        ListarCODSistema("PERFIL")
        ListarCODSistema("PERFILRESP")
        ListarCODSistema("RISCOS")
        ListarCODSistema("SEGUROS")
        ListarCODSistema("NACIONALIDADE")
        ListarCODSistema("ESTADOCIVIL")
        ListarCODSistema("PARENTESCO")
        CarregarDocs(params.id_cliente, getSessionData("DadosIdfilial"))
    }, [])



    //#region - EXPORTAR EXCEL - ANALISE DE PERFIL - INICIO


    //LISTA DE ARRAY - CODSISTEMA
    const [ListaPerfilGeral, set_PerfilGeral] = useState([])
    const [ListaResposta, set_Resposta] = useState([])



    function ExportarAnaliseDePerfil(dados: any) {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/analisedeperfil/${dados.id_perfil}/${dadosCliente.id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta: any) {

            try {
                const workbookAnaliseDePerfil = new exceljs.Workbook()

                const analisedeperfil = workbookAnaliseDePerfil.addWorksheet("Analise de Perfil")

                const A1 = analisedeperfil.getCell("B1")
                A1.value = "ANALISE DE PERFIL"

                const A2 = analisedeperfil.getCell("B2")
                A2.value = dadosCliente.cod_cliente + " - " + dadosCliente.nome + " - " + dadosCliente.cpf

                const columA = analisedeperfil.getColumn("A")
                columA.width = 15
                columA.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columB = analisedeperfil.getColumn("B")
                columB.width = 45
                columB.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columC = analisedeperfil.getColumn("C")
                columC.width = 45
                columC.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columD = analisedeperfil.getColumn("D")
                columD.width = 55
                columD.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                //fazendo o bg branco
                for (let i = 1; i < 60; i = i + 1) {

                    analisedeperfil.getCell(`A${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`B${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`C${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`D${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`E${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`F${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`G${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`H${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`I${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`J${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`K${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`L${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`M${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`N${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`O${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`P${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }
                }


                A1.font = {
                    bold: true,
                    size: 14
                }

                const rowB = analisedeperfil.getRow(2)
                rowB.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }
                const rowC = analisedeperfil.getRow(3)
                rowC.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                //cabecalho
                const row4 = analisedeperfil.getRow(4)
                row4.font = {
                    bold: true
                }

                const A4 = analisedeperfil.getCell("A4")
                A4.value = "#"
                A4.border = {
                    bottom: { style: "thin" }
                }

                const B4 = analisedeperfil.getCell("B4")
                B4.value = "PERFIL GERAL"
                B4.border = {
                    bottom: { style: "thin" }
                }

                const C4 = analisedeperfil.getCell("C4")
                C4.value = "RESPOSTA"
                C4.border = {
                    bottom: { style: "thin" }
                }

                const D4 = analisedeperfil.getCell("D4")
                D4.value = "COMENTÁRIO"
                D4.border = {
                    bottom: { style: "thin" }
                }


                //preenchendo informações
                for (let i = 1; i <= 18; i = i + 1) {

                    let row = i + 4
                    let celB: any = ListaPerfilGeral.find(function (perfil: any) {

                        return perfil.value == resposta.data.analise_perfil[0][i < 10 ? `codperfil_0${i}` : `codperfil_${i}`]
                    })

                    let celC: any = ListaResposta.find(function (resposta_: any) {

                        return resposta_.value == resposta.data.analise_perfil[0][i < 10 ? `codresposta_0${i}` : `codresposta_${i}`]
                    })

                    analisedeperfil.getCell(`A${row}`).value = i
                    analisedeperfil.getCell(`B${row}`).value = celB != undefined ? celB.label : ""
                    analisedeperfil.getCell(`C${row}`).value = celC != undefined ? celC.label : ""
                    analisedeperfil.getCell(`D${row}`).value = resposta.data.analise_perfil[0][i < 10 ? `comentario_0${i}` : `comentario_${i}`] || ""
                }

                //analisedeperfil.getCell("")

                workbookAnaliseDePerfil.xlsx.writeBuffer().then(function (bufferd: any) {

                    set_carregando(false)
                    const blob = new Blob([bufferd], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    FileSaver.saveAs(blob, "AnaliseDePerfil.xlsx")
                }).catch(function (erro: any) {

                    set_carregando(false)
                    toast("Erro ao converter blob: " + erro.message)
                })
            } catch (error: any) {
                console.log(error)
                set_carregando(false)
                toast.error("Erro ao criar excel: " + error.message || error)
            }
        }).catch(function (erro) {

            toast.error("Erro ao carregar Analise de Perfil: " + erro.message || erro.statusText || erro.message)
        })
    }
    //#region - EXPORTAR EXCEL - ANALISE DE PERFIL - FIM


    //#region - EXPORTAR EXCEL - ANALISE DE RISCOS - INICIO
    const [ListaEvento, set_ListaEvento] = useState([])
    const [ListaSeguradora, set_ListaSeguradora] = useState([])
    function ExportarAnaliseDeRiscos(dados: any) {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/analisederisco/${dados.id_riscos}/${dadosCliente.id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta: any) {

            try {
                const workbookAnaliseDeRiscos = new exceljs.Workbook()

                const analisedeperfil = workbookAnaliseDeRiscos.addWorksheet("Analise de Riscos")

                const A1 = analisedeperfil.getCell("B1")
                A1.value = "ANALISE DE RISCOS"

                const A2 = analisedeperfil.getCell("B2")
                A2.value = dadosCliente.cod_cliente + " - " + dadosCliente.nome + " - " + dadosCliente.cpf

                const columA = analisedeperfil.getColumn("A")
                columA.width = 15
                columA.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columB = analisedeperfil.getColumn("B")
                columB.width = 45
                columB.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columC = analisedeperfil.getColumn("C")
                columC.width = 45
                columC.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columD = analisedeperfil.getColumn("D")
                columD.width = 55
                columD.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                //fazendo o bg branco
                for (let i = 1; i < 60; i = i + 1) {

                    analisedeperfil.getCell(`A${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`B${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`C${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`D${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`E${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`F${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`G${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`H${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`I${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`J${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`K${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`L${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`M${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`N${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`O${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    analisedeperfil.getCell(`P${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }
                }


                A1.font = {
                    bold: true,
                    size: 14
                }

                const rowB = analisedeperfil.getRow(2)
                rowB.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }
                const rowC = analisedeperfil.getRow(3)
                rowC.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                //cabecalho
                const row4 = analisedeperfil.getRow(4)
                row4.font = {
                    bold: true
                }

                const A4 = analisedeperfil.getCell("A4")
                A4.value = "#"
                A4.border = {
                    bottom: { style: "thin" }
                }

                const B4 = analisedeperfil.getCell("B4")
                B4.value = "EVENTO - CLIENTE"
                B4.border = {
                    bottom: { style: "thin" }
                }

                const C4 = analisedeperfil.getCell("C4")
                C4.value = "SEGURADORA"
                C4.border = {
                    bottom: { style: "thin" }
                }

                const D4 = analisedeperfil.getCell("D4")
                D4.value = "COMENTÁRIO"
                D4.border = {
                    bottom: { style: "thin" }
                }

                //preenchendo informações
                for (let i = 1; i <= 18; i = i + 1) {

                    let row = i + 4
                    let celB: any = ListaEvento.find(function (evento: any) {

                        return evento.value == resposta.data[0][i < 10 ? `codevento_cliente_0${i}` : `codevento_cliente_${i}`]
                    })

                    let celC: any = ListaSeguradora.find(function (seguradora: any) {

                        return seguradora.value == resposta.data[0][i < 10 ? `codseguradora_cliente_0${i}` : `codseguradora_cliente_${i}`]
                    })

                    analisedeperfil.getCell(`A${row}`).value = i
                    analisedeperfil.getCell(`B${row}`).value = celB != undefined ? celB.label : ""
                    analisedeperfil.getCell(`C${row}`).value = celC != undefined ? celC.label : ""
                    analisedeperfil.getCell(`D${row}`).value = resposta.data[0][i < 10 ? `custocliente_0${i}` : `custocliente_${i}`] || ""
                }

                workbookAnaliseDeRiscos.xlsx.writeBuffer().then(function (bufferd: any) {

                    const blob = new Blob([bufferd], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    FileSaver.saveAs(blob, "AnaliseDeRiscos.xlsx")
                    set_carregando(false)
                }).catch(function (erro: any) {

                    set_carregando(false)
                    toast("Erro ao converter blob: " + erro.message)
                })
            } catch (error: any) {

                set_carregando(false)
                toast.error("Erro ao criar excel: " + error.message || error)
            }
        }).catch(function (erro) {

            toast.error("Erro ao carregar Analise de Perfil: " + erro.message || erro.statusText || erro.message)
        })
    }
    //#region - EXPORTAR EXCEL - ANALISE DE RISCOS - FIM


    //#region - EXPORTAR SUCESSÃO - INICIO
    const [ListaNacionalidade, set_ListaNacionalidade] = useState([])
    const [ListaEstadoCivil, set_ListaEstadoCivil] = useState([])
    const [ListaParentesco, set_ListaParentesco] = useState([])

    function exportarExcelSucessao(dado: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/sucessao/${dado.id_sucessao}/${dadosCliente.id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {

            try {
                const workbookSucessao = new exceljs.Workbook()

                const worksheetSucessao = workbookSucessao.addWorksheet("Sucessão")

                const A1 = worksheetSucessao.getCell("B1")
                A1.value = "SUCESSÃO"

                const A2 = worksheetSucessao.getCell("B2")
                A2.value = dadosCliente.cod_cliente + " - " + dadosCliente.nome + " - " + dadosCliente.cpf

                const columA = worksheetSucessao.getColumn("A")
                columA.width = 35
                columA.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columB = worksheetSucessao.getColumn("B")
                columB.width = 45
                columB.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columC = worksheetSucessao.getColumn("C")
                columC.width = 35
                columC.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columD = worksheetSucessao.getColumn("D")
                columD.width = 35
                columD.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                const columE = worksheetSucessao.getColumn("E")
                columE.width = 35
                columE.alignment = {
                    vertical: 'bottom', horizontal: 'right'
                }

                //fazendo o bg branco
                for (let i = 1; i < 60; i = i + 1) {

                    worksheetSucessao.getCell(`A${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`B${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`C${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`D${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`E${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`F${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`G${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`H${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`I${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`J${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`K${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`L${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`M${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`N${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`O${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetSucessao.getCell(`P${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }
                }


                A1.font = {
                    bold: true,
                    size: 14
                }

                const rowB = worksheetSucessao.getRow(2)
                rowB.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }
                const rowC = worksheetSucessao.getRow(3)
                rowC.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                const estCivil: any = ListaEstadoCivil.find(function (estado: any) {
                    if (dadosCliente.estado_civil != null) {
                        return estado.value == dadosCliente.estado_civil
                    }
                    else {
                        return null
                    }
                })
                const estCivilComp: any = ListaEstadoCivil.find(function (estado: any) {
                    if (resposta.data[0].estadocivilcomp != null) {
                        return estado.value == resposta.data[0].estadocivilcomp
                    }
                    else {
                        return null
                    }
                })

                //cabecalho - 1
                const cellA4 = worksheetSucessao.getCell("A4")
                cellA4.value = "Premissas"
                cellA4.font = {
                    bold: true
                }
                cellA4.border = {
                    bottom: {
                        style: "thin"
                    }
                }

                const cellB4 = worksheetSucessao.getCell("B4")
                cellB4.value = "Cliente"
                cellB4.font = {
                    bold: true
                }
                cellB4.border = {
                    bottom: {
                        style: "thin"
                    }
                }

                const cellC4 = worksheetSucessao.getCell("C4")
                cellC4.value = "Companheiro(a)"
                cellC4.font = {
                    bold: true
                }
                cellC4.border = {
                    bottom: {
                        style: "thin"
                    }
                }

                const cellA5 = worksheetSucessao.getCell("A5")
                cellA5.value = "Estado Civil"
                const cellB5 = worksheetSucessao.getCell("B5")
                cellB5.value = estCivil == null ? "" : estCivil!.label
                const cellC5 = worksheetSucessao.getCell("C5")
                cellC5.value = estCivilComp == null ? "" : estCivilComp!.label


                const cellA6 = worksheetSucessao.getCell("A6")
                cellA6.value = "Numero de filhos comuns"
                const cellB6 = worksheetSucessao.getCell("B6")
                cellB6.value = resposta.data[0].numfilhoscomum_cliente
                const cellC6 = worksheetSucessao.getCell("C6")
                cellC6.value = resposta.data[0].numfilhoscomum_comp

                const cellA7 = worksheetSucessao.getCell("A7")
                cellA7.value = "Numero de filhos particulares"
                const cellB7 = worksheetSucessao.getCell("B7")
                cellB7.value = resposta.data[0].numfilhospart_cliente
                const cellC7 = worksheetSucessao.getCell("C7")
                cellC7.value = resposta.data[0].numfilhospart_comp

                const cellA8 = worksheetSucessao.getCell("A8")
                cellA8.value = "Mãe"
                const cellB8 = worksheetSucessao.getCell("B8")
                cellB8.value = resposta.data[0].maeviva_cliente == 1 ? "Sim" : "Não"
                const cellC8 = worksheetSucessao.getCell("C8")
                cellC8.value = resposta.data[0].nmaeviva_comp == 1 ? "Sim" : "Não"

                const cellA9 = worksheetSucessao.getCell("A9")
                cellA9.value = "Pai"
                const cellB9 = worksheetSucessao.getCell("B9")
                cellB9.value = resposta.data[0].paivivo_cliente == 1 ? "Sim" : "Não"
                const cellC9 = worksheetSucessao.getCell("C9")
                cellC9.value = resposta.data[0].paivivo_comp == 1 ? "Sim" : "Não"

                //cabecalho 2
                const cellA11 = worksheetSucessao.getCell("A11")
                cellA11.value = "DEPENDENTE"
                cellA11.border = {
                    "bottom": {
                        "style": "thin"
                    }
                }

                const cellB11 = worksheetSucessao.getCell("B11")
                cellB11.value = "DATA DE NASCIMENTO"
                cellB11.border = {
                    "bottom": {
                        "style": "thin"
                    }
                }

                const cellC11 = worksheetSucessao.getCell("C11")
                cellC11.value = "NACIONALIDADE"
                cellC11.border = {
                    "bottom": {
                        "style": "thin"
                    }
                }

                const cellD11 = worksheetSucessao.getCell("D11")
                cellD11.value = "ESTADO CIVIL"
                cellD11.border = {
                    "bottom": {
                        "style": "thin"
                    }
                }

                const cellE11 = worksheetSucessao.getCell("E11")
                cellE11.value = "PARENTESCO"
                cellE11.border = {
                    "bottom": {
                        "style": "thin"
                    }
                }

                for (let i = 1; i < 5; i = i + 1) {

                    let row = i + 11
                    const dataNascimento = resposta.data[0][i < 10 ? `dep0${i}_nascimento` : `dep0${i}_nascimento`]
                        != null ? resposta.data[0][i < 10 ? `dep0${i}_nascimento` : `dep0${i}_nascimento`].split("T")[0].split("-") : ""
                    const dataNascimentoFormatada = dataNascimento.length > 0 ? (dataNascimento[2] + '-' + dataNascimento[1] + "-" + dataNascimento[0]).replaceAll("-", "/") : dataNascimento

                    worksheetSucessao.getCell(`A${row}`).value = resposta.data[0][i < 10 ? `dep0${i}_nome` : `dep${i}_nome`]
                    worksheetSucessao.getCell(`B${row}`).value = dataNascimentoFormatada

                    const nacionalidade: any = ListaNacionalidade.find(function (nacionalidade: any) {

                        return nacionalidade.value == resposta.data[0][i < 10 ? `dep0${i}_nacionalidade` : `dep${i}_nacionalidade`]
                    })

                    worksheetSucessao.getCell(`C${row}`).value = nacionalidade ? nacionalidade.label : ""

                    const EstadoCivil: any = ListaEstadoCivil.find(function (estado: any) {

                        return estado.value == resposta.data[0][i < 10 ? `dep0${i}_estadocivil` : `dep${i}_estadocivil`]
                    })

                    worksheetSucessao.getCell(`D${row}`).value = EstadoCivil ? EstadoCivil.label : ""

                    const parente: any = ListaParentesco.find(function (parente: any) {

                        return parente.value == resposta.data[0][i < 10 ? `dep0${i}_grauparentesco` : `dep${i}_grauparentesco`]
                    })

                    worksheetSucessao.getCell(`E${row}`).value = parente ? parente.label : ""
                }

                workbookSucessao.xlsx.writeBuffer().then(function (bufferd: any) {

                    const blob = new Blob([bufferd], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    FileSaver.saveAs(blob, "Sucessao.xlsx")
                    set_carregando(false)
                }).catch(function (erro: any) {

                    set_carregando(false)
                    toast("Erro ao converter blob: " + erro.message)
                })
            } catch (error: any) {
                set_carregando(false)
                toast.error("Erro ao carregar Sucessão: " + error.message || error)
            }
        }).catch(function (erro) {

            toast.error(erro.response.data || erro.statusText || erro.message)
        })
    }

    //#region - EXPORTAR SUCESSÃO - FIM

    //#region - exportar Independencia financeira -- INICIO
    async function ExportarIndpFinanceira(dados: any) {
        set_carregando(true)
        try {
            const workBookIndep = new exceljs.Workbook()
            const worksheetIndep = workBookIndep.addWorksheet("Independência Financeira")
            try {
                const alfabeto = [
                    "A",
                    "B",
                    "C",
                    "D",
                    "E",
                    "F",
                    "G",
                    "H",
                    "I",
                    "J",
                    "K",
                    "L",
                    "M",
                    "N",
                    "O",
                    "P",
                    "Q",
                    "R",
                    "S",
                    "T",
                    "U",
                    "V",
                    "W",
                    "X",
                    "Y",
                    "Z"
                ]
                //fazendo o bg branco
                for (let i = 1; i < 60; i = i + 1) {

                    worksheetIndep.getCell(`A${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`B${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`C${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`D${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`E${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`F${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`G${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`H${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`I${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`J${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`K${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`L${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`M${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`N${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`O${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }

                    worksheetIndep.getCell(`P${[i]}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFF' }
                    }
                }
                const columA = worksheetIndep.getColumn("A")
                columA.width = 55
                const columB = worksheetIndep.getColumn("B")
                columB.width = 20
                const columC = worksheetIndep.getColumn("C")
                columC.width = 35
                const celA1 = worksheetIndep.getCell("A1")
                celA1.alignment = { vertical: 'middle', horizontal: 'center' }
                celA1.border = {
                    bottom: {
                        style: "thin"
                    }
                }
                celA1.font = {
                    bold: true
                }
                celA1.value = "Projeções de Independência Financeira Plena"

                const celA2 = worksheetIndep.getCell("A2")
                celA2.value = "Renda futura almejada em valor presente"
                celA2.alignment = { vertical: "middle", horizontal: "right" }

                const celB2 = worksheetIndep.getCell("B2")
                celB2.value = dados.rendafutura || ""
                celB2.alignment = { vertical: "middle", horizontal: "center" }

                const celA3 = worksheetIndep.getCell("A3")
                celA3.value = "Idade Atual"
                celA3.alignment = { vertical: "middle", horizontal: "right" }

                const celB3 = worksheetIndep.getCell("B3")
                celB3.value = parseInt(dados.idadeAtual) || ""
                celB3.alignment = { vertical: "middle", horizontal: "center" }

                const celC3 = worksheetIndep.getCell("C3")
                celC3.value = dados.coment_07 || ""
                celC3.alignment = { vertical: "middle", horizontal: "center" }

                const celA4 = worksheetIndep.getCell("A4")
                celA4.value = "Idade Alvo"
                celA4.alignment = { vertical: "middle", horizontal: "right" }

                const celB4 = worksheetIndep.getCell("B4")
                celB4.value = dados.idadealvo || ""
                celB4.alignment = { vertical: "middle", horizontal: "center" }

                const celC4 = worksheetIndep.getCell("C4")
                celC4.value = dados.coment_01 || ""
                celC4.alignment = { vertical: "middle", horizontal: "center" }


                const celA6 = worksheetIndep.getCell("A6")
                celA6.alignment = { vertical: 'middle', horizontal: 'center' }
                celA6.border = {
                    bottom: {
                        style: "thin"
                    }
                }
                celA6.font = {
                    bold: true
                }
                celA6.value = "Previsão de entradas futuras em valor presente"

                const celA7 = worksheetIndep.getCell("A7")
                celA7.value = "Previdência Social (INSS)"
                celA7.alignment = { vertical: "middle", horizontal: "right" }

                const celB7 = worksheetIndep.getCell("B7")
                celB7.value = dados.prevsocial || ""
                celB7.alignment = { vertical: "middle", horizontal: "center" }

                const celC7 = worksheetIndep.getCell("C7")
                celC7.value = dados.coment_02 || ""
                celC7.alignment = { vertical: "middle", horizontal: "center" }

                const celA8 = worksheetIndep.getCell("A8")
                celA8.value = "Previdência Complementar"
                celA8.alignment = { vertical: "middle", horizontal: "right" }


                const celB8 = worksheetIndep.getCell("B8")
                celB8.value = dados.prevcompl || ""
                celB8.alignment = { vertical: "middle", horizontal: "center" }

                const celC8 = worksheetIndep.getCell("C8")
                celC8.value = dados.coment_03 || ""
                celC8.alignment = { vertical: "middle", horizontal: "center" }

                const celA9 = worksheetIndep.getCell("A9")
                celA9.value = "Aluguéis"
                celA9.alignment = { vertical: "middle", horizontal: "right" }

                const celB9 = worksheetIndep.getCell("B9")
                celB9.value = dados.alugueis || ""
                celB9.alignment = { vertical: "middle", horizontal: "center" }

                const celC9 = worksheetIndep.getCell("C9")
                celC9.value = dados.coment_04 || ""
                celC9.alignment = { vertical: "middle", horizontal: "center" }

                const cel10 = worksheetIndep.getCell("A10")
                cel10.value = "Dividendos"
                cel10.alignment = { vertical: "middle", horizontal: "right" }

                const celB10 = worksheetIndep.getCell("B10")
                celB10.value = dados.dividendos || ""
                celB10.alignment = { vertical: "middle", horizontal: "center" }

                const celC10 = worksheetIndep.getCell("C10")
                celC10.value = dados.coment_05 || ""
                celC10.alignment = { vertical: "middle", horizontal: "center" }

                const cel11 = worksheetIndep.getCell("A11")
                cel11.value = "Outras entradas"
                cel11.alignment = { vertical: "middle", horizontal: "right" }

                const celB11 = worksheetIndep.getCell("B11")
                celB11.value = dados.outrasentradas || ""
                celB11.alignment = { vertical: "middle", horizontal: "center" }


                const celC11 = worksheetIndep.getCell("C11")
                celC11.value = dados.coment_06 || ""
                celC11.alignment = { vertical: "middle", horizontal: "center" }

                const celA12 = worksheetIndep.getCell("A12")
                celA12.value = "Total"
                celA12.alignment = { vertical: "middle", horizontal: "right" }

                const celA14 = worksheetIndep.getCell("A14")
                celA14.value = "Observações Gerais"
                celA14.alignment = { vertical: "middle", horizontal: "center" }
                celA14.font = {
                    bold: true
                }

                const celA15 = worksheetIndep.getCell("A15")
                celA15.alignment = { horizontal: "center" }
                celA15.value = dados.observacao

                const columD = worksheetIndep.getColumn("D")
                columD.width = 55
                const celD18 = worksheetIndep.getCell("D18")
                celD18.font = {
                    bold: true,
                    size: 15
                }
                celD18.alignment = { vertical: "middle", horizontal: "right" }
                celD18.value = dadosCliente.nome + " - " + dadosCliente.cpf + " - " + dadosCliente.cod_cliente

                const buffer = await workBookIndep.xlsx.writeBuffer()
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, "IndependenciaFinanceira.xlsx")
                set_carregando(false)
            } catch (error: any) {
                set_carregando(false)
                toast.error("Erro ao buffer do .xlsx:" + error.message || error)
            }
        } catch (error: any) {
            set_carregando(false)
            toast.error("Erro ao escrever .xslx: " + error.message || error)
        }
    }
    //#region - exportar Independencia financeira -- FIM


    //#region INICIO PREVIDENCIA
    const [ListaPrevidencia, set_ListaPrevidencia] = useState([])
    const [buscaPrevidencia, set_buscaPrevidencia] = useState("")

    function SearchPrevidencia(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {

            CarregarPrevidencias(params.id_cliente!, getSessionData("DadosIdfilial")!)
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = ListaPrevidencia.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.mesanobase.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)
            })


            set_ListaPrevidencia(resultados)
        }
    }

    useEffect(function () {

        SearchPrevidencia(buscaPrevidencia)
    }, [buscaPrevidencia])


    function CarregarPrevidencias(id_cliente: string, id_filial: string) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/todas/previdencias/${id_cliente}/${id_filial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            set_carregando(false)
            set_ListaPrevidencia(resposta.data.independencias)
        }).catch(function (erro) {
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }

    async function ExcluirPrevidencias(dado: any) {

        try {

            await showConfirmationDialog("Excluir Previdência Corporativa ?",
                "Confirmar?", "question").then(async function (resposta) {

                    if (resposta.confirmed) {
                        set_carregando(true)
                        const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/previdencia/${dado.id_previdencia}`, {
                            headers: {
                                Authorization: token
                            }
                        })
                        set_carregando(false)
                        toast.success(resposta.data.message)
                        CarregarPrevidencias(params.id_cliente!, getSessionData("DadosIdfilial")!)
                    }
                })
        } catch (error: any) {

            toast.error(error)
        }
    }

    async function Editarprevidencia(dado: any) {

        navigate(`/org/previdencia/list/${params.id_cliente}/editar/${dado.id_previdencia}`)
    }

    async function Visualizarprevidencia(dado: any) {

        navigate(`/org/previdencia/list/${params.id_cliente}/visualizar/${dado.id_previdencia}`)
    }

    const actions6: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={function () {
                        navigate(`/org/previdencia/list/${params.id_cliente}/novo/0`)
                    }}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    const colunas6: ITabela[] = [
        { titulo: "ID", acesso: "id_previdencia" },
        { titulo: "Mês/Ano Base", acesso: "mesanobase" }
    ]

    async function exportarExcelPrevidencia(dado: any) {
        set_carregando(true)
        console.log(dado)
        try {
            const workbookPrevidencia = new exceljs.Workbook()
            const worksheetPrevidencia = workbookPrevidencia.addWorksheet("Projeção de Ativos")
            const previdencia = dado
            //fazendo o bg branco
            for (let i = 1; i < 60; i = i + 1) {

                worksheetPrevidencia.getCell(`A${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`B${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`C${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`D${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`E${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`F${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`G${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`H${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`I${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`J${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`K${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`L${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`M${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`N${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`O${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheetPrevidencia.getCell(`P${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }
            }

            const columA = worksheetPrevidencia.getColumn("A")
            columA.width = 50
            const cellA1 = worksheetPrevidencia.getCell("A1")
            cellA1.font = {
                bold: true
            }
            cellA1.value = dadosCliente.nome + " - " + dadosCliente.cpf + " - " + dadosCliente.cod_cliente

            const columE = worksheetPrevidencia.getColumn("E")
            columE.width = 40

            const cellA3 = worksheetPrevidencia.getCell("A3")
            cellA3.font = {
                bold: true
            }
            cellA3.alignment = {
                "horizontal": "right",
                "vertical": "middle"
            }
            cellA3.border = {
                bottom: {
                    "style": "thin"
                }
            }
            cellA3.value = "Projeção de Ativos Financeiros"

            const cellA4 = worksheetPrevidencia.getCell("A4")
            cellA4.alignment = {
                "horizontal": "right",
                "vertical": "middle"
            }
            cellA4.value = "Custo de vida Mensal/extras anuais/Anual"

            const cellB4 = worksheetPrevidencia.getCell("B4")
            cellB4.alignment = {
                "horizontal": "center",
                "vertical": "middle"
            }
            cellB4.value = previdencia.custovidamensal

            const cellC4 = worksheetPrevidencia.getCell("C4")
            cellC4.alignment = {
                "horizontal": "center",
                "vertical": "middle"
            }
            cellC4.value = previdencia.extrasanuais

            const cellD4 = worksheetPrevidencia.getCell("D4")
            cellD4.alignment = {
                "horizontal": "center",
                "vertical": "middle"
            }
            cellD4.value = previdencia.custovidaanual

            const cellE4 = worksheetPrevidencia.getCell("E4")
            cellE4.alignment = {
                "horizontal": "center",
                "vertical": "middle"
            }
            cellE4.value = previdencia.coment_01

            const cellA5 = worksheetPrevidencia.getCell("A5")
            cellA5.alignment = {
                "horizontal": "right",
                "vertical": "middle"
            }
            cellA5.value = "Contribui com a previdência?"

            const cellB5 = worksheetPrevidencia.getCell("B5")
            cellB5.value = previdencia.prevcorp == 1 ? "Sim" : "Não"
            cellB5.alignment = {
                "horizontal": "center",
                "vertical": "middle"
            }

            const cellA6 = worksheetPrevidencia.getCell("A6")
            cellA6.alignment = {
                "horizontal": "right",
                "vertical": "middle"
            }
            cellA6.value = "Participante"

            const cellB6 = worksheetPrevidencia.getCell("B6")
            cellB6.alignment = {
                "horizontal": "center",
                "vertical": "middle"
            }
            cellB6.value = previdencia.vlrparticipante || ""

            const cellE6 = worksheetPrevidencia.getCell("E6")
            cellE6.alignment = {
                "horizontal": "center",
                "vertical": "middle"
            }
            cellE6.value = previdencia.coment_02

            const cellA7 = worksheetPrevidencia.getCell("A7")
            cellA7.alignment = {
                "horizontal": "right",
                "vertical": "middle"
            }
            cellA7.value = "Patrocinadora"

            const cellB7 = worksheetPrevidencia.getCell("B7")
            cellB7.alignment = {
                "horizontal": "center",
                "vertical": "middle"
            }
            cellB6.value = previdencia.vlrpatrocinador || ""

            const cellE7 = worksheetPrevidencia.getCell("E7")
            cellE7.alignment = {
                "horizontal": "center",
                "vertical": "middle"
            }
            cellE7.value = previdencia.coment_03

            const cellA9 = worksheetPrevidencia.getCell("A9")
            cellA9.value = "Observações gerais"
            cellA9.font = {
                bold: true
            }

            const cellA10 = worksheetPrevidencia.getCell("A10")
            cellA10.value = previdencia.observacao

            const row10 = worksheetPrevidencia.getRow(10)
            row10.height = 35

            try {
                const buffer = await workbookPrevidencia.xlsx.writeBuffer()
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, "ProjeçãoAtivos")
                set_carregando(false)
            } catch (error: any) {
                set_carregando(false)
                toast.error(error.message)
            }
        } catch (error: any) {
            set_carregando(false)
            toast.error(error.message)
        }
    }

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo={"Coleta Qualitativa"} />
                <Card>

                    <Card.Body>
                        <TabContext value={guia}>
                            <TabList

                                onChange={capturaGuia}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#000",
                                        color: "#000",
                                    },
                                }}
                            >
                                <Tab label="Dados Básicos" value="1" />
                                <Tab label="Analise de Perfil" value="2" />
                                <Tab label="Analise de Riscos" value="3" />
                                <Tab label="Sucessão" value="4" />
                                <Tab label="Independência Financeira" value="5" />
                                <Tab label="Projeção de Ativos" value="6" />
                                <Tab label="Documentos" value="7" />
                            </TabList>
                            <TabPanel value="1">
                                <Box sx={{ width: "100%" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col d-flex">
                                                <div className="me-3">
                                                    <InputSemBorda
                                                        name="fantasia"
                                                        type="text"

                                                        value={params.id_cliente || ""}
                                                        onChange={function () {

                                                        }}
                                                        readonly={true}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "

                                                        label="ID Cliente"
                                                    />
                                                </div>
                                                <div className="w-75">
                                                    <InputSemBorda
                                                        name="text"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={mascaraCPF(cpf)}
                                                        onChange={set_cpf}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="CPF"
                                                    />
                                                </div>

                                            </div>

                                            <div className="col d-flex">
                                                <div className="me-2">
                                                    <InputSemBorda
                                                        name="Data nasc."
                                                        type="date"
                                                        readonly={true}
                                                        value={data_nascimento}
                                                        required
                                                        onChange={set_data_nascimento}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Data de nascimento"
                                                    />
                                                </div>
                                                <div className="w-75">
                                                    <InputSemBorda
                                                        name="nome"
                                                        type="text"
                                                        readonly={true}
                                                        value={nome}
                                                        required
                                                        onChange={set_nome}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Nome"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="e-mail"
                                                    type="text"
                                                    readonly={true}
                                                    value={email}
                                                    required
                                                    onChange={set_email}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="E-mail"
                                                />
                                            </div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="celular"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={mascaraTelefoneCelular(celular)}
                                                    onChange={set_celular}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Celular"
                                                />
                                            </div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="telefone"
                                                    type="text"
                                                    readonly={true}
                                                    value={mascaraTelefoneFixo(telefone)}
                                                    onChange={set_telefone}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Telefone"
                                                />
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col d-flex">
                                                <div className="me-3">
                                                    <InputSemBorda
                                                        name="cep"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={mascaraCEP(cep)}
                                                        onChange={set_cep}
                                                        onBlur={function () {
                                                            const cep_formatado = cep.replace(/[.\-/]/g, '')
                                                            set_cep(cep_formatado)
                                                        }}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Cep"
                                                    />
                                                </div>
                                                <div className="w-75">

                                                    <InputSemBorda
                                                        name="numero"
                                                        type="text"
                                                        required
                                                        readonly={true}
                                                        value={numero}
                                                        onChange={set_numero}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Numero"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col">
                                                <InputSemBorda
                                                    name="complemento"
                                                    type="text"
                                                    readonly={true}
                                                    value={complemento}
                                                    onChange={set_complemento}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Complemento"
                                                />
                                            </div>
                                            <div className="col">

                                                <InputSemBorda
                                                    name="Logradouro"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={logradouro}
                                                    onChange={set_logradouro}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Logradouro"
                                                />

                                            </div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="bairro"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={bairro}
                                                    onChange={set_bairro}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Bairro"
                                                />
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="cidade"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={cidade}
                                                    onChange={set_cidade}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Cidade"
                                                />
                                            </div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="codmunicipio"
                                                    type="text"
                                                    required
                                                    readonly={true}
                                                    value={codmunicipio}
                                                    onChange={set_codmunicipio}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Cód. Município"
                                                />
                                            </div>
                                            <div className="col">
                                                <FormControl
                                                    required
                                                    variant="standard"
                                                    sx={{ m: 1.5, width: "100%" }}
                                                >
                                                    <InputLabel>UF</InputLabel>
                                                    <Select
                                                        readOnly={params.acao == "visualizar" ? true : false}
                                                        value={uf}
                                                        onChange={(e: SelectChangeEvent) =>
                                                            set_uf(e.target.value)
                                                        }
                                                        label="UF"
                                                        required
                                                        disabled={true}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Selecione...</em>
                                                        </MenuItem>
                                                        {dadosUF.map((item: any) => (
                                                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col">
                                                <InputSemBorda
                                                    name="Observação"
                                                    type="textarea"
                                                    readonly={true}
                                                    value={obs}
                                                    onChange={set_obs}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Observações"
                                                />
                                            </div>
                                            <div className="col-3">
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label">Ativo</label>
                                                    <input className="form-check-input w-25 me-1" disabled type="checkbox" id="flexSwitchCheckChecked" checked={status} onChange={function (e: any) {

                                                        set_status(e.target.checked)
                                                    }} />

                                                </div>
                                            </div>
                                            <div className="w-100"></div>
                                            <div className="col-sm col-md-4 col-lg-5">
                                                <div className="form-floating">
                                                    <select value={prioridade} className="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={function (e: any) {
                                                        set_prioridade(e.target.value)
                                                    }}>
                                                        <option selected value="">Selecione...</option>

                                                        {ListaPrioridade.map(function (item: any) {

                                                            return (
                                                                <>
                                                                    <option value={item.value}>{item.label}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </select>
                                                    <label>Prioridade do Projeto</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </TabPanel>
                            <TabPanel value="2">
                                <Box sx={{ width: "100%" }}>
                                    <Row>
                                        <SpeedDial
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            icon={<SpeedDialIcon />}
                                        >
                                            {actions.map((action: any) => (
                                                <SpeedDialAction
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </Row>
                                    <Row>
                                        <div className="d-flex my-2 gap-1 ">
                                            <Col sm={11}>
                                                <InputSemBorda
                                                    name="fantasia"
                                                    type="text"
                                                    value={buscaAnalisePerfil}
                                                    onChange={set_buscaAnalisePerfil}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Procurar mês ano base"
                                                />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Tabela
                                            coluna={colunas}
                                            dados={ListaAnalisePerfil}
                                            itemsPerPage={10}
                                            onVisuClick={VisualizarAnalisePerfil}
                                            onEditClick={EditarAnalisePerfil}
                                            onDeleteClick={ExcluirAnalisePerfil}
                                            onEnvio={ExportarAnaliseDePerfil}
                                            id="codigo"
                                            descEnvio="Exportar .xlsx"
                                            iconeEnvio={mdiFileExcel}
                                            usaEnvio={true}
                                            permissao_editar={false}
                                            permissao_visualizar={false}
                                            permissao_excluir={false}
                                            permissao_envio={false}
                                        />
                                    </Row>
                                </Box>
                            </TabPanel>
                            <TabPanel value="3">
                                <Box sx={{ width: "100%" }}>
                                    <Row>
                                        <SpeedDial
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            icon={<SpeedDialIcon />}
                                        >
                                            {actions2.map((action: any) => (
                                                <SpeedDialAction
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </Row>
                                    <Row>
                                        <div className="d-flex my-2 gap-1 ">
                                            <Col sm={11}>
                                                <InputSemBorda
                                                    name="fantasia"
                                                    type="text"
                                                    value={buscaAnaliseRisco}
                                                    onChange={set_buscaAnaliseRisco}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Procurar mês ano base"
                                                />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Tabela
                                            coluna={colunas2}
                                            dados={ListaAnaliseDeRiscos}
                                            itemsPerPage={10}
                                            onVisuClick={VisualizarAnaliseRisco}
                                            onEditClick={EditarAnaliseRisco}
                                            onDeleteClick={ExcluirAnaliseRisco}
                                            onEnvio={ExportarAnaliseDeRiscos}
                                            usaEnvio={true}
                                            descEnvio="Exportar .xlsx"
                                            iconeEnvio={mdiFileExcel}
                                            id="codigo"
                                            permissao_editar={false}
                                            permissao_visualizar={false}
                                            permissao_excluir={false}
                                            permissao_envio={false}
                                        />
                                    </Row>
                                </Box>

                            </TabPanel>
                            <TabPanel value="4">
                                <Box sx={{ width: "100%" }}>
                                    <Row>
                                        <SpeedDial
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            icon={<SpeedDialIcon />}
                                        >
                                            {actions3.map((action: any) => (
                                                <SpeedDialAction
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </Row>
                                    <Row>
                                        <div className="d-flex my-2 gap-1 ">
                                            <Col sm={11}>
                                                <InputSemBorda
                                                    name="fantasia"
                                                    type="text"
                                                    value={buscaSucessao}
                                                    onChange={set_buscaSucessao}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Procurar mês ano base"
                                                />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Tabela
                                            coluna={colunas3}
                                            dados={ListaSucessao}
                                            itemsPerPage={10}
                                            onVisuClick={VisualizarSucessao}
                                            onEditClick={EditarSucessao}
                                            onDeleteClick={ExcluirSucessao}
                                            onEnvio={exportarExcelSucessao}
                                            usaEnvio={true}
                                            iconeEnvio={mdiFileExcel}
                                            descEnvio="Exportar .xlsx"
                                            id="codigo"
                                            permissao_editar={false}
                                            permissao_visualizar={false}
                                            permissao_excluir={false}
                                            permissao_envio={false}
                                        />
                                    </Row>
                                </Box>
                            </TabPanel>
                            <TabPanel value="5">
                                <Box sx={{ width: "100%" }}>
                                    <Row>
                                        <SpeedDial
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            icon={<SpeedDialIcon />}
                                        >
                                            {actions5.map((action: any) => (
                                                <SpeedDialAction
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </Row>
                                    <Row>
                                        <div className="d-flex my-2 gap-1 ">
                                            <Col sm={11}>
                                                <InputSemBorda
                                                    name="fantasia"
                                                    type="text"
                                                    value={buscaIndpFinanceira}
                                                    onChange={set_buscaIndpFinanceira}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Procurar mês ano base"
                                                />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Tabela
                                            coluna={colunas5}
                                            dados={ListaIndpFinanceira}
                                            itemsPerPage={10}
                                            onVisuClick={Visualizarindep}
                                            onEditClick={Editarindep}
                                            onDeleteClick={ExcluirIndpFinanceiras}
                                            onEnvio={ExportarIndpFinanceira}
                                            usaEnvio={true}
                                            iconeEnvio={mdiFileExcel}
                                            descEnvio="Exportar .xlsx"
                                            id="codigo"
                                            permissao_editar={false}
                                            permissao_visualizar={false}
                                            permissao_excluir={false}
                                            permissao_envio={false}
                                        />
                                    </Row>
                                </Box>
                            </TabPanel>
                            <TabPanel value="6">
                                <Box sx={{ width: "100%" }}>
                                    <Row>
                                        <SpeedDial
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            icon={<SpeedDialIcon />}
                                        >
                                            {actions6.map((action: any) => (
                                                <SpeedDialAction
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </Row>
                                    <Row>
                                        <div className="d-flex my-2 gap-1 ">
                                            <Col sm={11}>
                                                <InputSemBorda
                                                    name="fantasia"
                                                    type="text"
                                                    value={buscaPrevidencia}
                                                    onChange={set_buscaPrevidencia}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput"
                                                    placeholder=" "
                                                    label="Procurar mês ano base"
                                                />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Tabela
                                            coluna={colunas6}
                                            dados={ListaPrevidencia}
                                            itemsPerPage={10}
                                            onVisuClick={Visualizarprevidencia}
                                            onEditClick={Editarprevidencia}
                                            onDeleteClick={ExcluirPrevidencias}
                                            onEnvio={exportarExcelPrevidencia}
                                            usaEnvio={true}
                                            iconeEnvio={mdiFileExcel}
                                            descEnvio="Exportar .xlsx"
                                            id="codigo"
                                            permissao_editar={false}
                                            permissao_visualizar={false}
                                            permissao_excluir={false}
                                            permissao_envio={false}
                                        />
                                    </Row>
                                </Box>
                            </TabPanel>
                            <TabPanel value="7">
                                <Box sx={{ width: "100%" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm col-md col-lg-6 m-auto">
                                                <div className="form-floating">
                                                    <select value={descricao_documento} required className="form-select" id="floatingSelect" aria-label="Floating label select example" onChange={function (e: any) {

                                                        set_descricao_documento(e.target.value)
                                                    }}>
                                                        <option selected value="">----</option>
                                                        {ListaTiposDoc.map(function (doc: any) {

                                                            return (
                                                                <option value={doc.label}>{doc.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <label>Descrição do documento</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="conatiner">

                                        <input type="file" name="" id="FilesFiles" style={dropzoneStyles} onChange={function (e: any) {

                                            ImportarArquivo(e.target.files[0])
                                        }} />
                                        <div className="row">
                                            <div className="col-sm col-md col-lg text-center">
                                                <button type="button" className="btn btn-secondary pt-0 pb-1" onClick={function () {

                                                    if (descricao_documento == "") {

                                                        toast.error("Selecione a descrição do documento.")
                                                    }
                                                    else if (arquivoBase64 == "") {

                                                        toast.error("Importe um arquivo.")
                                                    }
                                                    else {

                                                        //insert do documento no banco
                                                        InserirArquivoNoBanco()
                                                    }
                                                }}><i className="bi bi-download fs-3 me-1"></i>Salvar</button>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="container">
                                        <div className="row">
                                            <Row>
                                                <Tabela
                                                    coluna={colunas4}
                                                    dados={ListaDocumentos}
                                                    itemsPerPage={10}
                                                    iconeVisualizar={mdiDownload}
                                                    onVisuClick={visulizarArquivo}
                                                    onEditClick={function () {

                                                    }}
                                                    onDeleteClick={excluirArquivo}
                                                    id="codigo"
                                                    permissao_editar={true}
                                                    permissao_visualizar={false}
                                                    permissao_excluir={false}
                                                    permissao_envio={true}
                                                />
                                            </Row>
                                        </div>
                                    </div>
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={guia == '1' ? false : true}
                                disabled={params.acao == 'visualizar' ? true : false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                onclick={AtualizarPrioridade}
                                icone={mdiCheckCircle}
                            />
                        </div>
                    </Card.Footer>
                </Card>
                <ModalLoading show={carregando} />
            </div>
        </>
    )
}

export default FormOrgFinanceira