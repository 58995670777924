import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo"
import { Row, Col, Card } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import { getSessionData, setSessionData } from "../../utils/storageUtils";
import axios from "axios";
import { Button as ButtonCustom } from "../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import { mascaraCPF } from "../../hooks/mascaras";
import { toast } from "react-toastify";


function LoginCadastroExterno() {

    const navigate = useNavigate()
    const params = useParams()

    const [cpf, set_cpf] = useState("")
    const [codigo, set_codigo] = useState("")
    async function validar(e: any) {

        e.preventDefault()

        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/token/${codigo}/${cpf}/${params.token_cliente}`)
   
        if(resposta.data.codigo == 200){

            toast.success(resposta.data.message)
            navigate(`/complet/cad/${resposta.data.cliente[0].id_cliente}`)
        }
        else{

            toast.error(resposta.data.message)
        }
    }

    useEffect(function(){

        setSessionData("tokenPreCad", params.token_cliente)
    }, [])

    return (
        <>
            <div className="container-fluid mt-5">
                <div className="text-center">
                    <ContainerTitulo titulo="Login pré Cadastro" />
                </div>

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <form onSubmit={validar}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-sm-6 col-md col-lg-3">
                                                    <InputSemBorda
                                                        name="CPF"
                                                        type="text"
                                                        value={mascaraCPF(cpf)}
                                                        onChange={set_cpf}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        required
                                                        label="CPF"
                                                    />
                                                </div>
                                                <div className="col-sm-6  col-md col-lg-3">
                                                    <InputSemBorda
                                                        name="codigo"
                                                        type="text"
                                                        value={codigo}
                                                        required
                                                        onChange={set_codigo}
                                                        classNameInputsSemBorda="form-control bordasInferiorInput"
                                                        placeholder=" "
                                                        label="Cód. de verificação"
                                                    />

                                                </div>
                                                <div className="col-sm-6 col-md col-lg-2 text-center ">
                                                    <ButtonCustom
                                                        invisivel={false}
                                                        type="submit"
                                                        className="btn btn-light text-info-emphasis bg-white border-0 mt-3"
                                                        descricaoBotao="Verificar"
                                                        icone={mdiCheckCircle}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default LoginCadastroExterno