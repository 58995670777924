import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import ContainerTitulo from "../../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../../../../hooks/mascaras";
import { getSessionData } from "../../../../../utils/storageUtils";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MascaraValor from "../../../../../components/functions/mascaraValor";
import calcularIdade from "../../../../../hooks/calcIdade";
function IndependenciaFinanceira() {


    const id_filial = sessionStorage.getItem("DadosIdfilial")!
    const token = sessionStorage.getItem("MultfilialWebToken")!

    const params = useParams()
    const navigate = useNavigate()

    const [total, setTotal] = useState("")
    const [inputsIndpFinancera, setInputsIndpFinanceira] = useState({
        mesanobase: "",
        rendaAlmejada: "",
        idadeAlvo: "",
        coment1: "",
        inss: "",
        coment2: "",
        previdenciaComplementar: "",
        coment3: "",
        Alugueis: "",
        coment4: "",
        dividendos: "",
        coment5: "",
        outrasEntradas: "",
        coment6: "",
        observacoes: "",
        idadeAtual: "",
        coment7: ""
    })

    function CalcularNovoTotal() {
        try {
            const valores = {
                inss: isNaN(parseFloat(inputsIndpFinancera.inss.replace(".", "").replace(",", "."))) ? 0 : parseFloat(inputsIndpFinancera.inss.replace(".", "").replace(",", ".")),
                pvcomplementar: isNaN(parseFloat(inputsIndpFinancera.previdenciaComplementar.replace(".", "").replace(",", "."))) ? 0 : parseFloat(inputsIndpFinancera.previdenciaComplementar.replace(".", "").replace(",", ".")),
                alugueis: isNaN(parseFloat(inputsIndpFinancera.Alugueis.replace(".", "").replace(",", "."))) ? 0 : parseFloat(inputsIndpFinancera.Alugueis.replace(".", "").replace(",", ".")),
                dividendos: isNaN(parseFloat(inputsIndpFinancera.dividendos.replace(".", "").replace(",", "."))) ? 0 : parseFloat(inputsIndpFinancera.dividendos.replace(".", "").replace(",", ".")),
                outrasEntrada: isNaN(parseFloat(inputsIndpFinancera.outrasEntradas.replace(".", "").replace(",", "."))) ? 0 : parseFloat(inputsIndpFinancera.outrasEntradas.replace(".", "").replace(",", "."))
            }
            const NovoTotal = valores.inss + valores.pvcomplementar + valores.alugueis + valores.dividendos + valores.outrasEntrada
            setTotal(MascaraValor(NovoTotal))
        } catch (error) {
            console.error(error)
        }
    }

    const [modalMesAnoBase, set_modalMesAnoBase] = useState(false)
    const [mesanobase, set_mesanobase] = useState("")
    const [meses, set_meses] = useState<any>([])
    function gerarMeses() {
        const dataToday = new Date();
        const anoAtual = dataToday.getFullYear(); // Obtém o ano atual
        const mesAtual = dataToday.getMonth() + 1; // Obtém o mês atual (de 0 a 11)
        const datas = [];

        // Gera 3 meses para trás
        let ano = anoAtual;
        let mes = mesAtual;
        for (let i = 0; i < 3; i++) {
            mes--;
            if (mes < 1) {
                mes = 12;
                ano--;
            }
            datas.unshift({ mes: mes.toString().padStart(2, '0'), ano: ano }); // Adiciona ao início do array
        }

        // Reseta para o mês atual e ano
        ano = anoAtual;
        mes = mesAtual;

        // Gera 12 meses para frente
        for (let i = 0; i < 12; i++) {
            datas.push({ mes: mes.toString().padStart(2, '0'), ano: ano });
            mes++;
            if (mes > 12) {
                mes = 1;
                ano++;
            }
        }

        // Atualiza o estado com o array de datas
        set_meses(datas);
    }


    useEffect(function () {

        setInputsIndpFinanceira({ ...inputsIndpFinancera, mesanobase: mesanobase })
    }, [mesanobase])


    useEffect(function () {
        if (params.acao != 'novo') {
            carregarIndp(params.id_independencia!)
        }
        else {
            setInputsIndpFinanceira({
                ...inputsIndpFinancera,
                idadeAtual: calcularIdade(sessionStorage.getItem("dataNascimento")!)
            })
            set_modalMesAnoBase(true)
            gerarMeses()
        }
    }, [])

    useEffect(function () {
        if (params.acao != "novo") {
            CalcularNovoTotal()
        }
    }, [inputsIndpFinancera.mesanobase])

    const [carregando, set_carregando] = useState(false)

    function CriarNovaIndependencia(id_filial: string, id_cliente: string) {

        set_carregando(true)
        const dados = {
            inputsIndpFinancera,
            usuario: sessionStorage.getItem("NomeUsuario")
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/nova/independencia/${id_filial}/${id_cliente}`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            set_carregando(false)
            toast.success(resposta.data.message)
            setTimeout(() => {
                navigate(-1)
            }, 2000)
        }).catch(function (erro) {
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }

    function carregarIndp(id_independencia: string) {

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/indep/unica/${id_independencia}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            const independencia = resposta.data.indep
            setInputsIndpFinanceira({
                mesanobase: independencia.mesanobase,
                rendaAlmejada: MascaraValor(independencia.rendafutura),
                idadeAlvo: independencia.idadealvo,
                coment1: independencia.coment_01,
                inss: independencia.prevsocial == null ? "" : MascaraValor(independencia.prevsocial),
                coment2: independencia.coment_02,
                previdenciaComplementar: independencia.prevcompl == null ? "" : MascaraValor(independencia.prevcompl),
                coment3: independencia.coment_03,
                Alugueis: independencia.alugueis == null ? "" : MascaraValor(independencia.alugueis),
                coment4: independencia.coment_04,
                dividendos: independencia.dividendos == null ? "" : MascaraValor(independencia.dividendos),
                coment5: independencia.coment_05,
                outrasEntradas: independencia.outrasentradas == null ? "" : MascaraValor(independencia.outrasentradas),
                coment6: independencia.coment_06,
                observacoes: independencia.observacao,
                idadeAtual: calcularIdade(independencia.dtnascimento),
                coment7: independencia.coment_07
            })
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
        })
    }

    function atualizarIndp(id_independencia: string) {
        set_carregando(true)
        const dados = {
            inputsIndpFinancera
        }
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/indpn/${id_independencia}`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            set_carregando(false)
            toast.success(resposta.data.message)
            setTimeout(() => {
                navigate(-1)
            }, 2000);
        }).catch(function (erro) {
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }

    return (
        <>
            <ModalLoading show={carregando} />
            <ContainerTitulo titulo={params.acao == "novo" ? "Nova Independência " + inputsIndpFinancera.mesanobase : params.acao == "editar" ? "Editando Independência " + inputsIndpFinancera.mesanobase : "Visualizando Independência " + inputsIndpFinancera.mesanobase} />
            <Card>
                <form onSubmit={function (e) {
                    e.preventDefault()
                    if (params.acao == "novo") {
                        CriarNovaIndependencia(id_filial, params.id_cliente!)
                    }
                    else if (params.acao == "editar") {
                        atualizarIndp(params.id_independencia!)
                    }
                }}>
                    <Card.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm col-md col-lg-6">
                                    <p className="pb-0 border-bottom border-2 border-dark"><b className="d-block text-center">Projeções de Independência Financeira Plena</b></p>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3 mt-3">
                                    <p><b>Renda futura almejada em valor presente: </b></p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        required
                                        value={inputsIndpFinancera.rendaAlmejada}
                                        onBlur={function () {
                                            CalcularNovoTotal()
                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, rendaAlmejada: MascaraValor(inputsIndpFinancera.rendaAlmejada) })
                                        }}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, rendaAlmejada: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3 mt-3">
                                    <p><b>Idade Atual: </b></p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="idadeAlvo"
                                        required
                                        readonly={true}
                                        type="textarea"
                                        value={inputsIndpFinancera.idadeAtual}
                                        onChange={function (value) {
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-5">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={inputsIndpFinancera.coment7}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, coment7: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Observações"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3 mt-3">
                                    <p><b>Idade Alvo: </b></p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="idadeAlvo"
                                        required
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={inputsIndpFinancera.idadeAlvo}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, idadeAlvo: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-5">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={inputsIndpFinancera.coment1}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, coment1: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Observações"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-6">
                                    <p className="pb-0 border-bottom border-2 border-dark mt-3"><b className="d-block text-center">Previsão de entradas futuras em valor presente</b></p>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3 mt-3">
                                    <p><b>Previdência Social (INSS): </b></p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="inss"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={inputsIndpFinancera.inss}
                                        onBlur={function () {

                                            CalcularNovoTotal()
                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, inss: MascaraValor(inputsIndpFinancera.inss) })
                                        }}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, inss: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-5">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        value={inputsIndpFinancera.coment2}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, coment2: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Previsão de recebimento e observações"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3 mt-3">
                                    <p><b>Previdência Complementar: </b></p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="previdencia"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        onBlur={function () {
                                            CalcularNovoTotal()
                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, previdenciaComplementar: MascaraValor(inputsIndpFinancera.previdenciaComplementar) })
                                        }}
                                        value={inputsIndpFinancera.previdenciaComplementar}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, previdenciaComplementar: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-5">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"

                                        value={inputsIndpFinancera.coment3}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, coment3: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Previsão de recebimento e observações"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3 mt-3">
                                    <p><b>Aluguéis: </b></p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="previdencia"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={inputsIndpFinancera.Alugueis}
                                        onBlur={function () {
                                            CalcularNovoTotal()
                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, Alugueis: MascaraValor(inputsIndpFinancera.Alugueis) })
                                        }}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, Alugueis: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-5">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"

                                        value={inputsIndpFinancera.coment4}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, coment4: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Previsão de recebimento e observações"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3 mt-3">
                                    <p><b>Dividendos: </b></p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="previdencia"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={inputsIndpFinancera.dividendos}
                                        onBlur={function () {
                                            CalcularNovoTotal()
                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, dividendos: MascaraValor(inputsIndpFinancera.dividendos) })
                                        }}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, dividendos: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-5">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"

                                        value={inputsIndpFinancera.coment5}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, coment5: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Previsão de recebimento e observações"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3 mt-3">
                                    <p><b>Outras entradas: </b></p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="previdencia"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={inputsIndpFinancera.outrasEntradas}
                                        onBlur={function () {
                                            CalcularNovoTotal()
                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, outrasEntradas: MascaraValor(inputsIndpFinancera.outrasEntradas) })
                                        }}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, outrasEntradas: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-5">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"

                                        value={inputsIndpFinancera.coment6}
                                        onChange={function (value) {

                                            setInputsIndpFinanceira({ ...inputsIndpFinancera, coment6: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Previsão de recebimento e observações"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-3 mt-3">
                                    <p><b>Total: </b></p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="previdencia"
                                        readonly
                                        type="text"
                                        value={total}
                                        onChange={function () {


                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-10">
                                    <div className="col-sm col-md col-lg mt-3">
                                        <p><b>Observações gerais:</b></p>
                                    </div>
                                    <div className="form-floating">
                                        <textarea placeholder=" " className="form-control bordasInferiorInput text-dark"
                                            value={inputsIndpFinancera.observacoes}
                                            disabled={params.acao == 'visualizar' ? true : false}
                                            onChange={function (e) {
                                                setInputsIndpFinanceira({ ...inputsIndpFinancera, observacoes: e.target.value })
                                            }}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={false}
                                disabled={params.acao == 'visualizar' ? true : false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                            />
                        </div>
                    </Card.Footer>
                </form>
            </Card >


            <Modal show={modalMesAnoBase}>

                <Modal.Header className="bg-white">
                    <Modal.Title>Mês/Ano Base da Independência Financeira a ser criada.</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Defina o mês/ano do inicio do Fluxo a ser criado.</h4>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select name="" id="" onChange={function (e) {
                                    const data = e.target.value.split("-")
                                    set_mesanobase(data[1] + "/" + data[0])
                                }} className="select-control bordasInferiorInput w-100">
                                    <option value="" selected></option>
                                    {meses.map(function (data: any) {

                                        return <option className="text-center" value={`${data.ano}-${data.mes}`}>{`${data.mes}/${data.ano}`}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Cancelar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        set_modalMesAnoBase(false)
                                        navigate(-1);
                                    }}
                                />
                            </div>
                            <div className="col text-end">
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' ? true : false}
                                    type="button"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Continuar"
                                    onclick={function () {
                                        set_modalMesAnoBase(false)
                                    }}
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default IndependenciaFinanceira