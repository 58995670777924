import { useState } from "react";
import { Modal } from "react-bootstrap";
import { TbExchange } from "react-icons/tb";
import { ModalTrocarSenha } from "./ModalTrocarSenha";
import { Button } from "../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ApiPut from "../../../../../api/endPoints/usePut";
import { getSessionData } from "../../../../../utils/storageUtils";
import { toast } from "react-toastify";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";

export const ModalTrocarSenhaLink: React.FC = () => {
  const token = getSessionData("MultfilialWebToken") || "";
  const [showModal, setShowModal] = useState(false);
  const fecharModal = () => setShowModal(false);
  const abrirModal = () => setShowModal(true);

  const [ loading, setLoading ] = useState<boolean>(false);
  //#region Modal Trocar Senha
  const alterarSenha = (
    id_usuario: string,
    senha: string,
    novasenha: string
  ) => {
    setLoading(true);
    const dados = {
      id_usuario,
      senha,
      novasenha,
    };
    ApiPut(`/usuario/alterarsenha/${id_usuario}`, dados, token)
      .then((response) => {
        setLoading(false);
        toast.success(response.message);
        fecharModal();
      })
      .catch((erro) => {
        setLoading(false);        
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao alterar senha. Motivo: ${msg}`);
      });
  };

  //#endregion
  const handleSubmit = (dadosSenha: any) => {
    alterarSenha(dadosSenha.id_usuario, dadosSenha.senhaAtual, dadosSenha.novaSenha);    
  };

  return (
    <>
      <button
        type="button"
        className="dropdown-item notify-item"
        onClick={abrirModal}
      >
        {/*<TbExchange size={17} /> */}
        <span>Trocar Senha</span>
      </button>

      <Modal
        show={showModal}
        onHide={fecharModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        className="modal-full-width "
      >
        <ModalTrocarSenha
          titulo="Preencha os dados abaixo para efetuar a troca de senha:"
          onsubmit={handleSubmit}
        />
      </Modal>
      <ModalLoading show={loading}  label="Alterando senha do usuário..."/>
    </>
  );
};
