import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { Row, Col, Card } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import { getSessionData } from "../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import Tabela from "../../../components/Formularios/Table";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { mdiEmailFastOutline } from '@mdi/js';
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../api/endPoints/useGet";

function ParceirosList() {

    const navigate = useNavigate()
    interface Action {
        icon: JSX.Element;
        name: string;
    }

    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/cadastro/parceiros/form/novo/criar")}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    const [busca, set_busca] = useState("")
    function Search(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {
            carregarParceiros(getSessionData("DadosIdfilial"))
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = parceiros.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.nome.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)

            })


            set_parceiros(resultados)
        }
    }

    useEffect(function () {
        
        Search(busca)
    }, [busca])

    const [parceiros, set_parceiros] = useState([])
    async function carregarParceiros(id_filial: any) {

        set_carregando(true)

        try {

            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/parceiros/${id_filial}`, {
                headers:{
                    Authorization: getSessionData("MultfilialWebToken")
                }
            })

            set_parceiros(resposta.data)
            set_carregando(false)
        } catch (error: any) {

            set_carregando(false)
            toast.error(error.response.data || error.statusText)
        }

    }

    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_parceiros" },
        { titulo: "CPF/CNPJ", acesso: "cnpjcpf" },
        { titulo: "Nome", acesso: "nome" },
        { titulo: "Email", acesso: "email" },
        { titulo: "Celular", acesso: "telcelular" },
        { titulo: "Status", acesso: "status" }
    ]

    //modal loading
    const [carregando, set_carregando] = useState(false)

    //clicar para visu
    function visualizar(dado: any) {

        navigate(`/cadastro/parceiros/form/${dado.id_parceiros}/visualizar`)
    }

    //clicar para editar
    function editar(dado: any) {

        navigate(`/cadastro/parceiros/form/${dado.id_parceiros}/editar`)
    }

    async function excluir(dado: any) {

        await showConfirmationDialog("Excluir Cadastro ?",
            "Deseja continuar ?", "question").then(function (resposta) {

                if (resposta.confirmed) {

                    set_carregando(true)

                    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/parceiro/${getSessionData("DadosIdfilial")}/${dado.id_parceiros}`, {
                        headers: {
                            Authorization: getSessionData("MultfilialWebToken")
                        }
                    })
                        .then(function (resposta) {

                            set_carregando(false)
                            carregarParceiros(getSessionData("DadosIdfilial"))
                            toast.success(resposta.data)
                        }).catch(function (erro) {

                            set_carregando(false)
                            toast.error(erro.response.data)
                        })
                }
            })


    }

    //verifica permissão --- inicio
    const [permissao_incluir, set_permissao_incluir] = useState(false)
    const [permissao_editar, set_permissao_editar] = useState(false)
    const [permissao_excluir, set_permissao_excluir] = useState(false)
    const [permissao_visualizar, set_permissao_visualizar] = useState(false)

    async function CarregarPermissões() {
        set_carregando(true);
        const headers = {
            Authorization: getSessionData("MultfilialWebToken") || "",
        };
        const dados = {
            url: "/perfil/carregar/:idperfil",
            parametros: {
                idperfil: getSessionData("id_perfil") || "",
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            set_carregando(false)
            if (response[0].visualizarCadParceiro === 1) {
                set_permissao_visualizar(false)
            }
            if (response[0].incluirCadvisualizarCadParceiro === 1) {
                set_permissao_incluir(false)
            }
            if (response[0].EditarCadvisualizarCadParceiro === 1) {
                set_permissao_editar(false)
            }
            if (response[0].ExcluirCadvisualizarCadParceiro === 1) {
                set_permissao_excluir(false)
            }
        })
    }

    useEffect(function () {

        CarregarPermissões()
    }, [])



    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Listagem de Parceiros" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >  {actions.map((action) => (




                                        <SpeedDialAction
                                            hidden={action.name == "Incluir" ? permissao_incluir : false}
                                            key={action.name}
                                            icon={action.icon}
                                            tooltipTitle={action.name}
                                        />
                                    ))}
                                    </SpeedDial>
                                </Row>

                                <Row>
                                    <div className="d-flex my-2 gap-1 ">
                                        <Col sm={11}>
                                            <InputSemBorda
                                                name="fantasia"
                                                type="text"
                                                value={busca}
                                                onChange={set_busca}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Procurar por parceiro"
                                            />
                                        </Col>
                                    </div>
                                </Row>

                                <Row>
                                    <Tabela
                                        coluna={colunas}
                                        dados={parceiros}
                                        itemsPerPage={10}
                                        onVisuClick={visualizar}
                                        onEditClick={editar}
                                        onDeleteClick={excluir}
                                        usaEnvio={false}
                                        id="codigo"
                                        permissao_editar={permissao_editar}
                                        permissao_visualizar={permissao_visualizar}
                                        permissao_excluir={permissao_excluir}
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ModalLoading show={carregando} />
            </div>
        </>
    )
}

export default ParceirosList